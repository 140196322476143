import { Navigate, Route, Routes } from 'react-router-dom'
import URLS from 'lib/constants/urls'
import Home from 'pages/Home'
// import ProductsAndBilling from 'pages/ProductsAndBilling'
import Login from 'pages/Login'
// import Signup from 'pages/Signup'
// import ForgotPassword from 'pages/Login/ForgotPassword'
// import ResetPasswordEmailSent from 'pages/Login/ResetPasswordEmailSent'
// import ResetPasswordConfirmation from 'pages/Login/ResetPasswordConfirmation'
// import Profile from 'pages/Profile'
// import CompanyDetails from 'pages/CompanyDetails'
// import Customers from 'pages/Customers'
// import VerifyAccount from 'pages/Signup/VerifyAccount'
import ProtectedRoutes from 'components/ProtectedRoutes'
import OpportunitiesList from 'pages/OpportunitiesList'
import OpportunityContextProvider from 'context/OpportunityContextProvider'

export default function Router() {
  return (
    <Routes>
      {/* ------------------ NO AUTH ------------------*/}
      <Route path={URLS.login} element={<Login />} />
      {/* <Route path={URLS.signup} element={<Signup />} />
      <Route path={URLS.signupVerify} element={<VerifyAccount />} />
      <Route path={URLS.forgotPassword} element={<ForgotPassword />} />
      <Route path={URLS.forgotPasswordInit} element={<ResetPasswordEmailSent />} />
      <Route path={URLS.forgotPasswordFinish} element={<ResetPasswordConfirmation />} /> */}

      {/* ------------------ AUTH ------------------*/}
      <Route element={<ProtectedRoutes sales support />}>
        {/* <Route path={URLS.customers} element={<Customers />} /> */}
        <Route
          path={URLS.opportunities}
          element={
            <OpportunityContextProvider>
              <OpportunitiesList />
            </OpportunityContextProvider>
          }
        />
      </Route>

      <Route element={<ProtectedRoutes sales support user />}>
        <Route path={URLS.home} element={<Home />} />
        {/* <Route path={URLS.profile} element={<Profile />} />
        <Route path={URLS.productsAndBilling} element={<ProductsAndBilling />} />
        <Route path={URLS.companyDetails} element={<CompanyDetails />} /> */}
      </Route>

      {/* -------- NOT FOUND (MUST COME LAST) ---------*/}
      <Route path="/*" element={<Navigate to={URLS.login} replace />} />
    </Routes>
  )
}
