import { createContext, useEffect, useMemo } from 'react'
import { useUserCTX } from '@chunker/user-ctx'
import useOpportunityStatuses from 'hooks/useOpportunityStatuses'
import useOpportunityStatusReasons from 'hooks/useOpportunityStatusReasons'
import useOpportunitySpaceUnits from 'hooks/useOpportunitySpaceUnits'
import useGoodsInOutFrequencyUnits from 'hooks/useGoodsInOutFrequencyUnits'
import useOptionsByOptionHeader from 'hooks/useOptionsByOptionHeader'
import isUserStatusLoggedInOrReinitializing from 'lib/utils/userUtils/isUserStatusLoggedInOrReinitializing'
import { IOpportunityContext } from './types'

export const OpportunityContext = createContext<IOpportunityContext | null>(null)

function OpportunityContextProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const { status } = useUserCTX()

  const opportunityStatusesHook = useOpportunityStatuses()
  const opportunityStatusReasonsHook = useOpportunityStatusReasons()
  const optionsByHeaderHook = useOptionsByOptionHeader()
  const opportunitySpaceUnitsHook = useOpportunitySpaceUnits()
  const goodsInOutFrequencyUnitsHook = useGoodsInOutFrequencyUnits()

  const hooks = useMemo(
    () => [
      opportunityStatusesHook,
      opportunityStatusReasonsHook,
      optionsByHeaderHook,
      opportunitySpaceUnitsHook,
      goodsInOutFrequencyUnitsHook,
    ],
    [
      goodsInOutFrequencyUnitsHook,
      opportunitySpaceUnitsHook,
      opportunityStatusReasonsHook,
      opportunityStatusesHook,
      optionsByHeaderHook,
    ],
  )

  useEffect(() => {
    if (isUserStatusLoggedInOrReinitializing(status)) {
      Promise.all(
        hooks.map((hook) => {
          return hook.fetch().catch(console.error)
        }),
      ).catch(console.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const value = useMemo(
    () => ({
      opportunityStatuses: opportunityStatusesHook.opportunityStatuses,
      opportunityStatusReasons: opportunityStatusReasonsHook.opportunityStatusReasons,
      optionsByHeader: optionsByHeaderHook.optionsByHeader,
      opportunitySpaceUnits: opportunitySpaceUnitsHook.opportunitySpaceUnits,
      goodsInOutFrequencyUnits: goodsInOutFrequencyUnitsHook.goodsInOutFrequencyUnits,
      loading: hooks.some((hook) => hook.loading),
    }),
    [
      opportunityStatusesHook.opportunityStatuses,
      opportunityStatusReasonsHook.opportunityStatusReasons,
      optionsByHeaderHook.optionsByHeader,
      opportunitySpaceUnitsHook.opportunitySpaceUnits,
      goodsInOutFrequencyUnitsHook.goodsInOutFrequencyUnits,
      hooks,
    ],
  )

  return <OpportunityContext.Provider value={value}>{children}</OpportunityContext.Provider>
}

export default OpportunityContextProvider
