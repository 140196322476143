import { Alert, Snackbar } from '@mui/material'
import { createContext, useCallback, useMemo, useState } from 'react'
import { DEFAULT_ERROR_TEXT, DEFAULT_SUCCESS_TEXT } from './constants'
import { INotifyContext } from './types'

export const NotifyContext = createContext<INotifyContext | null>(null)

/**
 * @description
 * - Provides functions to control and app wide notification.
 * - Use useNotify hook within your component to notify.
 * - Invoking the notify function with a message will make a snackbar visible for a short period.
 */

function NotifyContextProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [open, setOpen] = useState(false)
  const [severity, setSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('success')

  const notifyError = useCallback((message?: string) => {
    setOpen(true)
    setSnackbarMessage(message ?? DEFAULT_ERROR_TEXT)
    setSeverity('error')
  }, [])

  const notifySuccess = useCallback((message?: string) => {
    setOpen(true)
    setSnackbarMessage(message ?? DEFAULT_SUCCESS_TEXT)
    setSeverity('success')
  }, [])

  const notifyInfo = useCallback((message: string) => {
    setOpen(true)
    setSnackbarMessage(message)
    setSeverity('info')
  }, [])

  const notifyWarning = useCallback((message: string) => {
    setOpen(true)
    setSnackbarMessage(message)
    setSeverity('warning')
  }, [])

  const handleClose = () => setOpen(false)

  const value = useMemo(
    () => ({
      notifyError,
      notifySuccess,
      notifyInfo,
      notifyWarning,
    }),
    [notifyError, notifyInfo, notifySuccess, notifyWarning],
  )

  return (
    <NotifyContext.Provider value={value}>
      <Snackbar
        open={open}
        autoHideDuration={10000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={(_, reason) => {
          if (reason === 'clickaway') {
            return
          }

          handleClose()
        }}
      >
        <Alert
          data-testid={`notify-context-alert-${severity}`}
          severity={severity}
          onClose={() => handleClose()}
          variant="filled"
          sx={{ width: '100%', whiteSpace: 'pre-line' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {children}
    </NotifyContext.Provider>
  )
}

export default NotifyContextProvider
