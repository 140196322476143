import { AppendableTestIdsExtention } from './types'

function getAppendableTestId<T extends AppendableTestIdsExtention>({
  appendableTestIds,
  key,
  testId,
}: {
  appendableTestIds?: T
  key?: keyof T
  testId: string
}): string {
  if (!appendableTestIds || !key) return testId

  const appendableTestId = appendableTestIds[key]

  if (!appendableTestId) return testId

  return `${testId}-${appendableTestId}`
}

export default getAppendableTestId
