import { StandardChunkerAPIType, opportunitySpaceUnitResource } from '@chunker/chunker-request'
import useNotify from 'hooks/useNotify'
import { useCallback, useEffect, useState } from 'react'

function useOpportunitySpaceUnits() {
  const { notifyError } = useNotify()
  const [opportunitySpaceUnits, setOpportunitySpaceUnits] = useState<StandardChunkerAPIType[]>([])
  const [loading, setLoading] = useState(false)

  const fetchOpportunitySpaceUnits = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await opportunitySpaceUnitResource.requests.getOpportunitySpaceUnits()
      setOpportunitySpaceUnits(data)
    } catch (error) {
      console.error(error)
      notifyError()
    } finally {
      setLoading(false)
    }
  }, [notifyError])

  useEffect(() => {
    fetchOpportunitySpaceUnits().catch(console.error)
  }, [fetchOpportunitySpaceUnits])

  return { opportunitySpaceUnits, fetch: fetchOpportunitySpaceUnits, loading }
}

export default useOpportunitySpaceUnits
