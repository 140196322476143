import { useFormikContext } from 'formik'
import { Opportunity } from '@chunker/chunker-request'
import { DeepPartial } from '@chunker/chunker-request/dist/types/DeepPartial'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import ChunkerButtonWithLoading from 'components/ChunkerButtonWithLoading'
import deepDifference from 'lib/utils/objectUtils/deepDifference'
import useNotify from 'hooks/useNotify'
import opportunitiesResource from '@chunker/chunker-request/dist/opportunitiesResource'
import extractData from 'lib/utils/requestUtils/extractData'
import { Id } from '@chunker/chunker-request/dist/types/Id'
import { isObject } from 'lodash'
import { IOpportunityActionsButtonsProps } from './types'

function OpportunityActionsButtons({ action, onClose }: IOpportunityActionsButtonsProps) {
  const {
    initialValues,
    values,
    setFieldValue,
    validateForm,
    setSubmitting,
    isValid,
    isValidating,
    isSubmitting,
    resetForm,
  } = useFormikContext<DeepPartial<Opportunity> & { currentStep: number }>()

  const { notifyError, notifySuccess } = useNotify()

  const differenceObject = deepDifference(values, initialValues, {
    omitKeys: ['currentStep'],
  })
  const changesWereMade = Object.keys(differenceObject).length > 0
  const hasOpportunityId = Boolean(values.id)

  const steps = ['step1', 'step2', 'step3']

  const getNextStepIndex = (currentStep: number) => {
    const currentIndex = currentStep
    if (action === 'back') {
      return Math.max(currentIndex - 1, 0)
    }
    if (action === 'next') {
      return Math.min(currentIndex + 1, steps.length - 1)
    }
    if (action === 'submit') {
      return currentIndex
    }
    return currentIndex
  }

  const nextStepIndex = getNextStepIndex(values.currentStep)

  const handleButtonClicked = () => {
    if (action === 'back' || action === 'submit') {
      if (changesWereMade && !hasOpportunityId) {
        notifyError('Opportunity ID is missing, something went wrong. Please contact support.')
        return
      }
    }

    if (action === 'next') {
      if (changesWereMade && !hasOpportunityId) {
        setSubmitting(true)
        opportunitiesResource.requests
          .postOpportunities({
            ...values,
          })
          .then(extractData)
          .then((data) => {
            resetForm({
              values: {
                ...initialValues,
                ...data,
              } as Opportunity & { currentStep: number },
            })
            setFieldValue('currentStep', nextStepIndex, true)
              .then(() => {
                validateForm().catch(console.error)
              })
              .catch(console.error)
          })
          .catch((err) => {
            if (isObject(err)) {
              const { message } = err as { message: string }
              notifyError(message)
            }
            console.error(err)
          })
          .finally(() => {
            setSubmitting(false)
          })
      }
    }

    if (!changesWereMade && hasOpportunityId) {
      if (action === 'submit') {
        onClose?.()
      }

      setFieldValue('currentStep', nextStepIndex, true)
        .then(() => {
          validateForm().catch(console.error)
        })
        .catch(console.error)
    }

    if (changesWereMade && hasOpportunityId) {
      setSubmitting(true)
      opportunitiesResource.requests
        .patchOpportunitiesById(values.id as Id, {
          id: values.id,
          ...differenceObject,
        })
        .then(extractData)
        .then((data) => {
          resetForm({
            values: {
              ...initialValues,
              ...data,
            } as Opportunity & { currentStep: number },
          })
          setFieldValue('currentStep', nextStepIndex, true)
            .then(() => {
              validateForm().catch(console.error)
            })
            .catch(console.error)
          notifySuccess('Opportunity saved successfully.')

          if (action === 'submit') {
            onClose?.()
          }
        })
        .catch((err) => {
          console.error(err)
          notifyError('Failed to saved opportunity.')
        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  }

  const renderBackButton = () => (
    <ChunkerButtonWithLoading
      type="button"
      loading={isSubmitting}
      loadingVariant="start"
      startIcon={<ArrowBack />}
      onClick={handleButtonClicked}
      disabled={!isValid || isValidating || isSubmitting}
    >
      {changesWereMade ? 'Save & Go Back' : 'Go Back'}
    </ChunkerButtonWithLoading>
  )

  const renderNextButton = () => (
    <ChunkerButtonWithLoading
      type="button"
      loading={isSubmitting}
      loadingVariant="end"
      endIcon={<ArrowForward />}
      onClick={handleButtonClicked}
      disabled={!isValid || isValidating || isSubmitting}
    >
      {changesWereMade ? 'Save & Continue' : 'Continue'}
    </ChunkerButtonWithLoading>
  )

  const renderSubmitButton = () => (
    <ChunkerButtonWithLoading
      type="button"
      loading={isSubmitting}
      loadingVariant="end"
      onClick={handleButtonClicked}
      disabled={!isValid || isValidating || isSubmitting}
    >
      {changesWereMade ? 'Save & Submit' : 'Close'}
    </ChunkerButtonWithLoading>
  )

  return (
    <>
      {action === 'back' && renderBackButton()}
      {action === 'next' && renderNextButton()}
      {action === 'submit' && renderSubmitButton()}
    </>
  )
}

export default OpportunityActionsButtons
