import { isArray, isObject } from 'lodash'
import { IObjectOptions } from './types'

export default function changeObject(objectToClean: object, options: IObjectOptions): object {
  const entries = Object.entries(objectToClean)

  const done = entries.reduce<typeof entries>((acc, [key, value]) => {
    // if this is not an array and is empty.
    if (
      options.cleanEmptyObjects &&
      isObject(value) &&
      Object.keys(value).length === 0 &&
      !isArray(value)
    )
      return acc

    // if this is an array check if we want to add it in.
    if (isArray(value)) {
      if (!options.omitArrays) return [...acc, [key, value]]
      return acc
    }

    if (isObject(value)) {
      // it this level we evaluate the final return value from all sub levels.
      const val = changeObject(value, options)

      // if we are cleaning objects this is an object and is empty and is not an array return nothing, else proceed.
      if (
        options.cleanEmptyObjects &&
        isObject(value) &&
        Object.keys(val).length === 0 &&
        !isArray(value)
      )
        return acc

      return [...acc, [key, val]]
    }

    return [...acc, [key, value]]
  }, [])

  return Object.fromEntries(done)
}
