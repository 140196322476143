import { Fragment, useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  TableFooter,
  TablePagination,
  Button,
  Collapse,
  Box,
  Typography,
} from '@mui/material'
import {
  ChunkerMarketPlace,
  OpportunitySolution,
} from '@chunker/chunker-request/dist/types/OpportunitySolution'
import { Listing } from '@chunker/chunker-request/dist/types/Listing'
import InfoIcon from '@mui/icons-material/Info'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExportCSV from 'components/ExportCsv'
import SalesUtilityIcons from 'components/SalesUtilityIcons'
import WarehouseDetailsDialog from '../WarehouseDetailsDialog'
import {
  renderLocationAddressCell,
  renderLocationDistanceCell,
  renderLocationTypeCell,
} from '../utils'

function WarehouseChunkerMarketPlacesTable({
  locations,
}: {
  locations: OpportunitySolution['chunkerMarketPlaces']
}) {
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState<ChunkerMarketPlace | null>(null)
  const [page, setPage] = useState(0)
  const [csvData, setCsvData] = useState<{ headers: string[]; rows: Record<string, unknown>[] }>()
  const [openCollapsible, setOpenCollapsible] = useState<{ [key: string]: boolean }>({})
  const rowsPerPage = 5

  const displayedLocations = locations?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleOpenModal = (location: ChunkerMarketPlace) => {
    setSelectedLocation(location)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setSelectedLocation(null)
  }

  const toggleCollapsible = (id: string | number | null) => {
    if (id === null) return
    const idStr = String(id)
    setOpenCollapsible((prev) => ({
      ...prev,
      [idStr]: !prev[idStr],
    }))
  }

  useEffect(() => {
    const headers = [
      'owner_company_name',
      'owner_company_contact_name',
      'owner_company_contact_email',
      'owner_company_contact_phone',
      'owner_company_contact_title',
      'property_creator_first_name',
      'property_creator_last_name',
      'property_creator_email',
      'listing_primary_contact_name',
      'listing_primary_contact_email',
      'listing_primary_contact_phone',
      'listing_primary_contact_title',
      'listing_primary_contact_company',
    ] as string[]

    const rows = [] as Record<string, unknown>[]

    const blockedEmails = ['support@chunker.com', 'stella@bigtimebrokerage.com']
    const blockedDomains = ['@chunker.com']

    const isEmailBlocked = (email: string) => {
      const domain = email?.split('@')[1]
      return blockedEmails.includes(email) || blockedDomains.includes(`@${domain}`)
    }

    locations?.forEach((location: ChunkerMarketPlace) => {
      const propertyCreator = location?.property?.createdByUser
      const ownerCompany = location?.property?.ownerCompany

      const listings = location?.listings || []

      const addRow = (listing: Listing) => {
        const row = {
          owner_company: ownerCompany?.legalName,
          owner_company_contact_name: ownerCompany?.contactName,
          owner_company_contact_email: ownerCompany?.contactEmail,
          owner_company_contact_phone: ownerCompany?.contactPhone,
          owner_company_contact_title: ownerCompany?.contactTitle,
          property_creator_first_name: propertyCreator?.firstName,
          property_creator_last_name: propertyCreator?.lastName,
          property_creator_email: propertyCreator?.email,
          listing_primary_contact_name: listing?.contactNamePrimary || '',
          listing_primary_contact_email: listing?.contactEmailPrimary || '',
          listing_primary_contact_phone: listing?.contactPhonePrimary || '',
          listing_primary_contact_title: listing?.contactTitlePrimary || '',
          listing_primary_contact_company: listing?.contactCompanyPrimary || '',
        }

        if (
          (!isEmailBlocked(row.owner_company_contact_email as string) &&
            row.owner_company_contact_email) ||
          (!isEmailBlocked(row.property_creator_email as string) && row.property_creator_email) ||
          (!isEmailBlocked(row.listing_primary_contact_email) && row.listing_primary_contact_email)
        ) {
          rows.push(row)
        }
      }

      if (listings?.length > 0) {
        listings.forEach(addRow)
      } else {
        addRow({} as Listing)
      }
    })

    setCsvData({ headers, rows })
  }, [locations])

  return (
    <TableContainer>
      <ExportCSV data={csvData} fileName="warehouse-contacts-chunker-locations.csv" />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '5%' }} />
            <TableCell sx={{ width: '50%' }}>Address</TableCell>
            <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
              Distance
              <Tooltip title="Distance from the opportunity location">
                <IconButton size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedLocations?.map((location) => (
            <Fragment key={location.property?.id}>
              <TableRow>
                <TableCell>
                  {location.listings && location.listings?.length > 0 && (
                    <IconButton onClick={() => toggleCollapsible(String(location.property?.id))}>
                      {openCollapsible[String(location.property?.id)] ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                  )}
                </TableCell>
                <TableCell>{renderLocationAddressCell(location)}</TableCell>
                <TableCell>{renderLocationDistanceCell(location)}</TableCell>
                <TableCell>{renderLocationTypeCell(location)}</TableCell>
                <TableCell>
                  <Button variant="outlined" onClick={() => handleOpenModal(location)}>
                    Details
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <Collapse
                    in={openCollapsible?.[String(location.property?.id)]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box margin={1}>
                      <Typography variant="h6" gutterBottom component="div">
                        Related Listings
                      </Typography>
                      <Table size="small" aria-label="listing-details">
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: '50%' }}>Title</TableCell>
                            <TableCell>Primary Contact</TableCell>
                            <TableCell>Link</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {location.listings &&
                            location.listings?.map((listing) => (
                              <TableRow key={listing.id}>
                                <TableCell>{listing?.title ?? 'Untitled'}</TableCell>
                                <TableCell>
                                  {listing?.contactNamePrimary}
                                  <br />
                                  {listing?.contactTitlePrimary}
                                  <br />
                                  {listing?.contactEmailPrimary}
                                  <br />
                                  {listing?.contactPhonePrimary}
                                </TableCell>
                                <TableCell>
                                  <SalesUtilityIcons
                                    linkValue={`https://app.chunker.com/sales/${listing?.id}`}
                                    linkTooltip="Open in new tab"
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              count={locations?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
      {selectedLocation && (
        <WarehouseDetailsDialog
          open={modalOpen}
          handleClose={handleCloseModal}
          location={selectedLocation}
        />
      )}
    </TableContainer>
  )
}

export default WarehouseChunkerMarketPlacesTable
