import { Box, Grid } from '@mui/material'
import ClickableTypography from 'components/ClickableTypography'
import ClickableIcon from 'components/ClickableIcon'
import facebookIcon from '../../../assets/images/facebook.png'
import linkedinIcon from '../../../assets/images/linkedIn.png'
import URLS from '../../../lib/constants/urls'
import VersionWatermark from '../VersionWaterMark'
import windowOpenBlank from '../../../lib/utils/windowUtls/windowOpenBlank'

export default function FooterContent() {
  return (
    <Grid
      data-testid="application-footer-footer-content"
      sx={{
        p: 3,
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        '& > div': {
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      <Box sx={{ p: 3, display: 'flex' }}>
        <ClickableTypography
          text="Terms of Service"
          data-testid="footer-content-terms-of-service"
          onClick={() => windowOpenBlank(URLS.leaveCompanyPortal.terms)}
        />
        <Box sx={{ pl: 3 }}>
          <ClickableTypography
            text="Privacy Policy"
            data-testid="footer-content-privacy-policy"
            onClick={() => windowOpenBlank(URLS.leaveCompanyPortal.privacyPolicy)}
          />
        </Box>
        <Box sx={{ pl: 3 }}>
          <ClickableTypography
            text="Chunker App"
            data-testid="footer-content-chunker"
            onClick={() => windowOpenBlank(URLS.leaveCompanyPortal.marketPlace)}
          />
        </Box>
      </Box>
      <Box sx={{ px: [3, 6], py: 3 }}>
        <ClickableIcon
          src={facebookIcon}
          data-testid="footer-content-facebook"
          onClick={() => windowOpenBlank(URLS.leaveCompanyPortal.faceBook)}
          sx={{ mr: 3 }}
        />
        <ClickableIcon
          src={linkedinIcon}
          data-testid="footer-content-linkedin"
          onClick={() => windowOpenBlank(URLS.leaveCompanyPortal.linkedIn)}
        />
      </Box>
      <Box sx={{ p: 3 }}>
        <ClickableTypography
          data-testid="footer-content-copyright"
          text={`©Chunker ${new Date().getFullYear()}`}
        />
      </Box>
      <Box sx={{ p: 3, ml: 'auto' }}>
        <VersionWatermark />
      </Box>
    </Grid>
  )
}
