import { Link } from '@mui/material'
import { IButtonLinkClickableProps } from './types'

function ButtonLinkClickable({ children, onClick, sx, ...props }: IButtonLinkClickableProps) {
  return (
    <Link
      component="button"
      variant="body1"
      underline="always"
      sx={{
        fontWeight: 'bold',
        cursor: 'pointer',
        color: 'primary.main',
        ...sx,
      }}
      onClick={onClick}
      {...props}
    >
      {children}
    </Link>
  )
}

export default ButtonLinkClickable
