import { Box, Typography, useTheme } from '@mui/material'
import logoChunkerIcon from '../../assets/logo-chunker-orange-icon.png'
import { ILogoCaptionProps } from './types'

function LogoCaption({ sxBoxContainer, sxText }: ILogoCaptionProps) {
  const theme = useTheme()
  return (
    <Box
      sx={{
        m: '5rem 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'left',
        ...sxBoxContainer,
      }}
    >
      <Box
        component="img"
        src={logoChunkerIcon}
        sx={{
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          width: '20px',
          height: '20px',
          marginRight: theme.spacing(2),
        }}
        data-testid="footer-branding-logo"
      />
      <Typography
        variant="caption"
        color="black"
        fontWeight="bold"
        sx={{ ...sxText }}
        data-testid="footer-branding-text"
      >
        One account for Chunker Marketplace, Logistics Planning Platform and YMS.
      </Typography>
    </Box>
  )
}

export default LogoCaption
