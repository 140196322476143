import { Typography } from '@mui/material'

export default function VersionWatermark() {
  const { REACT_APP_VERSION_WATERMARK } = process.env

  return (
    <Typography
      data-testid="application-footer-version-watermark"
      variant="body2"
      sx={{ color: (theme) => theme.palette.grey?.[500] }}
    >
      {REACT_APP_VERSION_WATERMARK ?? 'REACT_APP_VERSION_WATERMARK'}
    </Typography>
  )
}
