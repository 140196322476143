import { isEqual } from 'lodash'
import useUserHasRights from 'hooks/useUserHasRights'
import { Divider, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { IMenuListItemProps } from './types'

function MenuListItem({
  isSelected,
  onClick,
  open,
  icon,
  userRights,
  title,
  ...props
}: IMenuListItemProps) {
  const isAllAvailable = isEqual(['*'], userRights)
  const userRightsIsArray = Array.isArray(userRights)
  const userRightsArrayIsPresent = Boolean(userRights?.length)
  const { hasRights, isUserLoggedIn } = useUserHasRights({
    sales: userRightsIsArray ? userRights.includes('sales') : false,
    support: userRightsIsArray ? userRights.includes('support') : false,
    user: userRightsIsArray ? userRights.includes('user') : false,
  })

  /**
   * - Ensures that the user is logged in and that the user has the rights to see the menu item
   * - Additionally, ensures that the wildcard (*) is not present in the user rights array
   * - Additionally, ensures that the user rights array is present
   */
  const shouldHideForAuthenticatedUsers = !hasRights && !isAllAvailable && userRightsArrayIsPresent

  /**
   * - Ensures that the user is logged out
   */
  const shouldHideForUnauthenticatedUsers = !isUserLoggedIn && userRightsArrayIsPresent

  const shouldHide = [shouldHideForAuthenticatedUsers, shouldHideForUnauthenticatedUsers].includes(
    true,
  )

  if (shouldHide) return null

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          selected={isSelected}
          onClick={onClick}
          data-testid={`nav-drawer-bar-button-menu-${props['data-testid']}`}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText
            primary={title}
            data-testid={`nav-drawer-bar-text-menu-${props['data-testid']}`}
          />
        </ListItemButton>
      </ListItem>
      <Divider />
    </>
  )
}

export default MenuListItem
