import { NotifyContext } from 'context/NotifyContextProvider'
import { useContext } from 'react'

/**
 * @description useNotify
 * - Provides functions from NotifyContextProvider to display notifications on the page.
 * - Invoking the provided methods will show a notifications on the page for a short time.
 * - Invoking two notifications concurrently does not reset the timer.
 * - Generally this should be used for promises and sparingly as the timer does not reset.
 *
 * @example
 * const { notifyError } = useNotify()
 *
 * Promise().catch(() => notifyError('FAIL'))
 */

export default function useNotify() {
  const ctx = useContext(NotifyContext)
  if (!ctx)
    throw new Error(
      'useNotify is being used outside of its context provider. Please ensure it is within the context provider.',
    )

  return ctx
}
