/* eslint-disable func-names */
/* eslint-disable import/prefer-default-export */
import { parsePhoneNumber } from 'awesome-phonenumber'
import * as yup from 'yup'

export const expectPhoneNumber = ({
  phoneType = 'phone',
  required = false,
  regionCode = 'US',
}: {
  phoneType?: 'phone' | 'fax' | 'cell'
  required?: boolean
  regionCode?: string
} = {}) =>
  yup.string().test('phone-number', 'Invalid phone number', function (value, ctx) {
    if (!value && !required) return true
    if (!value && required)
      return ctx.createError({
        path: this.path,
        type: 'required',
        message: `${phoneType.charAt(0).toUpperCase() + phoneType.slice(1)} number is required`,
      })

    const parsedPhoneNumber = parsePhoneNumber(value ?? '', { regionCode })
    const { valid, possibility } = parsedPhoneNumber

    if (!valid)
      return ctx.createError({
        path: this.path,
        type: 'invalid',
        message: (() => {
          if (possibility === 'invalid-country-code') return 'Invalid country code'
          if (possibility === 'is-possible') return 'Not a valid phone number'
          if (possibility === 'too-long') return 'Too Long'
          if (possibility === 'too-short') return 'Too Short'
          return `Invalid ${phoneType} number`
        })(),
      })

    return true
  })

export const expectPassword = () =>
  yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[1-9]/, 'Password must contain at least one number')

export const expectMaxString = (max: number, name: string) =>
  yup.string().max(max, `${name} must be ${max} characters or less`)
