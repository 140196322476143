import { useMemo, useState } from 'react'
import { FormikValues, useFormikContext } from 'formik'
import { format, parseISO } from 'date-fns'
import { Button, ButtonGroup, Grid, InputAdornment, Typography } from '@mui/material'
import AddressAutoFill from 'components/AddressAutoFill'
import CheckboxRequestCluster from 'components/CheckboxRequestCluster'
import DateTimeFrames from 'components/DateTimeFrames'
import { DateTimeFramesSelectOptionsValue } from 'components/DateTimeFrames/types'
import getTimeFrameFromDateRange from 'components/DateTimeFrames/utils/getTimeFrameFromDateRange'
import FormAccordionOption from 'components/FormAccordionOption'
import FormAccordionType from 'components/FormAccordionType'
import FormHeaderDivider from 'components/FormHeaderDivider'
import FormikCheckBox from 'components/FormikCheckbox'
import FormikSliderTravelDistance from 'components/FormikSliderTravelDistance'
import FormikTextField from 'components/FormikTextField'
import FormikTextFieldWithSwitch from 'components/FormikTextFieldWithSwitch'
import HeaderSmall from 'components/HeaderSmall'
import { DATE_TIME_FORMATS } from 'lib/constants/time'
import { ITransportationFormProps } from './types'
import { OPTION_HEADERS_ID } from '../constants/constants'

function TransportationForm({
  optionsByHeader,
  opportunitySpaceUnits,
  goodsInOutFrequencyUnits,
}: ITransportationFormProps) {
  const { setFieldValue, values } = useFormikContext<FormikValues>()

  const transportationServicesDateStart =
    typeof values.transportationServicesDateStart === 'string'
      ? parseISO(values.transportationServicesDateStart)
      : null

  const transportationServicesDateEnd =
    typeof values.transportationServicesDateEnd === 'string'
      ? parseISO(values.transportationServicesDateEnd)
      : null

  const [selectedTimeFrameValue, setSelectedTimeFrameValue] =
    useState<DateTimeFramesSelectOptionsValue>(
      getTimeFrameFromDateRange({
        dateTo: transportationServicesDateEnd,
        dateFrom: transportationServicesDateStart,
      }),
    )

  const spaceUnits = useMemo(() => ['containers', 'trailers'], [])

  return useMemo(() => {
    return (
      <FormAccordionType type="transportation" AccordionBaseProps={{ sx: { mt: 3 } }}>
        <FormHeaderDivider variant="secondary" sx={{ my: 3 }}>
          LOCATION
        </FormHeaderDivider>
        <Typography variant="body2" sx={{ mt: 3 }}>
          Provide the starting point and destination for the transportation service you require{' '}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <HeaderSmall>Origin</HeaderSmall>
            <AddressAutoFill
              fields={[
                {
                  key: 'transportationServicesOriginStreetAddress',
                  label: 'Street',
                  columns: { xs: 12 },
                },
                { key: 'transportationServicesOriginCity', label: 'City' },
                { key: 'transportationServicesOriginState', label: 'State' },
                { key: 'transportationServicesOriginCountryCode', label: 'Country' },
                { key: 'transportationServicesOriginPostalCode', label: 'Zip Code' },
              ]}
              fieldMapping={{
                street_number: 'transportationServicesOriginStreetAddress',
                route: 'transportationServicesOriginStreetAddress',
                locality: 'transportationServicesOriginCity',
                administrative_area_level_1: 'transportationServicesOriginState',
                postal_code: 'transportationServicesOriginPostalCode',
                country: 'transportationServicesOriginCountryCode',
                location: 'transportationServicesOriginLocation',
              }}
              BoxProps={{ sx: { mt: 3 } }}
              textFieldProps={{ size: 'small' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <HeaderSmall>Destination</HeaderSmall>
            <AddressAutoFill
              fields={[
                {
                  key: 'transportationServicesDestinationStreetAddress',
                  label: 'Street',
                  columns: { xs: 12 },
                },
                { key: 'transportationServicesDestinationCity', label: 'City' },
                { key: 'transportationServicesDestinationState', label: 'State' },
                { key: 'transportationServicesDestinationCountryCode', label: 'Country' },
                { key: 'transportationServicesDestinationPostalCode', label: 'Zip Code' },
              ]}
              fieldMapping={{
                street_number: 'transportationServicesDestinationStreetAddress',
                route: 'transportationServicesDestinationStreetAddress',
                locality: 'transportationServicesDestinationCity',
                administrative_area_level_1: 'transportationServicesDestinationState',
                postal_code: 'transportationServicesDestinationPostalCode',
                country: 'transportationServicesDestinationCountryCode',
                location: 'transportationServicesDestinationLocation',
              }}
              BoxProps={{ sx: { mt: 3 } }}
              textFieldProps={{ size: 'small' }}
            />
          </Grid>
        </Grid>
        <FormAccordionOption title="Additional Destination">
          <AddressAutoFill
            fields={[
              {
                key: 'transportationServicesAdditionalDestinationStreetAddress',
                label: 'Street',
                columns: { xs: 12 },
              },
              { key: 'transportationServicesAdditionalDestinationCity', label: 'City' },
              { key: 'transportationServicesAdditionalDestinationState', label: 'State' },
              { key: 'transportationServicesAdditionalDestinationCountryCode', label: 'Country' },
              { key: 'transportationServicesAdditionalDestinationPostalCode', label: 'Zip Code' },
            ]}
            fieldMapping={{
              street_number: 'transportationServicesAdditionalDestinationStreetAddress',
              route: 'transportationServicesAdditionalDestinationStreetAddress',
              locality: 'transportationServicesAdditionalDestinationCity',
              administrative_area_level_1: 'transportationServicesAdditionalDestinationState',
              postal_code: 'transportationServicesAdditionalDestinationPostalCode',
              country: 'transportationServicesAdditionalDestinationCountryCode',
              location: 'transportationServicesAdditionalDestinationLocation',
            }}
            BoxProps={{ sx: { mt: 3 } }}
            textFieldProps={{ size: 'small' }}
          />
        </FormAccordionOption>
        <HeaderSmall>Travel Distance</HeaderSmall>
        <Typography variant="body2" sx={{ mb: 3 }}>
          Provide how far the tenant is willing to travel to store their goods.
        </Typography>
        <FormikSliderTravelDistance name="transportationServicesTravelDistance" />
        <FormHeaderDivider variant="secondary" sx={{ my: 3 }}>
          SPACE NEEDED
        </FormHeaderDivider>
        <FormikTextFieldWithSwitch
          name="transportationServicesSpaceNeeded"
          label="Space Needed"
          switcherName="transportationServicesOpportunitySpaceUnit.id"
          switcherOptions={opportunitySpaceUnits.filter((unit) => spaceUnits.includes(unit.label))}
          size="small"
          integer
        />
        <HeaderSmall>Goods In / Out Frequency</HeaderSmall>
        <Typography variant="body2" sx={{ mb: 3 }}>
          How often will the customer need to store and retrieve goods (e.g., daily, weekly,
          monthly)?
        </Typography>
        <FormikTextFieldWithSwitch
          name="transportationServicesGoodsInOutFrequency"
          label="Goods In / Out Frequency"
          switcherName="transportationServicesGoodsInOutFrequencyUnit.id"
          switcherOptions={goodsInOutFrequencyUnits}
          size="small"
          integer
        />
        <FormHeaderDivider variant="secondary" sx={{ my: 3 }}>
          PROJECT LENGTH
        </FormHeaderDivider>
        <DateTimeFrames
          dateFrom={transportationServicesDateStart}
          dateTo={transportationServicesDateEnd}
          selectedTimeFrameValue={selectedTimeFrameValue}
          onChange={({ dateTo, dateFrom }) => {
            setSelectedTimeFrameValue(getTimeFrameFromDateRange({ dateTo, dateFrom }))

            setFieldValue(
              'transportationServicesDateStart',
              dateFrom ? format(new Date(dateFrom), DATE_TIME_FORMATS.DATABASE_NO_TIME) : null,
            ).catch(console.error)
            setFieldValue(
              'transportationServicesDateEnd',
              dateTo ? format(new Date(dateTo), DATE_TIME_FORMATS.DATABASE_NO_TIME) : null,
            ).catch(console.error)
          }}
          handleUpdateSelectedTimeFrameValue={setSelectedTimeFrameValue}
        />
        <HeaderSmall>Total Estimated Budget</HeaderSmall>
        <FormikTextField
          name="transportationServicesEstimatedBudgetTotal"
          label="Total Estimated Budget"
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          fullWidth
          integer
        />
        <HeaderSmall>Estimated Budget Per Unit </HeaderSmall>
        <FormikTextFieldWithSwitch
          name="transportationServicesEstimatedBudgetPerUnit"
          label="Estimated Budget Per Unit"
          switcherName="transportationServicesOpportunitySpaceUnit.id"
          switcherOptions={opportunitySpaceUnits.filter((unit) => spaceUnits.includes(unit.label))}
          startAdornment="$"
          size="small"
          float
        />
        <FormHeaderDivider variant="secondary" sx={{ my: 3 }}>
          SPECIAL REQUIREMENTS
        </FormHeaderDivider>
        <CheckboxRequestCluster
          clusterName="transportationServicesOptions"
          header="Transportation Needed"
          subheader="Select all the transportation services needed that match the needs of the customer."
          data={optionsByHeader[OPTION_HEADERS_ID.TRANSPORTATION_NEEDED]}
        />
        <CheckboxRequestCluster
          clusterName="transportationServicesOptions"
          header="Equipment Needed"
          data={optionsByHeader[OPTION_HEADERS_ID.EQUIPMENT_NEEDED]}
        />
        <FormAccordionOption title="Pallets">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <HeaderSmall>Pallets Height</HeaderSmall>
              <FormikTextField
                name="transportationServicesPalletHeight"
                label="Pallets Height"
                size="small"
                InputProps={{
                  endAdornment: 'inches',
                }}
                fullWidth
                float
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HeaderSmall>Pallets Width</HeaderSmall>
              <FormikTextField
                name="transportationServicesPalletWidth"
                label="Pallets Width"
                size="small"
                InputProps={{
                  endAdornment: 'inches',
                }}
                fullWidth
                float
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HeaderSmall>Pallets Length</HeaderSmall>
              <FormikTextField
                name="transportationServicesPalletLength"
                label="Pallets Length"
                size="small"
                InputProps={{
                  endAdornment: 'inches',
                }}
                fullWidth
                float
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HeaderSmall>Pallets Weight</HeaderSmall>
              <FormikTextField
                name="transportationServicesPalletWeight"
                label="Pallets Weight"
                size="small"
                InputProps={{
                  endAdornment: 'pounds',
                }}
                fullWidth
                float
              />
            </Grid>
          </Grid>
          <HeaderSmall>Can they be stacked?</HeaderSmall>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2">
                Specify if any of the tenants pallets can be stacked
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ButtonGroup variant="outlined" sx={{ height: '100%' }} disableElevation fullWidth>
                <Button
                  name="transportationServicesPalletStackable"
                  onClick={() => {
                    setFieldValue('transportationServicesPalletStackable', true).catch(
                      console.error,
                    )
                  }}
                  variant={values.transportationServicesPalletStackable ? 'contained' : 'outlined'}
                  fullWidth
                  style={{
                    textTransform: 'none',
                  }}
                >
                  Yes
                </Button>
                <Button
                  name="transportationServicesPalletStackable"
                  onClick={() => {
                    setFieldValue('transportationServicesPalletStackable', false).catch(
                      console.error,
                    )
                  }}
                  variant={!values.transportationServicesPalletStackable ? 'contained' : 'outlined'}
                  fullWidth
                  style={{
                    textTransform: 'none',
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </Grid>
            {values.transportationServicesPalletStackable && (
              <>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2">How many pallets can they be stacked?</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    name="transportationServicesPalletStackableLevels"
                    label="Stackable Levels"
                    size="small"
                    InputProps={{
                      endAdornment: 'levels',
                    }}
                    fullWidth
                    integer
                  />
                </Grid>
              </>
            )}
          </Grid>
        </FormAccordionOption>
        <CheckboxRequestCluster
          clusterName="transportationServicesOptions"
          header="Handled Materials"
          data={optionsByHeader[OPTION_HEADERS_ID.HANDLED_GOODS]}
        />
        <FormAccordionOption title="Chemical/Hazmat">
          <FormikCheckBox name="transportationServicesHazardousMaterial" label="Chemical/Hazmat" />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <HeaderSmall>What kind of hazardous materials? </HeaderSmall>
              <FormikTextField
                name="transportationServicesHazardousMaterialTypeOfMaterials"
                label="Hazmat Type"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <HeaderSmall>Are there any specific requirements or conditions?</HeaderSmall>
              <FormikTextField
                name="transportationServicesHazardousMaterialRequiredConditions"
                label="Special Conditions"
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
        </FormAccordionOption>
      </FormAccordionType>
    )
  }, [
    goodsInOutFrequencyUnits,
    opportunitySpaceUnits,
    optionsByHeader,
    selectedTimeFrameValue,
    setFieldValue,
    spaceUnits,
    transportationServicesDateEnd,
    transportationServicesDateStart,
    values.transportationServicesPalletStackable,
  ])
}

export default TransportationForm
