// import { useNavigate } from 'react-router-dom'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Box, Grid, ListItem, ListItemAvatar, ListItemText, useTheme } from '@mui/material'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Assignment, TipsAndUpdates } from '@mui/icons-material'
// import ButtonLinkClickable from 'components/ButtonLinkClickable'
import ChunkerButtonWithImage from 'components/ChunkerButtonWithImage'
import HeaderPage from 'components/HeaderPage'
import HeaderSubPage from 'components/HeaderPageSub'
import PageContainer from 'AppLayout/PageContainer'
import URLS from 'lib/constants/urls'
import windowOpenBlank from 'lib/utils/windowUtls/windowOpenBlank'
import iconLogistics from '../../assets/images/icon-logistics-platform-light-brown.png'
import iconMarketplace from '../../assets/images/icon-marketplace-light-brown.png'
import iconYMS from '../../assets/images/icon-yard-management-system-light-brown.png'

export default function Home() {
  // const navigate = useNavigate()
  // const theme = useTheme()

  return (
    <PageContainer>
      <HeaderPage>Welcome to the Chunker Family</HeaderPage>
      <HeaderSubPage sx={{ mt: 6 }}>Our Platforms</HeaderSubPage>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <ChunkerButtonWithImage
            onClick={() => windowOpenBlank(URLS.leaveCompanyPortal.logisticsPlanningPlatform)}
            image={iconLogistics}
            text="LOGISTICS PLANNING PLATFORM"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ChunkerButtonWithImage
            onClick={() => windowOpenBlank(URLS.leaveCompanyPortal.marketPlace)}
            image={iconMarketplace}
            text="CHUNKER MARKETPLACE"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ChunkerButtonWithImage
            onClick={() => windowOpenBlank(URLS.leaveCompanyPortal.yardManagementSystem)}
            image={iconYMS}
            text="YARD MANAGEMENT SYSTEM"
            disabled
          />
        </Grid>
      </Grid>
      <HeaderSubPage sx={{ mt: 6, color: 'primary.main' }}>News & Updates</HeaderSubPage>
      <ListItem>
        <ListItemAvatar sx={{ display: 'flex', alignItems: 'center' }}>
          <TipsAndUpdates color="primary" />
        </ListItemAvatar>
        <ListItemText
          primary="Logistics Planning Platform will be available for you soon!"
          primaryTypographyProps={{ variant: 'h6', color: 'secondary.dark' }}
        />
      </ListItem>
      {/* <HeaderSubPage sx={{ mt: 6, color: 'primary.main' }}>Next Steps</HeaderSubPage>
      <ListItem>
        <ListItemAvatar sx={{ display: 'flex', alignItems: 'center' }}>
          <Assignment color="primary" />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box
              sx={{
                display: 'flex',
                whiteSpace: 'break-spaces',
                flexWrap: 'wrap',
                alignItems: 'center',
              }}
            >
              Don’t forget to complete your{' '}
              <ButtonLinkClickable
                onClick={() => navigate(URLS.profile)}
                sx={{ fontSize: theme.typography.h6, mr: 1 }}
              >
                user
              </ButtonLinkClickable>
              and
              <ButtonLinkClickable
                onClick={() => navigate(URLS.companyDetails)}
                sx={{ fontSize: theme.typography.h6, ml: 1 }}
              >
                company
              </ButtonLinkClickable>
              .
            </Box>
          }
          primaryTypographyProps={{ variant: 'h6', color: 'secondary.dark' }}
        />
      </ListItem> */}
    </PageContainer>
  )
}
