import { OpportunityContext } from 'context/OpportunityContextProvider'
import { useContext } from 'react'

/**
 *
 * @description useOpportunityCTX
 * - Provides opportunity statuses, reasons, options, space units, and frequency units from OpportunityContextProvider.
 * - This is a convenience hook to avoid having to import OpportunityContextProvider and useContext.
 * - Data is fetched on mount of OpportunityContextProvider and is available to all children, whenever the user is logged in.
 */
export default function useOpportunityCTX() {
  const ctx = useContext(OpportunityContext)
  if (!ctx)
    throw new Error(
      'useOpportunityContext is being used outside of its context provider. Please ensure it is within the context provider.',
    )

  return ctx
}
