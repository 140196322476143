import { Box, Grid, Typography, useTheme } from '@mui/material'
import SalesUtilityIcons from 'components/SalesUtilityIcons'

function ContactOrOwnerInfo({
  title,
  name,
  email,
  phone,
  link,
}: {
  title?: string
  name?: string
  email?: string
  phone?: string
  link?: string
}) {
  const theme = useTheme()
  return (
    <Grid container spacing={2}>
      {/* Left Side: Title and Name */}
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Typography>{name}</Typography>
          <Typography
            sx={{
              color: theme.palette.grey[700],
              fontWeight: '500',
              fontSize: '0.8rem',
            }}
          >
            {title}
          </Typography>
        </Box>
      </Grid>

      {/* Right Side: Email and Phone */}
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography>{email}</Typography>
            <SalesUtilityIcons clipboardValue={email} clipboardTooltip="Copy to clipboard" />
          </Box>
          <Typography>{phone}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography sx={{ color: theme.palette.primary.main }}>{link}</Typography>
            <SalesUtilityIcons linkValue={link} linkTooltip="Open in new tab" />
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ContactOrOwnerInfo
