import { createTheme, responsiveFontSizes } from '@mui/material'
import './fonts.css'

let theme = createTheme({
  typography: {
    fontFamily: 'Forza-Book, Saira-Condensed',
    h1: {
      fontWeight: 700,
      fontSize: '2.25rem',
    },
    h2: {
      fontFamily: 'Forza-Book',
      fontWeight: 700,
      fontSize: '2rem',
    },
    h3: {
      fontFamily: 'Forza-Book',
      fontWeight: 700,
      fontSize: '1.75rem',
    },
    h4: {
      fontFamily: 'Forza-Book',
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h5: {
      fontFamily: 'Forza-Book',
      fontWeight: 600,
      fontSize: '1.25rem',
    },
    h6: {
      fontFamily: 'Forza-Book',
      fontWeight: 600,
      fontSize: '1rem',
    },
    subtitle1: {
      fontFamily: 'Forza-Book',
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: 'Forza-Book',
      fontWeight: 500,
    },
    body1: {
      fontFamily: 'Saira-Condensed',
    },
    body2: {
      fontFamily: 'Saira-Condensed',
    },
    button: {
      fontFamily: 'Saira-Condensed',
      fontWeight: 700,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      light: '#F0A233',
      main: '#ED8B00',
      dark: '#A56100',
      contrastText: 'white',
    },
    secondary: {
      light: '#9B938B',
      main: '#83786F',
      dark: '#5B544D',
      contrastText: 'white',
    },
  },
  spacing: (factor: number) => `${0.25 * factor}rem`,
  components: {
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme: t }) => ({
          '&.Mui-selected': {
            backgroundColor: `rgba(0, 0, 0, 0.04)`,
            '*': {
              fontWeight: '600',
              color: t.palette.primary.main,
            },
          },
          '&.Mui-selected:hover': {
            backgroundColor: `rgba(0, 0, 0, 0.08)`,
          },
        }),
      },
    },
  },
})

export default theme = responsiveFontSizes(theme)
