import { useNavigate } from 'react-router-dom'
import { useUserCTX } from '@chunker/user-ctx'
import { Divider, Grid } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import URLS from 'lib/constants/urls'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import isUserStatusLoggedInOrReinitializing from 'lib/utils/userUtils/isUserStatusLoggedInOrReinitializing'
import ChunkerLogo from 'components/ChunkerLogo'
import UserMenu from './UserMenu'
import HamburgerMenu from './HamburgerMenu'

export default function Navbar() {
  const navigate = useNavigate()
  const { status } = useUserCTX()

  if (!isUserStatusLoggedInOrReinitializing(status)) return null

  return (
    <AppBar position="static" color="secondary" data-testid="navbar" sx={{ p: 0 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <ChunkerLogo data-testid="navbar-chunker-logo" onClick={() => navigate(URLS.home)} />
          <Grid container justifyContent="flex-end">
            <Grid item sx={{ px: 1 }}>
              <UserMenu />
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ marginTop: 1, marginBottom: 1, borderColor: (t) => t.palette.grey[500] }}
            />
            <Grid item sx={{ display: 'flex', alignItems: 'center', px: 3 }}>
              <HamburgerMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
