import { Theme } from '@mui/material'
import { OptionState } from 'components/AddressSearchBar/types'

function getOptionBackgroundColor(state: OptionState, theme: Theme) {
  if (state.isFocused) {
    return theme.palette.secondary.light
  }
  if (state.isSelected) {
    return theme.palette.secondary.dark
  }
  return theme.palette.common.white
}

export default getOptionBackgroundColor
