import { Box, Grid, Theme, Typography, alpha, useTheme } from '@mui/material'
import HeaderSubPage from 'components/HeaderPageSub'
import InfoCardWithImage from 'components/InfoCardWithImage'
import iconMarketplace from '../../../assets/images/icon-marketplace.png'
import iconLogistics from '../../../assets/images/icon-logistics-platform.png'
import iconYMS from '../../../assets/images/icon-yard-management-system.png'

const sharedHeaderCardStyle = (theme: Theme) => {
  return {
    color: 'primary.main',
    textShadow: `0px 2px 4px ${alpha(theme.palette.common.black, 0.1)}`,
    letterSpacing: '0.1em',
    mb: 0,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  }
}

function ProductBranding() {
  const theme = useTheme()
  return (
    <Box
      sx={{
        maxWidth: theme.breakpoints.values.sm, // Set a maximum width for the content
        margin: '0 auto', // Center content horizontally
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(6),
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontWeight: '900',
          textShadow: `0px 10px 4px ${alpha(theme.palette.common.black, 0.1)}`,
          letterSpacing: '0.1em',
          pb: theme.spacing(4),
        }}
      >
        OUR <br />
        <span style={{ color: theme.palette.secondary.dark }}>PRODUCTS</span>
      </Typography>
      <Grid container>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <InfoCardWithImage
              image={iconMarketplace}
              text={
                <Grid container>
                  <Grid item>
                    <HeaderSubPage sx={{ ...sharedHeaderCardStyle(theme) }}>
                      CHUNKER MARKETPLACE
                    </HeaderSubPage>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" sx={{ color: 'secondary.dark' }}>
                      Short-term warehouse marketplace
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">
                      Find temporary storage or additional space effortlessly. Seamlessly connect
                      with reliable warehouse solutions tailored to your specific needs.
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InfoCardWithImage
              image={iconLogistics}
              text={
                <Grid container>
                  <Grid item>
                    <HeaderSubPage sx={{ ...sharedHeaderCardStyle(theme) }}>
                      LOGISTICS PLANNING PLATFORM
                    </HeaderSubPage>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" sx={{ color: 'secondary.dark' }}>
                      A logistics platform solution with predictive Insights
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">
                      Streamline your logistics operations with advanced predictive insights.
                      Optimize your supply chain, enhance decision-making, and drive efficiency with
                      data-driven intelligence.
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InfoCardWithImage
              image={iconYMS}
              text={
                <Grid container>
                  <Grid item>
                    <HeaderSubPage sx={{ ...sharedHeaderCardStyle(theme) }}>
                      YARD MANAGEMENT SYSTEM
                    </HeaderSubPage>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" sx={{ color: 'secondary.dark' }}>
                      Streamline the management of containers and trailers
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">
                      Simplify the management of containers and trailers with ease. Efficiently
                      track, monitor, and optimize movement within your yard, improving visibility
                      and productivity.
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProductBranding
