import { useEffect } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Grid, InputLabel, Theme, Typography, useTheme } from '@mui/material'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { AxiosTypes, userJwtController } from '@chunker/chunker-request'
import { useUserCTX } from '@chunker/user-ctx'
import useLocationRedirectState from 'hooks/useLocationRedirectState'
import useNotify from 'hooks/useNotify'
import { EMAIL } from 'lib/constants/regex'
import URLS from 'lib/constants/urls'
import { buttonDarkContrastStyle } from 'components/ButtonWithDarkContrast'
import ChunkerButtonWithLoading from 'components/ChunkerButtonWithLoading'
import LogoCaption from 'components/LogoCaption'
import FormikCheckBox from 'components/FormikCheckbox'
import FormikTextField from 'components/FormikTextField'
import LoadingPage from 'components/LoadingPage'
import SplitPageContainer from 'AppLayout/SplitPageContainer'
import logoChunkerLong from '../../assets/logo-chunker-orange-long.png'
import ProductBranding from './ProductBranding'

const sharedInputStyle = {
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: 'white',
  },
  '&:focus': {
    backgroundColor: 'white',
  },
  '&:blur': {
    backgroundColor: 'white',
  },
}

const sharedFormHelperTextStyle = (theme: Theme) => {
  return {
    color: 'white',
    fontSize: theme.typography.caption.fontSize,
    fontWeight: 'bold',
    '&.Mui-error': {
      color: 'white',
    },
  }
}

function Login() {
  const theme = useTheme()
  const [searchParams] = useSearchParams()
  const passwordChanged = searchParams.get('resetPasswordSuccess')
  const newAccount = searchParams.get('key')

  const { setupUserSession, status } = useUserCTX()
  const { redirect } = useLocationRedirectState()
  const { notifyError, notifySuccess } = useNotify()
  const navigate = useNavigate()

  useEffect(() => {
    if (passwordChanged) {
      notifySuccess('Password successfully changed. Please login with your new password.')
    }
    if (newAccount) {
      notifySuccess('Your email has been verified. Please login to continue.')
    }
  }, [newAccount, notifySuccess, passwordChanged])

  /**
   * To ensure that the user does not try to return to the login page and try and re login as a different user
   * We will block them from doing so. This could cause problems because state that is set app wide would all need to understand this
   * process which we don't need to occur in the fist place.
   */
  if (status.isLoadingUser) return <LoadingPage />
  if (status.isUserLoggedIn) return <Navigate to={URLS.home} />

  return (
    <SplitPageContainer
      leftContent={<ProductBranding />}
      rightContent={
        <Box
          sx={{
            maxWidth: theme.breakpoints.values.sm, // Set a maximum width for the content
            margin: '0 auto', // Center content horizontally
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: theme.spacing(6),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              component="img"
              src={logoChunkerLong}
              sx={{
                maxWidth: '140px',
                height: 'auto',
                my: theme.spacing(6),
              }}
            />
            <Formik
              initialValues={{
                username: '',
                password: '',
                rememberMe: true,
              }}
              validationSchema={yup.object().shape({
                username: yup
                  .string()
                  .matches(EMAIL, 'Invalid email format')
                  .required('Email is required'),
                password: yup.string().required('Password is required'),
                rememberMe: yup.boolean().required(),
              })}
              onSubmit={async (values) => {
                try {
                  await userJwtController.requests.postAuthenticate(values)
                  await setupUserSession()

                  navigate(redirect ?? URLS.home, { replace: true })
                } catch (error) {
                  if (error instanceof AxiosTypes.AxiosError) {
                    if (error.response?.data) {
                      const data = error.response.data as { detail: string }
                      notifyError(data.detail)
                      return
                    }
                  }
                  notifyError('Invalid email or password. Please try again.')
                  console.error(error)
                }
              }}
            >
              {({ isValid, isSubmitting }) => {
                return (
                  <Form>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h3"
                          gutterBottom
                          align="center"
                          sx={{
                            color: 'white',
                          }}
                        >
                          Sign In to Your Account
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel
                          htmlFor="username"
                          sx={{ color: 'white', fontWeight: 'bold' }}
                          required
                        >
                          Email
                        </InputLabel>
                        <FormikTextField
                          type="email"
                          id="username"
                          name="username"
                          autoComplete="email"
                          InputProps={{ sx: sharedInputStyle }}
                          FormHelperTextProps={{ sx: sharedFormHelperTextStyle(theme) }}
                          fullWidth
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel
                          htmlFor="password"
                          sx={{ color: 'white', fontWeight: 'bold' }}
                          required
                        >
                          Password
                        </InputLabel>
                        <FormikTextField
                          type="password"
                          id="password"
                          name="password"
                          autoComplete="current-password"
                          InputProps={{ sx: sharedInputStyle }}
                          FormHelperTextProps={{ sx: sharedFormHelperTextStyle(theme) }}
                          fullWidth
                          size="small"
                        />
                      </Grid>
                      {/* <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Typography
                          variant="body1"
                          align="right"
                          sx={{ color: 'white', fontWeight: 'bold' }}
                          component="a"
                          rel="noopener noreferrer"
                          target="_blank"
                          href={URLS.forgotPassword}
                        >
                          Forgot your Password?
                        </Typography>
                      </Grid> */}
                      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <FormikCheckBox
                          name="rememberMe"
                          label="Remember me"
                          FormControlLabelProps={{
                            slotProps: { typography: { variant: 'subtitle1', color: 'white' } },
                            color: 'white',
                          }}
                          CheckboxProps={{
                            sx: {
                              '&:hover': {
                                '& .MuiSvgIcon-root': {
                                  color: 'white',
                                },
                              },
                              '& .MuiSvgIcon-root': {
                                color: 'white',
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <ChunkerButtonWithLoading
                          type="submit"
                          sx={{ width: '50%', ...buttonDarkContrastStyle(theme) }}
                          loading={isSubmitting}
                          loadingVariant="end"
                          disabled={!isValid}
                          size="large"
                        >
                          Log In
                        </ChunkerButtonWithLoading>
                      </Grid>
                      {/* <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography component="span" sx={{ mr: 1, color: 'white' }}>
                          Don&#39;t have an account?{' '}
                        </Typography>
                        <Typography
                          component="a"
                          href={URLS.signup}
                          data-testid="signup-link"
                          sx={{ color: 'white', textDecoration: 'underline', fontWeight: 'bold' }}
                        >
                          Create one here.
                        </Typography>
                      </Grid> */}
                    </Grid>
                    <LogoCaption sxText={{ color: 'white' }} />
                  </Form>
                )
              }}
            </Formik>
          </Box>
        </Box>
      }
    />
  )
}

export default Login
