import { useState } from 'react'
import { useFormikContext } from 'formik'
import { Close, Contacts, Delete } from '@mui/icons-material'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Theme,
  useTheme,
} from '@mui/material'
import FormSearchCustomerByName from 'components/FormSearchCustomerByName'
import FormHeaderDivider from 'components/FormHeaderDivider'
import FormHeaderStep from 'components/FormHeaderStep'
import FormikCompanyCluster from 'components/FormikCompanyCluster'
import FormikCustomerContactCluster from 'components/FormikCustomerContactCluster'
import FormikTextFieldEditable from 'components/FormikTextFieldEditable'
import FormSearchContacts from 'components/FormSearchContacts'
import { DeepPartial } from '@chunker/chunker-request/dist/types/DeepPartial'
import { OpportunitiesContact, Opportunity } from '@chunker/chunker-request'
import OpportunityBackNextButtons from '../OpportunityActionsButtons'

function CustomerAndContactInformation({ onClose }: { onClose: () => void }) {
  const { values, setFieldValue } = useFormikContext<Opportunity & { currentStep: number }>()

  const [isNewCustomer, setIsNewCustomer] = useState(false)

  const theme = useTheme<Theme>()

  return (
    <>
      <DialogTitle>
        <FormHeaderStep
          step="1"
          title="Customer & Contact Information"
          description="Who is our potential customer, and what are the contact person&#39;s details?"
          Icon={Contacts}
        />
        <Divider sx={{ my: 3 }} />
        <FormikTextFieldEditable id="name" name="name" label="Opportunity Name" required />
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (t) => t.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormHeaderDivider sx={{ my: 3 }}>CUSTOMER INFORMATION</FormHeaderDivider>
        <FormSearchCustomerByName
          onSelect={(selectedOption) => {
            if (!selectedOption) {
              setFieldValue('customer', {
                id: null,
                name: '',
                streetAddress: '',
                streetAddress2: '',
                city: '',
                state: '',
                postalCode: '',
                country: '',
                website: '',
              }).catch(console.error)
              setIsNewCustomer(true)
              return
            }
            setFieldValue('customer', selectedOption).catch(console.error)
            setIsNewCustomer(false)
          }}
          selectedCustomer={values?.customer}
          TextFieldProps={{ size: 'small' }}
        />
        {(isNewCustomer || values.customer?.id || values.customer?.name) && (
          <FormikCompanyCluster
            fieldNameAddress="customer.streetAddress"
            fieldNameCity="customer.city"
            fieldNameCountry="customer.country"
            fieldNameState="customer.state"
            fieldNamePostalCode="customer.postalCode"
            fieldNameCompanyName="customer.name"
            fieldNameCompanyWebsite="customer.website"
            OutlinedContainerProps={{ sx: { mt: 3 } }}
            readOnly={!isNewCustomer && Boolean(values.customer?.id)}
          />
        )}
        <FormHeaderDivider sx={{ my: 3 }}>CONTACT INFORMATION</FormHeaderDivider>
        <FormSearchContacts
          onSelect={(selectedOptions, newContact = false) => {
            if (newContact) {
              setFieldValue('opportunitiesContacts', [
                ...(values.opportunitiesContacts ?? []),
                {
                  id: null,
                  primaryContact: false,
                  contact: {
                    id: null,
                    firstName: '',
                    lastName: '',
                    title: '',
                    contactPhone: '',
                    contactEmail: '',
                  },
                },
              ]).catch(console.error)
              return
            }

            if (!selectedOptions) {
              setFieldValue('opportunitiesContacts', [
                ...(values.opportunitiesContacts ?? []),
              ]).catch(console.error)
              return
            }

            const newContacts =
              values.opportunitiesContacts?.filter(
                (oppContact: OpportunitiesContact) => !oppContact.contact?.id,
              ) ?? []

            setFieldValue('opportunitiesContacts', [...newContacts, ...selectedOptions]).catch(
              console.error,
            )
          }}
          selectedContacts={values.opportunitiesContacts}
          TextFieldProps={{ size: 'small' }}
        />
        {values.opportunitiesContacts &&
          values.opportunitiesContacts.length > 0 &&
          values.opportunitiesContacts.map(
            (oppContact: DeepPartial<OpportunitiesContact>, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`opportunitiesContacts-${index}`}>
                  <FormikCustomerContactCluster
                    fieldCustomerContactFirstName={`opportunitiesContacts[${index}].contact.firstName`}
                    fieldCustomerContactLastName={`opportunitiesContacts[${index}].contact.lastName`}
                    fieldCustomerContactTitle={`opportunitiesContacts[${index}].contact.title`}
                    fieldCustomerContactPhone={`opportunitiesContacts[${index}].contact.contactPhone`}
                    fieldCustomerContactEmail={`opportunitiesContacts[${index}].contact.contactEmail`}
                    fieldCustomerContactPrimaryContact={`opportunitiesContacts[${index}].primaryContact`}
                    OutlinedContainerProps={{ sx: { mt: 3 } }}
                    readOnly={Boolean(oppContact.contact?.id)}
                  />
                  {values.opportunitiesContacts &&
                    values.opportunitiesContacts.length > 1 &&
                    !oppContact.contact?.id && (
                      <>
                        <Button
                          variant="outlined"
                          color="secondary"
                          startIcon={<Delete />}
                          onClick={() => {
                            setFieldValue(
                              'opportunitiesContacts',
                              values.opportunitiesContacts?.filter((_, i) => i !== index),
                            ).catch(console.error)
                          }}
                          sx={{ my: 3 }}
                          fullWidth
                        >
                          Delete Contact
                        </Button>
                        <hr
                          style={{
                            border: 'none',
                            borderTop: `1px solid ${theme.palette.secondary.light}`,
                            margin: '5px 0',
                          }}
                        />
                      </>
                    )}
                </div>
              )
            },
          )}
      </DialogContent>
      <DialogActions>
        <OpportunityBackNextButtons action="next" />
      </DialogActions>
    </>
  )
}

export default CustomerAndContactInformation
