/* eslint-disable @typescript-eslint/no-unsafe-return */
import { TravelExplore } from '@mui/icons-material'
import { Box, Typography, useTheme } from '@mui/material'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { useFormikContext } from 'formik'
import useNotify from 'hooks/useNotify'
import { FormValues } from 'types/FormValidation'
import { IAddressSearchBarProps } from './types'
import setAddressValues from './utils/setAddressValues'
import getOptionBackgroundColor from './utils/getOptionBackgroundColor'

function AddressSearchBar({ fieldMapping, disabled }: IAddressSearchBarProps) {
  const theme = useTheme()
  const { notifyError } = useNotify()
  const { values, setValues, setFieldTouched } = useFormikContext<FormValues>()

  return (
    <GooglePlacesAutocomplete
      selectProps={{
        isDisabled: disabled,
        placeholder: (
          <Box sx={{ display: 'flex' }}>
            <TravelExplore />
            <Typography sx={{ ml: 2 }} data-testid="address-search-bar-placeholder">
              Search for an address...
            </Typography>
          </Box>
        ),
        onChange: (newValue) => {
          // This function is called when a user selects an address from the dropdown
          // It geocodes the address to get the address components
          setAddressValues(newValue, values, setValues, notifyError, setFieldTouched, fieldMapping)
        },
        styles: {
          control: (provided) =>
            ({
              ...provided,
              width: '100%',
              boxShadow: 'none',
              borderColor: 'lightgray',
              '&:hover': {
                boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
                borderColor: theme.palette.primary.main,
              },
              '&:focus': {
                boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
                borderColor: theme.palette.primary.main,
              },
              '&:blur': {
                boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
                borderColor: theme.palette.primary.main,
              },
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }) as any,
          option: (provided, state) =>
            ({
              ...provided,
              backgroundColor: getOptionBackgroundColor(state, theme),
              '&:hover': {
                backgroundColor: theme.palette.secondary.light,
              },
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }) as any,
        },
      }}
      autocompletionRequest={{ componentRestrictions: { country: ['us', 'ca'] } }}
      onLoadFailed={(error) => {
        notifyError()
        console.error('Could not inject Google script', error)
      }}
    />
  )
}

export default AddressSearchBar
