import { CircularProgress, Container } from '@mui/material'
import { ITestIds } from 'types/ITestIds'

/**
 * @description
 * - Provides a full page spinner when things are currently loading.
 */
export default function LoadingPage({ ...props }: ITestIds) {
  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
      data-testid={props['data-testid'] ?? 'loading-page'}
    >
      <CircularProgress data-testid="loading-page-circular-progress" />
    </Container>
  )
}
