import { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Tooltip,
  IconButton,
  TableFooter,
  TablePagination,
} from '@mui/material'
import {
  CustomerLocation,
  OpportunitySolution,
} from '@chunker/chunker-request/dist/types/OpportunitySolution'
import InfoIcon from '@mui/icons-material/Info'
import ExportCSV from 'components/ExportCsv'
import WarehouseDetailsDialog from '../WarehouseDetailsDialog'
import {
  renderLocationAddressCell,
  renderLocationDistanceCell,
  renderLocationTypeCell,
} from '../utils'

function WarehouseCustomerLocationsTable({
  locations,
}: {
  locations: OpportunitySolution['customerLocations']
}) {
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState<CustomerLocation | null>(null)
  const [page, setPage] = useState(0)
  const [csvData, setCsvData] = useState<{ headers: string[]; rows: Record<string, unknown>[] }>()
  const rowsPerPage = 5

  const displayedLocations = locations?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleOpenModal = (location: CustomerLocation) => {
    setSelectedLocation(location)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setSelectedLocation(null)
  }

  useEffect(() => {
    const headers = [
      'customer',
      'source_url',
      'first_name',
      'last_name',
      'contact_email',
      'contact_phone',
      'title',
    ] as string[]

    const rows = [] as Record<string, unknown>[]

    locations?.forEach((location) => {
      const customer = location?.customer?.name
      const contacts = location?.customer?.contacts

      if (contacts) {
        contacts.forEach((contact) => {
          if (!contact?.contactEmail) return

          const row = {
            customer,
            source_url: location?.sourceURL,
            first_name: contact?.firstName,
            last_name: contact?.lastName,
            contact_email: contact?.contactEmail,
            contact_phone: contact?.contactPhone,
            title: contact?.title,
          }

          rows.push(row)
        })
      }
    })

    setCsvData({ headers, rows })
  }, [locations])

  return (
    <TableContainer>
      <ExportCSV data={csvData} fileName="warehouse-contacts-other-locations.csv" />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '5%' }} />
            <TableCell sx={{ width: '50%' }}>Address</TableCell>
            <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
              Distance
              <Tooltip title="Distance from the opportunity location">
                <IconButton size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedLocations?.map((location) => (
            <TableRow key={location.id}>
              <TableCell />
              <TableCell>{renderLocationAddressCell(location)}</TableCell>
              <TableCell>{renderLocationDistanceCell(location)}</TableCell>
              <TableCell>{renderLocationTypeCell(location)}</TableCell>
              <TableCell>
                <Button variant="outlined" onClick={() => handleOpenModal(location)}>
                  Details
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              count={locations?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
      {selectedLocation && (
        <WarehouseDetailsDialog
          open={modalOpen}
          handleClose={handleCloseModal}
          location={selectedLocation}
        />
      )}
    </TableContainer>
  )
}

export default WarehouseCustomerLocationsTable
