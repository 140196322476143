import { useState } from 'react'
import { IconButton, Link, Snackbar, Tooltip } from '@mui/material'
import { Assignment, OpenInNew } from '@mui/icons-material'
import { validateUrl } from 'pages/OpportunitiesList/OpportunitiesForm/WarehouseSolutions/utils'

export default function SalesUtilityIcons({
  clipboardValue,
  clipboardTooltip,
  linkValue,
  linkTooltip,
}: {
  clipboardValue?: string
  clipboardTooltip?: string
  linkValue?: string
  linkTooltip?: string
}) {
  const [open, setOpen] = useState(false)

  const handleCopyToClipboard = () => {
    setOpen(true)

    if (!clipboardValue) return

    window.navigator.clipboard.writeText(clipboardValue).catch((err) => {
      console.error('Could not copy text: ', err)
    })
  }

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={() => setOpen(false)}
        message="Copied to clipboard!"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
      {clipboardValue && (
        <Tooltip title={clipboardTooltip}>
          <IconButton
            onClick={handleCopyToClipboard}
            color="primary"
            size="small"
            disableFocusRipple
            disableRipple
          >
            <Assignment />
          </IconButton>
        </Tooltip>
      )}
      {linkValue && (
        <Tooltip title={linkTooltip}>
          <Link
            href={validateUrl(linkValue)}
            target="_blank"
            rel="noopener noreferrer"
            underline="always"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <OpenInNew />
          </Link>
        </Tooltip>
      )}
    </>
  )
}
