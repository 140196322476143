import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { IChildren } from 'types/IChildren'

const stripeKey = process.env.REACT_APP_STRIPE_API_KEY

if (!stripeKey) console.error('Stripe API key not found')

const stripePromise = loadStripe(stripeKey ?? '')

export default function StripeElementsProvider({ children }: IChildren) {
  return <Elements stripe={stripePromise}>{children}</Elements>
}
