import { CircularProgress } from '@mui/material'
import ChunkerButton from 'components/ChunkerButton'
import { IButtonWithLoadingProps } from './types'

export default function ChunkerButtonWithLoading({
  loading,
  disabledLoading,
  loadingVariant = 'start',
  ...props
}: IButtonWithLoadingProps) {
  const isStartVariant = loadingVariant === 'start'

  const getLoadingAnimation = () => {
    return loading || disabledLoading ? (
      <CircularProgress
        data-testid={`button-with-loading-${isStartVariant ? 'start' : 'end'}`}
        color="primary"
        size={!props.size || props.size === 'medium' || props.size === 'small' ? 14 : 18}
      />
    ) : null
  }

  return (
    <ChunkerButton
      {...props}
      disabled={disabledLoading || props.disabled}
      startIcon={(loadingVariant === 'start' ? getLoadingAnimation() : null) ?? props.startIcon}
      endIcon={(loadingVariant === 'end' ? getLoadingAnimation() : null) ?? props.endIcon}
    />
  )
}
