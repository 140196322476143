import { StandardChunkerAPIType, opportunityStatusReasonResource } from '@chunker/chunker-request'
import useNotify from 'hooks/useNotify'
import { useCallback, useEffect, useState } from 'react'

function useOpportunityStatusReasons() {
  const { notifyError } = useNotify()
  const [opportunityStatusReasons, setOpportunityStatusReasons] = useState<
    Array<StandardChunkerAPIType & { opportunityStatus: StandardChunkerAPIType }>
  >([])
  const [loading, setLoading] = useState<boolean>(true)

  const fetchOpportunityStatusReasons = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await opportunityStatusReasonResource.requests.getOpportunityStatusReasons()
      setOpportunityStatusReasons(data)
    } catch (error) {
      console.error(error)
      notifyError()
    } finally {
      setLoading(false)
    }
  }, [notifyError])

  useEffect(() => {
    fetchOpportunityStatusReasons().catch(console.error)
  }, [fetchOpportunityStatusReasons])

  return { opportunityStatusReasons, fetch: fetchOpportunityStatusReasons, loading }
}

export default useOpportunityStatusReasons
