import { BrowserRouter } from 'react-router-dom'
import React from 'react'
import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ReactDOM from 'react-dom/client'
import theme from 'themes/theme'
import UserCTXProvider from 'context/UserCTXProvider'
import NotifyContextProvider from 'context/NotifyContextProvider'
import StripeElementsProvider from 'context/StripeElementsProvider'
import reportWebVitals from './reportWebVitals'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <NotifyContextProvider>
            <UserCTXProvider>
              <StripeElementsProvider>
                <App />
              </StripeElementsProvider>
            </UserCTXProvider>
          </NotifyContextProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
