import getSearchFiltersQuery from 'lib/utils/requestUtils/getSearchFiltersQuery'
import { IQueryState } from 'types/IQueryState'

function builtQuery(queryState: IQueryState) {
  return Object.entries(queryState)
    .reduce<string[]>((acc, [param, value]: Array<string>) => {
      let adjustedValue = value
      if (param === 'query') {
        if (value === '') {
          adjustedValue = '*'
        }
      }

      if (param === 'sort' && Array.isArray(value)) {
        // if sort is an empty array, return the accumulator
        if (value.length === 0) {
          return acc
        }

        const sortParams = value.map((sort: string) => `${param}=${sort}`).join('&')
        return [...acc, ...sortParams.split('&')]
      }

      if (queryState?.filters && queryState?.filters?.length > 0) {
        const filtersQuery =
          queryState.filters?.length > 0
            ? ` AND (${getSearchFiltersQuery(queryState.filters)})`
            : ''

        if (param === 'query') {
          return [...acc, `${param}=${adjustedValue}${filtersQuery}`]
        }
      }

      if (param === 'filters') {
        return acc
      }

      return [...acc, `${param}=${adjustedValue}`]
    }, [])
    .join('&')
}

export default builtQuery
