import { SyntheticEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { set } from 'lodash'
import { Opportunity } from '@chunker/chunker-request'
import { DeepPartial } from '@chunker/chunker-request/dist/types/DeepPartial'
import { Id } from '@chunker/chunker-request/dist/types/Id'
import opportunitiesResource from '@chunker/chunker-request/dist/opportunitiesResource'
import { Dialog, LinearProgress, useMediaQuery, useTheme } from '@mui/material'
import { EMAIL } from 'lib/constants/regex'
import { expectPhoneNumber } from 'lib/validations '
import useNotify from 'hooks/useNotify'
import { ValidationErrors } from 'types/FormValidation'
import * as yup from 'yup'
import extractData from 'lib/utils/requestUtils/extractData'
import OpportunityInformation from './OpportunityInformation'
import { expectAtLeastOneLocationGrouping, expectRequiredIfNewCustomer } from './validations'
import CustomerAndContactInformation from './CustomerAndContactInformation'
import WarehouseSolutions from './WarehouseSolutions'

function OpportunitiesForm({
  open,
  onClose,
  opportunityId,
}: {
  open: boolean
  onClose: () => void
  opportunityId?: Id
}) {
  const { id: opportunityParamId } = useParams()
  const { notifyError } = useNotify()
  const theme = useTheme()

  const [loading, setLoading] = useState(false)
  const [opportunityValues, setOpportunityValues] = useState<
    (DeepPartial<Opportunity> & { currentStep: number }) | null
  >(null)

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    setLoading(true)

    if ((!opportunityId && !opportunityParamId) || opportunityId === 'new') {
      setOpportunityValues(null)
      setLoading(false)
      return
    }

    opportunitiesResource.requests
      .getOpportunitiesById(opportunityId ?? (opportunityParamId as Id))
      .then(extractData)
      .then((opportunity) => {
        setOpportunityValues({
          currentStep: 0,
          ...opportunity,
        })
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        notifyError('Error retrieving opportunity.')
        setLoading(false)
      })
  }, [notifyError, opportunityId, opportunityParamId])

  if (loading) return <LinearProgress color="primary" />

  const currentStepContent = [
    <CustomerAndContactInformation key={0} onClose={onClose} />,
    <OpportunityInformation key={1} onClose={onClose} />,
    <WarehouseSolutions key={2} onClose={onClose} />,
  ]

  const validationSchema = [
    yup.object({
      name: yup.string().required('Please provide a name for this opportunity.'),
      customer: yup.object().shape(
        {
          id: yup.string().nullable(),
          name: yup.string().when('id', {
            is: (id: number | null) => !id,
            then: () => yup.string().required('Required'),
          }),
          streetAddress: yup.string(),
          city: expectAtLeastOneLocationGrouping(['state', 'postalCode', 'country', 'id']),
          state: expectAtLeastOneLocationGrouping(['city', 'postalCode', 'country', 'id']),
          country: expectAtLeastOneLocationGrouping(['city', 'postalCode', 'state', 'id']),
          postalCode: expectAtLeastOneLocationGrouping(['city', 'state', 'country', 'id']),
          website: yup.string().when('id', {
            is: (id: number | null) => !id,
            then: () =>
              yup
                .string()
                .url(
                  'Must be a valid URL. Ensure your URL starts with https:// (e.g. https://www.chunker.com).',
                )
                .required('Required'),
          }),
        },
        [
          ['city', 'state'],
          ['city', 'postalCode'],
          ['city', 'country'],
          ['state', 'postalCode'],
          ['state', 'country'],
          ['postalCode', 'country'],
        ],
      ),
      opportunitiesContacts: yup
        .array()
        .of(
          yup.object({
            id: yup.string().nullable(),
            primaryContact: yup.boolean(),
            contact: yup.object({
              id: yup.string().nullable(),
              firstName: expectRequiredIfNewCustomer(),
              lastName: expectRequiredIfNewCustomer(),
              title: yup.string(),
              contactPhone: yup.string().when('id', {
                is: (id: Id) => !id,
                then: () => expectPhoneNumber(),
              }),
              contactEmail: yup
                .string()
                .matches(EMAIL, 'Invalid email format')
                .concat(expectRequiredIfNewCustomer()),
            }),
          }),
        )
        .min(1, 'At least one contact is required'),
    }),
    yup.object({
      name: yup.string().required('Required'),
      opportunityStatus: yup.object({
        id: yup.number(),
      }),
      opportunityStatusReason: yup.object({
        id: yup.number(),
      }),
      notes: yup.string().max(400, 'Notes must be less than 400 characters.'),
      warehouseStreetAddress: yup.string(),
      warehouseCity: yup.string(),
      warehouseState: yup.string(),
      warehouseCountryCode: yup.string(),
      warehousePostalCode: yup.string(),
      warehouseLocation: yup.string(),
      warehouseTravelDistance: yup.number(),
      warehouseSpaceNeededMin: yup.number(),
      warehouseSpaceNeededMax: yup.number(),
      warehouseOpportunitySpaceUnit: yup.object({
        id: yup.number(),
      }),
      warehouseGoodsInOutFrequency: yup.number(),
      warehouseGoodsInOutFrequencyUnit: yup.object({
        id: yup.number(),
      }),
      warehouseMonthToMonth: yup.boolean(),
      warehouseDateStart: yup.string(),
      warehouseDateEnd: yup.string(),
      warehouseEstimatedBudgetTotal: yup.number(),
      warehouseEstimatedBudgetPerUnit: yup.number(),
      warehouseEstimatedServicesBudgetPerUnit: yup.number(),
      warehouseOptions: yup
        .array()
        .of(
          yup.object({
            id: yup.number(),
          }),
        )
        .nullable(),
      warehouseColdStorageRequired: yup.boolean(),
      warehouseColdStorageMinSize: yup.number(),
      warehouseColdStorageMaxSize: yup.number(),
      warehouseColdStorageMinTemp: yup.number(),
      warehouseColdStorageMaxTemp: yup.number(),
      warehouseOperationTimeOpen: yup.string(),
      warehouseOperationTimeClose: yup.string(),
      warehouseDockDoorHighDoors: yup.number(),
      warehouseDockDoorDriveInDoors: yup.number(),
      warehouseDockDoorHeight: yup.number(),
      warehouseDockDoorWidth: yup.number(),
      warehousePalletHeight: yup.number(),
      warehousePalletWidth: yup.number(),
      warehousePalletLength: yup.number(),
      warehousePalletWeight: yup.number(),
      warehousePalletStackable: yup.boolean(),
      warehousePalletStackableLevels: yup.number(),
      warehouseHazardousMaterial: yup.boolean(),
      warehouseHazardousMaterialTypeOfMaterials: yup.string(),
      warehouseHazardousMaterialRequiredConditions: yup.string(),

      containerStorageStreetAddress: yup.string(),
      containerStorageCity: yup.string(),
      containerStorageState: yup.string(),
      containerStorageCountryCode: yup.string(),
      containerStoragePostalCode: yup.string(),
      containerStorageLocation: yup.string(),
      containerStorageTravelDistance: yup.number(),
      containerStorageSpaceNeeded: yup.number(),
      containerStorageUnitsTotal: yup.number(),
      containerStorageUnitsPerLoad: yup.number(),
      containerStorageOpportunitySpaceUnit: yup.object({
        id: yup.number(),
      }),
      containerStorageGoodsInOutFrequency: yup.number(),
      containerStorageGoodsInOutFrequencyUnit: yup.object({
        id: yup.number(),
      }),
      containerStorageDateStart: yup.string(),
      containerStorageDateEnd: yup.string(),
      containerStorageEstimatedBudgetTotal: yup.number(),
      containerStorageEstimatedBudgetPerUnit: yup.number(),
      containerStorageParkingSpotTrailerSpots: yup.number(),
      containerStorageParkingSpotCarSpots: yup.number(),
      containerStorageParkingSpotTruckSpots: yup.number(),
      containerStorageContainerHeight: yup.number(),
      containerStorageContainerWidth: yup.number(),
      containerStorageContainerLength: yup.number(),
      containerStorageContainerWeight: yup.number(),
      containerStorageContainerStackable: yup.boolean(),
      containerStorageContainerStackableLevels: yup.number(),
      containerStoragePalletHeight: yup.number(),
      containerStoragePalletWidth: yup.number(),
      containerStoragePalletLength: yup.number(),
      containerStoragePalletWeight: yup.number(),
      containerStoragePalletStackable: yup.boolean(),
      containerStoragePalletStackableLevels: yup.number(),
      containerStorageHazardousMaterial: yup.boolean(),
      containerStorageHazardousMaterialTypeOfMaterials: yup.string(),
      containerStorageHazardousMaterialRequiredConditions: yup.string(),
      containerStorageOptions: yup
        .array()
        .of(
          yup.object({
            id: yup.number(),
          }),
        )
        .nullable(),

      transportationServicesOriginStreetAddress: yup.string(),
      transportationServicesOriginCity: yup.string(),
      transportationServicesOriginState: yup.string(),
      transportationServicesOriginPostalCode: yup.string(),
      transportationServicesOriginCountryCode: yup.string(),
      transportationServicesOriginLocation: yup.string(),

      transportationServicesDestinationStreetAddress: yup.string(),
      transportationServicesDestinationCity: yup.string(),
      transportationServicesDestinationState: yup.string(),
      transportationServicesDestinationPostalCode: yup.string(),
      transportationServicesDestinationCountryCode: yup.string(),
      transportationServicesDestinationLocation: yup.string(),

      transportationServicesAdditionalDestinationStreetAddress: yup.string(),
      transportationServicesAdditionalDestinationCity: yup.string(),
      transportationServicesAdditionalDestinationState: yup.string(),
      transportationServicesAdditionalDestinationPostalCode: yup.string(),
      transportationServicesAdditionalDestinationCountryCode: yup.string(),
      transportationServicesAdditionalDestinationLocation: yup.string(),

      transportationServicesTravelDistance: yup.number(),
      transportationServicesSpaceNeeded: yup.number(),
      transportationServicesOpportunitySpaceUnit: yup.object({
        id: yup.number(),
      }),
      transportationServicesGoodsInOutFrequency: yup.number(),
      transportationServicesGoodsInOutFrequencyUnit: yup.object({
        id: yup.number(),
      }),
      transportationServicesDateStart: yup.string(),
      transportationServicesDateEnd: yup.string(),
      transportationServicesEstimatedBudgetTotal: yup.number(),
      transportationServicesEstimatedBudgetPerUnit: yup.number(),
      transportationServicesPalletHeight: yup.number(),
      transportationServicesPalletWidth: yup.number(),
      transportationServicesPalletLength: yup.number(),
      transportationServicesPalletWeight: yup.number(),
      transportationServicesPalletStackable: yup.boolean(),
      transportationServicesPalletStackableLevels: yup.number(),
      transportationServicesHazardousMaterial: yup.boolean(),
      transportationServicesOptions: yup
        .array()
        .of(
          yup.object({
            id: yup.number(),
          }),
        )
        .nullable(),
    }),
  ]

  async function customValidate(
    valuesToValidate: DeepPartial<Opportunity> & { currentStep: number },
  ): Promise<ValidationErrors> {
    const currentValidationSchema = validationSchema[valuesToValidate.currentStep ?? 0]
    const errors: ValidationErrors = {}

    try {
      await currentValidationSchema.validate(valuesToValidate, { abortEarly: false })
    } catch (validationError) {
      if (validationError instanceof yup.ValidationError) {
        validationError?.inner?.forEach((error: yup.ValidationError) => {
          set(errors, error.path as string, error.message)
        })
      }
    }

    return errors
  }

  const handleClose = (_: SyntheticEvent<Element, Event>, reason: string) => {
    if (reason === 'backdropClick') return
    setOpportunityValues(null)
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={fullScreen} maxWidth="lg" fullWidth>
      <Formik
        initialValues={
          opportunityValues ?? {
            currentStep: 0,
            id: undefined,
            opportunitiesContacts: [],
            opportunityStatus: { id: 1 },
            warehouseTravelDistance: 50,
            warehouseOpportunitySpaceUnit: { id: 1 },
            warehouseGoodsInOutFrequencyUnit: { id: 1 },
            warehouseOptions: [],
            containerStorageTravelDistance: 50,
            containerStorageOpportunitySpaceUnit: { id: 3 },
            containerStorageGoodsInOutFrequencyUnit: { id: 1 },
            containerStorageOptions: [],
            transportationServicesTravelDistance: 50,
            transportationServicesOpportunitySpaceUnit: { id: 4 },
            transportationServicesGoodsInOutFrequencyUnit: { id: 1 },
            transportationServicesOptions: [],
          }
        }
        enableReinitialize
        validateOnMount
        validate={(values) => customValidate(values)}
        onSubmit={() => {}}
      >
        {({ values }) => {
          return <Form>{currentStepContent[values.currentStep]}</Form>
        }}
      </Formik>
    </Dialog>
  )
}

export default OpportunitiesForm
