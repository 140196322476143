import { isArray, isEqual, isObject, set, transform } from 'lodash'
import changeObject from './changeObject'
import { IObjectOptions } from './types'

/**
 * @description
 * - Deep difference between two objects
 * - Recursively compares object1 and object2 and returns the difference using transform.
 * - options can be used to omit arrays and clean empty objects - see IObjectOptions
 */
function deepDifference(object: object, base: object, options: IObjectOptions = {}) {
  const { omitArrays = false, cleanEmptyObjects = false, omitKeys = [] } = options

  function changes(changesObject: object, changesBase: object) {
    return transform(
      changesObject,
      (result, value, key) => {
        if (!isEqual(value, changesBase[key]))
          if (!omitKeys.includes(key)) {
            if (isArray(value) || isArray(changesBase[key])) {
              if (!omitArrays) set(result, key, value)
            } else {
              set(
                result,
                key,
                isObject(value) && isObject(changesBase[key])
                  ? changes(value, changesBase[key])
                  : value,
              )
            }
          }
      },
      {},
    )
  }

  const changedObject = changes(object, base)

  return changeObject(changedObject, {
    omitArrays,
    cleanEmptyObjects,
    omitKeys,
  })
}

export default deepDifference
