import { Typography, TypographyProps } from '@mui/material'

export default function HeaderMedium({ sx, ...props }: TypographyProps) {
  return (
    <Typography
      color="secondary.dark"
      sx={{ mb: 3, fontSize: 20, fontWeight: 600, ...sx }}
      {...props}
    />
  )
}
