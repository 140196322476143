/* eslint-disable import/prefer-default-export */

export const OPTION_HEADERS_ID = {
  BUILDING_ESSENTIALS: 1,
  YARD_ESSENTIALS: 2,
  SERVICES_NEEDED: 3,
  EQUIPMENT_NEEDED: 4,
  TRANSPORTATION_NEEDED: 5,
  HANDLED_GOODS: 6,
}
