import { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import {
  Alert,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
} from '@mui/material'
import { Close, Warehouse } from '@mui/icons-material'
import opportunitiesResource from '@chunker/chunker-request/dist/opportunitiesResource'
import { OpportunitySolution } from '@chunker/chunker-request/dist/types/OpportunitySolution'
import FormHeaderDivider from 'components/FormHeaderDivider'
import FormHeaderStep from 'components/FormHeaderStep'
import FormikTextFieldEditable from 'components/FormikTextFieldEditable'
import { DeepPartial } from 'types/DeepPartial'
import { Opportunity } from '@chunker/chunker-request'
import useNotify from 'hooks/useNotify'
import OpportunityBackNextButtons from '../OpportunityActionsButtons'
import WarehouseCustomerLocationsTable from './WarehouseCustomerLocationsTable'
import WarehouseChunkerMarketPlacesTable from './WarehouseChunkerMarketPlacesTable'

function WarehouseSolutions({ onClose }: { onClose: () => void }) {
  const { notifyError } = useNotify()
  const { values } = useFormikContext<DeepPartial<Opportunity> & { currentStep: number }>()

  const [loading, setLoading] = useState(true)
  const [customerLocations, setCustomerLocations] = useState<
    OpportunitySolution['customerLocations']
  >([])

  const [chunkerMarketPlaces, setChunkerMarketPlaces] = useState<
    OpportunitySolution['chunkerMarketPlaces']
  >([])
  const opportunityId = values.id
  const { warehouseLocation } = values

  useEffect(() => {
    if (!opportunityId) return
    if (!warehouseLocation) {
      notifyError(
        'This form currently supports warehouse opportunities only. Please provide a location for the warehouse location the customer is asking for.',
      )
      return
    }

    setLoading(true)

    opportunitiesResource.requests
      .getOpportunitiesByIdSolution(opportunityId)
      .then(({ data }) => {
        setCustomerLocations(data.customerLocations)
        setChunkerMarketPlaces(data.chunkerMarketPlaces)
      })
      .catch((err) => {
        console.error(err)
        notifyError('An error occurred while searching for a warehouse solution.')
      })
      .finally(() => setLoading(false))
  }, [notifyError, opportunityId, warehouseLocation])

  return (
    <>
      <DialogTitle>
        <FormHeaderStep
          step="3"
          title="Warehouse Solutions"
          description="Optimized warehouse solutions aligned with location requirements"
          Icon={Warehouse}
        />
        <Divider sx={{ my: 3 }} />
        <FormikTextFieldEditable id="name" name="name" label="Opportunity Name" required />
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (t) => t.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading ? <LinearProgress /> : null}
        {!loading && customerLocations?.length === 0 && chunkerMarketPlaces?.length === 0 && (
          <Alert severity="warning" variant="filled" sx={{ mb: 4 }}>
            No warehouse solutions found. Please add a preferred WAREHOUSE location on the
            opportunity form to search for warehouse solutions.
          </Alert>
        )}
        {chunkerMarketPlaces && chunkerMarketPlaces?.length > 0 && (
          <>
            <FormHeaderDivider sx={{ my: 3 }}>
              Preferred Locations - Chunker Marketplace
            </FormHeaderDivider>
            {loading ? (
              <LinearProgress />
            ) : (
              <WarehouseChunkerMarketPlacesTable locations={chunkerMarketPlaces} />
            )}
          </>
        )}
        {customerLocations && customerLocations.length > 0 && (
          <>
            <FormHeaderDivider sx={{ my: 3 }}>
              Other Locations - HubSpot customers
            </FormHeaderDivider>
            {loading ? (
              <LinearProgress />
            ) : (
              <WarehouseCustomerLocationsTable locations={customerLocations} />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <OpportunityBackNextButtons action="back" />
        <OpportunityBackNextButtons action="submit" onClose={onClose} />
      </DialogActions>
    </>
  )
}

export default WarehouseSolutions
