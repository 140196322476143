import { Grid } from '@mui/material'
import FormikCheckBox from 'components/FormikCheckbox'
import FormikTextField from 'components/FormikTextField'
import OutlinedContainer from 'components/OutlinedContainer'
import { IFormikCustomerContactClusterProps } from './types'

export default function FormikCustomerContactCluster({
  readOnly = false,
  fieldCustomerContactFirstName = 'customerContactFirstName',
  fieldCustomerContactLastName = 'customerContactLastName',
  fieldCustomerContactEmail = 'customerContactEmail',
  fieldCustomerContactPhone = 'customerContactPhone',
  fieldCustomerContactTitle = 'customerContactTitle',
  fieldCustomerContactPrimaryContact = 'customerContactPrimaryContact',
  OutlinedContainerProps,
}: IFormikCustomerContactClusterProps) {
  return (
    <OutlinedContainer {...OutlinedContainerProps}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormikTextField
            label="First Name"
            name={fieldCustomerContactFirstName}
            disabled={readOnly}
            data-testid={`formik-customer-contact-cluster-${fieldCustomerContactFirstName}`}
            size="small"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikTextField
            label="Last Name"
            name={fieldCustomerContactLastName}
            disabled={readOnly}
            data-testid={`formik-customer-contact-cluster-${fieldCustomerContactLastName}`}
            size="small"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikTextField
            label="Email"
            name={fieldCustomerContactEmail}
            disabled={readOnly}
            data-testid={`formik-customer-contact-cluster-${fieldCustomerContactEmail}`}
            size="small"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikTextField
            label="Phone"
            name={fieldCustomerContactPhone}
            data-testid={`formik-customer-contact-cluster-${fieldCustomerContactPhone}`}
            disabled={readOnly}
            size="small"
            phoneNumber
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikTextField
            label="Title"
            name={fieldCustomerContactTitle}
            disabled={readOnly}
            data-testid={`formik-customer-contact-cluster-${fieldCustomerContactTitle}`}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikCheckBox
            label="Primary Contact"
            name={fieldCustomerContactPrimaryContact}
            CheckboxProps={{
              'data-testid': `formik-customer-contact-cluster-${fieldCustomerContactPrimaryContact}`,
            }}
          />
        </Grid>
      </Grid>
    </OutlinedContainer>
  )
}
