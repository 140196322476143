import { useState } from 'react'
import { Theme } from '@mui/material/styles'
import AccordionSummary from '@mui/material/AccordionSummary'
import { Add, Remove } from '@mui/icons-material'
import { Accordion, AccordionDetails } from '@mui/material'
import getAppendableTestId from 'lib/utils/testUtils/getAppendableTestId'
import { IFormAccordionOptionProps } from './types'

function FormAccordionOption({ title, children, appendableTestIds }: IFormAccordionOptionProps) {
  const [expanded, setExpanded] = useState(false)

  const handleChange = () => {
    setExpanded(!expanded)
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      elevation={0}
      square
      sx={{
        border: (t: Theme) => `1px solid ${t.palette.divider}`,
        '&:before': {
          display: 'none',
        },
        marginY: (t) => t.spacing(3),
      }}
      data-testid={getAppendableTestId({
        appendableTestIds,
        testId: 'form-accordion-option',
        key: 'accordionOption',
      })}
    >
      <AccordionSummary
        expandIcon={
          expanded ? (
            <Remove sx={{ color: (t) => t.palette.common.black }} />
          ) : (
            <Add sx={{ color: (t) => t.palette.common.black }} />
          )
        }
        sx={{
          backgroundColor: (t) => t.palette.grey[200],
          fontWeight: '500',
          maxHeight: '48px',
          '&.Mui-expanded': {
            minHeight: '48px',
          },
        }}
        data-testid={getAppendableTestId({
          appendableTestIds,
          testId: 'form-accordion-option-title',
          key: 'accordionOptionTitle',
        })}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails
        sx={{ padding: (t) => t.spacing(2), borderTop: '1px solid rgba(0, 0, 0, .125)' }}
        data-testid={getAppendableTestId({
          appendableTestIds,
          testId: 'form-accordion-option-content',
          key: 'accordionOptionContent',
        })}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default FormAccordionOption
