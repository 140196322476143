import { Typography, TypographyProps, useTheme } from '@mui/material'

export default function HeaderSubPage({ sx, style, ...props }: TypographyProps) {
  const theme = useTheme()
  return (
    <Typography
      color="secondary.dark"
      variant="h2"
      sx={{
        fontSize: theme.typography.h4,
        mb: 3,
        ...sx,
      }}
      {...props}
    />
  )
}
