import { useState } from 'react'
import { isValid } from 'date-fns'
import { Box, Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { DatePicker, DateValidationError } from '@mui/x-date-pickers'
import getTimeFrames from '../../lib/utils/dateUtils/getTimeFrames'
import { DateTimeFramesSelectOptionsValue, IDateTimeFramesProps } from './types'
import getTimeFrameFromDateRange from './utils/getTimeFrameFromDateRange'

export const dateTimeFramesOptions: readonly {
  label: string
  value: DateTimeFramesSelectOptionsValue
}[] = [
  { label: 'Custom', value: 'custom' },
  { label: 'Next 3 Months', value: 'next3Months' },
  { label: 'Next 6 Months', value: 'next6Months' },
  { label: 'Next 9 Months', value: 'next9Months' },
  { label: 'Next Year', value: 'nextYear' },
]
/**
 * @description DateTimeFrames
 * - Provides a date time picker cluster.
 * Sends the dateTo and dateFrom for each onChange.
 */
export default function DateTimeFrames({
  dateTo,
  dateFrom,
  onChange,
  selectedTimeFrameValue,
  handleUpdateSelectedTimeFrameValue,
}: IDateTimeFramesProps) {
  const [errorDateFrom, setErrorDateFrom] = useState<DateValidationError | null>(null)
  const [errorDateTo, setErrorDateTo] = useState<DateValidationError | null>(null)

  const getErrorMessage = (error: DateValidationError) => {
    if (!error) return null
    switch (error) {
      case 'minDate':
        return 'End date must be after start date'
      case 'maxDate':
        return 'Start date must be before end date'
      case 'disablePast':
        return 'Date cannot be in the past'
      default:
        return 'Invalid date, format must be MM/DD/YYYY'
    }
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: ['column', 'row'] }} data-testid="date-time-frames">
      <Box sx={{ flex: 1, pr: [0, 3], pt: [3, 0] }}>
        <DatePicker
          label="Start"
          value={dateFrom}
          maxDate={dateTo}
          disablePast
          data-testid="date-time-frames-start"
          onChange={(value) => {
            if (isValid(value)) {
              onChange({ dateFrom: value, dateTo })
              handleUpdateSelectedTimeFrameValue(
                getTimeFrameFromDateRange({ dateTo, dateFrom: value }),
              )
            }
          }}
          onError={(error) => setErrorDateFrom(error)}
          slotProps={{
            textField: {
              fullWidth: true,
              size: 'small',
              inputProps: { 'data-testid': 'date-time-frames-start-input' },
              helperText: getErrorMessage(errorDateFrom),
            },
          }}
        />
      </Box>
      <Box sx={{ flex: 1, pr: [0, 3], pt: [3, 0] }}>
        <FormControl fullWidth>
          <InputLabel shrink id="date-time-frames-select-label">
            Time Frame
          </InputLabel>
          <Select<DateTimeFramesSelectOptionsValue>
            labelId="date-time-frames-select-label"
            label="Time Frame"
            fullWidth
            notched
            data-testid="date-time-frames-time-frame"
            onChange={(e) => {
              const { value } = e.target as { value: DateTimeFramesSelectOptionsValue }
              if (value === 'custom')
                handleUpdateSelectedTimeFrameValue(value as DateTimeFramesSelectOptionsValue)
              else {
                onChange(getTimeFrames(dateFrom ?? new Date())[value])
                handleUpdateSelectedTimeFrameValue(value)
              }
            }}
            size="small"
            value={selectedTimeFrameValue}
          >
            {dateTimeFramesOptions.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ flex: 1, pt: [3, 0] }}>
        <DatePicker
          label="End"
          value={dateTo}
          minDate={dateFrom}
          data-testid="date-time-frames-end"
          onChange={(value) => {
            if (isValid(value)) {
              onChange({ dateTo: value, dateFrom })
              handleUpdateSelectedTimeFrameValue(
                getTimeFrameFromDateRange({ dateTo: value, dateFrom }),
              )
            }
          }}
          onError={(error) => setErrorDateTo(error)}
          slotProps={{
            textField: {
              fullWidth: true,
              size: 'small',
              inputProps: { 'data-testid': 'date-time-frames-end-input' },
              helperText: getErrorMessage(errorDateTo),
            },
          }}
          disabled={selectedTimeFrameValue !== 'custom'}
        />
      </Box>
    </Box>
  )
}
