/* eslint-disable @typescript-eslint/no-explicit-any */
import { geocodeByPlaceId } from 'react-google-places-autocomplete'
import { FormValues } from 'types/FormValidation'
import { INotifyContext } from 'context/NotifyContextProvider/types'
import { AddressMapping } from '../../types'
import mergeObjects from '../mergeObjects'

function setAddressValues(
  newValue: any,
  values: FormValues,
  setValues: (
    values: React.SetStateAction<FormValues>,
    shouldValidate?: boolean | undefined,
  ) => void,
  notifyError: INotifyContext['notifyError'],
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => void,
  fieldMapping?: AddressMapping | undefined,
) {
  const newFieldValues: Partial<FormValues> = {}

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
  geocodeByPlaceId(newValue?.value?.place_id)
    .then((results) => {
      // Extract address components from the geocoded results
      const addressComponents: google.maps.GeocoderAddressComponent[] =
        results[0]?.address_components

      const lat = results[0]?.geometry?.location?.lat()
      const lng = results[0]?.geometry?.location?.lng()

      const addressTypes: string[] = [
        'street_number',
        'route',
        'locality',
        'administrative_area_level_1',
        'country',
        'postal_code',
      ]

      // Extract address components based on the address types array, an empty string is returned if the address component is not found
      const extractedAddress = addressTypes.map((type) => {
        const addressComponent = addressComponents.find(({ types }) => types.includes(type))
        return {
          [type]: {
            long_name: addressComponent?.long_name ?? '',
            short_name: addressComponent?.short_name ?? '',
          },
        }
      })

      const defaultFieldMapping: AddressMapping = {
        street_number: 'streetAddress1',
        route: 'streetAddress1',
        locality: 'city',
        administrative_area_level_1: 'state',
        postal_code: 'postalCode',
        country: 'countryCode',
      }

      const getFieldMapping = fieldMapping ?? defaultFieldMapping

      // Set formik field values based on the field mapping object
      extractedAddress.forEach((address) => {
        const key = Object.keys(address)[0]
        const value = address[key]
        const fieldName = getFieldMapping[key]

        const streetNumber = extractedAddress[0]?.street_number?.long_name
        const route = extractedAddress[1]?.route?.long_name

        if (fieldName) {
          if (key === 'street_number' || key === 'route') {
            newFieldValues[fieldName] = [streetNumber, route].filter(Boolean).join(' ')
          } else if (key === 'country') {
            newFieldValues[fieldName] = value?.short_name
          } else if (key) {
            newFieldValues[fieldName] = value?.long_name
          }
        }
      })

      // set the location field
      const locationField = getFieldMapping.location
      if (locationField) {
        newFieldValues[locationField] = `${lat},${lng}`
      }
    })
    .then(() => {
      const mergedObject = mergeObjects(values, newFieldValues)
      setValues(mergedObject, true)

      setFieldTouched('address', true, false)
      // set each of the fields touched
      Object.keys(newFieldValues).forEach((field) => {
        setFieldTouched(field, true, false)
      })
    })
    .catch((error) => {
      notifyError('There was an error retrieving the address. Please try again.')
      console.error(error)
    })
}

export default setAddressValues
