import { useLocation } from 'react-router-dom'

/**
 * @description
 * - Supplies a type safe useLocation state to check if redirect exists within the location state.
 */
function useLocationRedirectState() {
  const { state } = useLocation() as { state: { redirect?: string } }

  return {
    redirect: state?.redirect,
  }
}

export default useLocationRedirectState
