const URLS = {
  leaveCompanyPortal: {
    contactUs: 'https://chunker.com/contact-us/',
    forgotPassword: 'https://app.chunker.com/login/reset',
    terms: 'https://app.chunker.com/terms',
    privacyPolicy: 'https://app.chunker.com/terms/privacy_policy',
    // TODO: update these links
    logisticsPlanningPlatform: 'https://lpp.chunker.com/',
    yardManagementSystem: 'https://yms.chunker.com/',
    marketPlace: 'https://app.chunker.com/',
    faceBook: 'https://www.facebook.com/chunkerwarehouses',
    linkedIn: 'https://www.linkedin.com/company/chunker',
  },
  home: '/',
  profile: '/profile',
  login: '/login',
  signup: '/sign-up',
  signupVerify: '/sign-up-verify',
  forgotPassword: '/forgot-password',
  forgotPasswordInit: '/forgot-password-init',
  forgotPasswordFinish: '/forgot-password-finish',
  productsAndBilling: '/products-and-billing',
  companyDetails: '/company-details',
  companies: '/companies',
  licenseManagement: '/license-management',
  companyUsers: '/company-users',
  customers: '/customers',
  opportunities: '/opportunities',
} as const

export default URLS
