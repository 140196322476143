import { useUserCTX } from '@chunker/user-ctx'
import { Avatar, Box, IconButton, ListItemButton, ListItemText, Menu } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import isUserStatusLoggedInOrReinitializing from 'lib/utils/userUtils/isUserStatusLoggedInOrReinitializing'

import URLS from 'lib/constants/urls'
// import { navbarLinks } from '../constants'
import { IUserMenu } from './types'

export default function UserMenu({ BoxProps }: IUserMenu) {
  const { clearUserSession, status, user: userInfos } = useUserCTX()
  const [userMenuEl, setUserMenuEl] = useState<null | HTMLElement>(null)
  const navigate = useNavigate()

  const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setUserMenuEl(event.currentTarget)

  const getUserInitials = () => {
    if (!userInfos?.user?.firstName || !userInfos?.user?.lastName) return null
    return `${userInfos?.user?.firstName?.charAt(0).toUpperCase()}${userInfos?.user?.lastName
      ?.charAt(0)
      .toUpperCase()}`
  }

  if (!isUserStatusLoggedInOrReinitializing(status)) return null

  return (
    <>
      <Box data-testid="user-menu" {...BoxProps}>
        <IconButton
          data-testid="user-menu-button"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleUserMenuClick}
          color="inherit"
          sx={{ display: 'flex' }}
        >
          <Avatar
            sizes="small"
            sx={{
              height: 35,
              width: 35,
              fontSize: 16,
              paddingTop: 0.8,
              alignSelf: 'center',
            }}
          >
            {getUserInitials()}
          </Avatar>
        </IconButton>
      </Box>
      <Menu
        id="navbar-user-menu-dropdown"
        data-testid="navbar-user-menu-dropdown"
        anchorEl={userMenuEl}
        open={Boolean(userMenuEl)}
        onClose={() => setUserMenuEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        MenuListProps={{
          'aria-labelledby': 'navbar-user-menu-dropdown',
        }}
      >
        {/* <ListItemButton
          onClick={() => {
            navigate(navbarLinks.PROFILE.url)
            setUserMenuEl(null)
          }}
          data-testid="user-menu-profile"
        >
          <ListItemText
            primaryTypographyProps={{
              sx: { fontWeight: 'bold', color: (t) => t.palette.grey[600] },
            }}
            primary="Profile"
          />
        </ListItemButton> */}
        <ListItemButton
          data-testid="user-menu-logout"
          onClick={() => {
            clearUserSession()
              .then(() => {
                navigate(URLS.login)
                setUserMenuEl(null)
              })
              .catch((err) => {
                console.error(err)
              })
          }}
        >
          <ListItemText
            primaryTypographyProps={{
              sx: { fontWeight: 'bold', color: (t) => t.palette.grey[600] },
            }}
            primary="Logout"
          />
        </ListItemButton>
      </Menu>
    </>
  )
}
