import { Box } from '@mui/material'
import { IClickableIcon } from './types'

export default function ClickableIcon({ src, onClick, sx, ...props }: IClickableIcon) {
  return (
    <Box
      data-testid={props['data-testid']}
      component="img"
      src={src}
      sx={{ height: 36, width: 36, cursor: 'pointer', ...sx }}
      onClick={onClick}
    />
  )
}
