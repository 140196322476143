import { useMemo, useState } from 'react'
import { FormikValues, useFormikContext } from 'formik'
import { format, formatISO, parseISO } from 'date-fns'
import {
  Button,
  ButtonGroup,
  Grid,
  InputAdornment,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import AddressAutoFill from 'components/AddressAutoFill'
import CheckboxRequestCluster from 'components/CheckboxRequestCluster'
import DateTimeFrames from 'components/DateTimeFrames'
import { DateTimeFramesSelectOptionsValue } from 'components/DateTimeFrames/types'
import getTimeFrameFromDateRange from 'components/DateTimeFrames/utils/getTimeFrameFromDateRange'
import FormAccordionOption from 'components/FormAccordionOption'
import FormAccordionType from 'components/FormAccordionType'
import FormHeaderDivider from 'components/FormHeaderDivider'
import FormikCheckBox from 'components/FormikCheckbox'
import FormikSliderTravelDistance from 'components/FormikSliderTravelDistance'
import FormikTextField from 'components/FormikTextField'
import FormikTextFieldWithSwitch from 'components/FormikTextFieldWithSwitch'
import HeaderSmall from 'components/HeaderSmall'
import TimeFrames from 'components/TimeFrames'
import { DATE_TIME_FORMATS } from 'lib/constants/time'
import { IWarehouseFormProps } from './types'
import { OPTION_HEADERS_ID } from '../constants/constants'

function WarehouseForm({
  optionsByHeader,
  opportunitySpaceUnits,
  goodsInOutFrequencyUnits,
}: IWarehouseFormProps) {
  const { setFieldValue, values } = useFormikContext<FormikValues>()
  const theme = useTheme<Theme>()

  const warehouseDateStart =
    typeof values.warehouseDateStart === 'string' ? parseISO(values.warehouseDateStart) : null

  const warehouseDateEnd =
    typeof values.warehouseDateEnd === 'string' ? parseISO(values.warehouseDateEnd) : null

  const warehouseOperationTimeOpen =
    typeof values.warehouseOperationTimeOpen === 'string'
      ? parseISO(values.warehouseOperationTimeOpen)
      : null

  const warehouseOperationTimeClose =
    typeof values.warehouseOperationTimeClose === 'string'
      ? parseISO(values.warehouseOperationTimeClose)
      : null

  const [selectedTimeFrameValue, setSelectedTimeFrameValue] =
    useState<DateTimeFramesSelectOptionsValue>(
      getTimeFrameFromDateRange({
        dateTo: warehouseDateEnd,
        dateFrom: warehouseDateStart,
      }),
    )

  const spaceUnits = useMemo(() => ['ft²', 'pallets'], [])

  return useMemo(() => {
    return (
      <FormAccordionType type="warehouse" AccordionBaseProps={{ sx: { mt: 3 } }}>
        <FormHeaderDivider variant="secondary" sx={{ my: 3 }}>
          LOCATION
        </FormHeaderDivider>
        <Typography variant="body2" sx={{ mt: 3 }}>
          Provide the location space is needed. At least one location grouping is required.
        </Typography>
        <AddressAutoFill
          fields={[
            { key: 'warehouseStreetAddress', label: 'Street', columns: { xs: 12 } },
            { key: 'warehouseCity', label: 'City' },
            { key: 'warehouseState', label: 'State' },
            { key: 'warehouseCountryCode', label: 'Country' },
            { key: 'warehousePostalCode', label: 'Zip Code' },
          ]}
          fieldMapping={{
            street_number: 'warehouseStreetAddress',
            route: 'warehouseStreetAddress',
            locality: 'warehouseCity',
            administrative_area_level_1: 'warehouseState',
            postal_code: 'warehousePostalCode',
            country: 'warehouseCountryCode',
            location: 'warehouseLocation',
          }}
          BoxProps={{ sx: { mt: 3 } }}
          textFieldProps={{ size: 'small' }}
        />
        <HeaderSmall>Travel Distance</HeaderSmall>
        <Typography variant="body2" sx={{ mb: 3 }}>
          Provide how far the tenant is willing to travel to store their goods.
        </Typography>
        <FormikSliderTravelDistance name="warehouseTravelDistance" />
        <FormHeaderDivider variant="secondary" sx={{ my: 3 }}>
          SPACE NEEDED
        </FormHeaderDivider>
        <Grid item xs={12} md={6}>
          <HeaderSmall>Space Needed</HeaderSmall>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormikTextFieldWithSwitch
                name="warehouseSpaceNeededMin"
                label="Minimum Space Needed"
                switcherName="warehouseOpportunitySpaceUnit.id"
                switcherOptions={opportunitySpaceUnits.filter((unit) =>
                  spaceUnits.includes(unit.label),
                )}
                size="small"
                integer
                noSwitcher
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormikTextFieldWithSwitch
                name="warehouseSpaceNeededMax"
                label="Maximum Space Needed"
                switcherName="warehouseOpportunitySpaceUnit.id"
                switcherOptions={opportunitySpaceUnits.filter((unit) =>
                  spaceUnits.includes(unit.label),
                )}
                size="small"
                integer
              />
            </Grid>
          </Grid>
        </Grid>
        <HeaderSmall>Goods In / Out Frequency</HeaderSmall>
        <Typography variant="body2" sx={{ mb: 3 }}>
          How often will the customer need to store and retrieve goods (e.g., daily, weekly,
          monthly)?
        </Typography>
        <FormikTextFieldWithSwitch
          name="warehouseGoodsInOutFrequency"
          label="Goods In / Out Frequency"
          switcherName="warehouseGoodsInOutFrequencyUnit.id"
          switcherOptions={goodsInOutFrequencyUnits}
          size="small"
          integer
        />
        <FormHeaderDivider variant="secondary" sx={{ my: 3 }}>
          PROJECT LENGTH
        </FormHeaderDivider>
        <FormikCheckBox name="warehouseMonthToMonth" label="Month to Month" />
        <DateTimeFrames
          dateFrom={warehouseDateStart}
          dateTo={warehouseDateEnd}
          selectedTimeFrameValue={selectedTimeFrameValue}
          onChange={({ dateTo, dateFrom }) => {
            setSelectedTimeFrameValue(getTimeFrameFromDateRange({ dateTo, dateFrom }))
            setFieldValue(
              'warehouseDateStart',
              dateFrom ? format(new Date(dateFrom), DATE_TIME_FORMATS.DATABASE_NO_TIME) : null,
            ).catch(console.error)
            setFieldValue(
              'warehouseDateEnd',
              dateTo ? format(new Date(dateTo), DATE_TIME_FORMATS.DATABASE_NO_TIME) : null,
            ).catch(console.error)
          }}
          handleUpdateSelectedTimeFrameValue={setSelectedTimeFrameValue}
        />
        <HeaderSmall>Total Estimated Storage Budget</HeaderSmall>
        <FormikTextField
          name="warehouseEstimatedBudgetTotal"
          label="Total Estimated Storage Budget"
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          fullWidth
          integer
        />
        <HeaderSmall>
          Estimated{' '}
          <span
            style={{
              fontWeight: 900,
              color: theme.palette.primary.main,
            }}
          >
            STORAGE
          </span>{' '}
          Budget Per Unit
        </HeaderSmall>
        <FormikTextFieldWithSwitch
          name="warehouseEstimatedBudgetPerUnit"
          label="Estimated Storage Budget Per Unit"
          switcherName="warehouseOpportunitySpaceUnit.id"
          switcherOptions={opportunitySpaceUnits.filter((unit) => spaceUnits.includes(unit.label))}
          startAdornment="$"
          size="small"
          float
        />
        <HeaderSmall>
          Estimated{' '}
          <span style={{ fontWeight: 900, color: theme.palette.primary.main }}>SERVICES</span>{' '}
          Budget Per Unit
        </HeaderSmall>
        <FormikTextField
          name="warehouseEstimatedServicesBudgetPerUnit"
          label="Estimated Services Budget Per Unit"
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            endAdornment: <InputAdornment position="end">per unit</InputAdornment>,
          }}
          fullWidth
          float
        />
        <FormHeaderDivider variant="secondary" sx={{ my: 3 }}>
          SPECIAL REQUIREMENTS
        </FormHeaderDivider>
        <CheckboxRequestCluster
          clusterName="warehouseOptions"
          header="Building Essentials"
          subheader="Select building requirements that match the needs of the customer."
          data={optionsByHeader[OPTION_HEADERS_ID.BUILDING_ESSENTIALS]}
        />
        <FormAccordionOption title="Cold Storage">
          <FormikCheckBox name="warehouseColdStorageRequired" label="Cold Storage" />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <HeaderSmall>Cold Storage Size</HeaderSmall>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormikTextField
                    name="warehouseColdStorageMinSize"
                    label="Minimum Size"
                    fullWidth
                    InputProps={{
                      endAdornment: 'ft²',
                    }}
                    size="small"
                    integer
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikTextField
                    name="warehouseColdStorageMaxSize"
                    label="Maximum Size"
                    fullWidth
                    InputProps={{
                      endAdornment: 'ft²',
                    }}
                    size="small"
                    integer
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <HeaderSmall>Cold Storage Temperature</HeaderSmall>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormikTextField
                    name="warehouseColdStorageMinTemp"
                    label="Minimum Temperature"
                    fullWidth
                    InputProps={{
                      endAdornment: '°F',
                    }}
                    size="small"
                    integer
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikTextField
                    name="warehouseColdStorageMaxTemp"
                    label="Maximum Temperature"
                    fullWidth
                    InputProps={{
                      endAdornment: '°F',
                    }}
                    size="small"
                    integer
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormAccordionOption>
        <FormAccordionOption title="Business Hours">
          <TimeFrames
            startTime={warehouseOperationTimeOpen}
            endTime={warehouseOperationTimeClose}
            onChange={({ startTime, endTime }) => {
              setFieldValue(
                'warehouseOperationTimeOpen',
                formatISO(new Date(startTime as Date)),
              ).catch(console.error)
              setFieldValue(
                'warehouseOperationTimeClose',
                formatISO(new Date(endTime as Date)),
              ).catch(console.error)
            }}
          />
        </FormAccordionOption>
        <FormAccordionOption title="Dock Doors">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <HeaderSmall>Dock Doors Configuration</HeaderSmall>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormikTextField
                    name="warehouseDockDoorHighDoors"
                    label="Number of Dock High Doors Required"
                    size="small"
                    fullWidth
                    integer
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikTextField
                    name="warehouseDockDoorDriveInDoors"
                    label="Number of Drive-In Doors Required"
                    size="small"
                    fullWidth
                    integer
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <HeaderSmall>Dock Doors Dimensions</HeaderSmall>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormikTextField
                    name="warehouseDockDoorHeight"
                    label="Dock Door Height"
                    size="small"
                    InputProps={{
                      endAdornment: 'ft',
                    }}
                    fullWidth
                    float
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikTextField
                    name="warehouseDockDoorWidth"
                    label="Dock Door Width"
                    size="small"
                    InputProps={{
                      endAdornment: 'ft',
                    }}
                    fullWidth
                    float
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormAccordionOption>
        <CheckboxRequestCluster
          clusterName="warehouseOptions"
          header="Services Needed"
          subheader="Select all the services needed that match the needs of the customer."
          data={optionsByHeader[OPTION_HEADERS_ID.SERVICES_NEEDED]}
        />
        <CheckboxRequestCluster
          clusterName="warehouseOptions"
          header="Equipment Needed"
          data={optionsByHeader[OPTION_HEADERS_ID.EQUIPMENT_NEEDED]}
        />
        <FormAccordionOption title="Pallets">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <HeaderSmall>Pallets Height</HeaderSmall>
              <FormikTextField
                name="warehousePalletHeight"
                label="Pallets Height"
                size="small"
                InputProps={{
                  endAdornment: 'inches',
                }}
                fullWidth
                float
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HeaderSmall>Pallets Width</HeaderSmall>
              <FormikTextField
                name="warehousePalletWidth"
                label="Pallets Width"
                size="small"
                InputProps={{
                  endAdornment: 'inches',
                }}
                fullWidth
                float
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HeaderSmall>Pallets Length</HeaderSmall>
              <FormikTextField
                name="warehousePalletLength"
                label="Pallets Length"
                size="small"
                InputProps={{
                  endAdornment: 'inches',
                }}
                fullWidth
                float
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HeaderSmall>Pallets Weight</HeaderSmall>
              <FormikTextField
                name="warehousePalletWeight"
                label="Pallets Weight"
                size="small"
                InputProps={{
                  endAdornment: 'pounds',
                }}
                fullWidth
                float
              />
            </Grid>
          </Grid>
          <HeaderSmall>Can they be stacked?</HeaderSmall>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2">
                Specify if any of the tenants pallets can be stacked
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ButtonGroup variant="outlined" sx={{ height: '100%' }} disableElevation fullWidth>
                <Button
                  name="warehousePalletStackable"
                  onClick={() => {
                    setFieldValue('warehousePalletStackable', true).catch(console.error)
                  }}
                  variant={values.warehousePalletStackable ? 'contained' : 'outlined'}
                  fullWidth
                  style={{
                    textTransform: 'none',
                  }}
                >
                  Yes
                </Button>
                <Button
                  name="warehousePalletStackable"
                  onClick={() => {
                    setFieldValue('warehousePalletStackable', false).catch(console.error)
                  }}
                  variant={!values.warehousePalletStackable ? 'contained' : 'outlined'}
                  fullWidth
                  style={{
                    textTransform: 'none',
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </Grid>
            {values.warehousePalletStackable && (
              <>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2">How many pallets can they be stacked?</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    name="warehousePalletStackableLevels"
                    label="Stackable Levels"
                    size="small"
                    InputProps={{
                      endAdornment: 'levels',
                    }}
                    fullWidth
                    integer
                  />
                </Grid>
              </>
            )}
          </Grid>
        </FormAccordionOption>
        <CheckboxRequestCluster
          clusterName="warehouseOptions"
          header="Handled Materials"
          data={optionsByHeader[OPTION_HEADERS_ID.HANDLED_GOODS]}
        />
        <FormAccordionOption title="Chemical/Hazmat">
          <FormikCheckBox name="warehouseHazardousMaterial" label="Chemical/Hazmat" />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <HeaderSmall>What kind of hazardous materials? </HeaderSmall>
              <FormikTextField
                name="warehouseHazardousMaterialTypeOfMaterials"
                label="Hazmat Type"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <HeaderSmall>Are there any specific requirements or conditions?</HeaderSmall>
              <FormikTextField
                name="warehouseHazardousMaterialRequiredConditions"
                label="Special Conditions"
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
        </FormAccordionOption>
      </FormAccordionType>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    goodsInOutFrequencyUnits,
    opportunitySpaceUnits,
    optionsByHeader,
    selectedTimeFrameValue,
    setFieldValue,
    spaceUnits,
    values.warehousePalletStackable,
    warehouseDateEnd,
    warehouseDateStart,
    warehouseOperationTimeClose,
    warehouseOperationTimeOpen,
  ])
}

export default WarehouseForm
