/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { IMaskInput } from 'react-imask'
import { ICentsInputProps } from './types'

const CentsInput = React.forwardRef<HTMLElement, ICentsInputProps>(function TextMaskCustom(
  { onChange, ...rest },
  ref,
) {
  return (
    <IMaskInput
      {...rest}
      mask="num"
      blocks={{
        num: {
          mask: '\\0.00',
          radix: '.',
          lazy: true,
          max: 0.99,
          min: 0.01,
        },
      }}
      unmask
      inputRef={ref as any}
      onAccept={(value) => onChange({ target: { name: rest.name, value } })}
      data-testid="cents-input"
    />
  )
})

export default CentsInput
