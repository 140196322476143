import { FormikValues, getIn, useFormikContext } from 'formik'
import { Grid, InputAdornment } from '@mui/material'
import FormikTextField from 'components/FormikTextField'
import FormikSwitch from 'components/FormikSwitch'
import { IFormikTextFieldWithSwitchProps } from './types'

function FormikTextFieldWithSwitch({
  switcherName,
  switcherOptions,
  startAdornment,
  textTransform,
  noSwitcher,
  ...props
}: IFormikTextFieldWithSwitchProps) {
  const { values } = useFormikContext<FormikValues>()

  const getInValue = getIn(values, switcherName) as number
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={noSwitcher ? 12 : 6}>
        <FormikTextField
          type="text"
          {...props}
          InputProps={{
            startAdornment: <InputAdornment position="start">{startAdornment}</InputAdornment>,
            endAdornment: (
              <InputAdornment
                position="end"
                style={{
                  textTransform: textTransform || 'none',
                }}
              >
                {switcherOptions?.map((switcherOption) => {
                  if (getInValue === switcherOption.id) {
                    return <span key={switcherOption.label}>{switcherOption.label}</span>
                  }
                  return null
                })}
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Grid>
      {!noSwitcher && (
        <Grid item xs={12} sm={6}>
          <FormikSwitch
            switcherName={switcherName}
            switcherOptions={switcherOptions}
            textTransform={textTransform}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default FormikTextFieldWithSwitch
