import { StandardChunkerAPIType, goodsInOutFrequencyUnitResource } from '@chunker/chunker-request'
import useNotify from 'hooks/useNotify'
import { useCallback, useEffect, useState } from 'react'

function useGoodsInOutFrequencyUnits() {
  const { notifyError } = useNotify()
  const [goodsInOutFrequencyUnits, setGoodsInOutFrequencyUnits] = useState<
    StandardChunkerAPIType[]
  >([])
  const [loading, setLoading] = useState(false)

  const fetchGoodsInOutFrequencyUnits = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await goodsInOutFrequencyUnitResource.requests.getGoodsInOutFrequencyUnits()
      setGoodsInOutFrequencyUnits(data)
    } catch (error) {
      console.error(error)
      notifyError()
    } finally {
      setLoading(false)
    }
  }, [notifyError])

  useEffect(() => {
    fetchGoodsInOutFrequencyUnits().catch(console.error)
  }, [fetchGoodsInOutFrequencyUnits])

  return { goodsInOutFrequencyUnits, fetch: fetchGoodsInOutFrequencyUnits, loading }
}

export default useGoodsInOutFrequencyUnits
