import {
  BatchPrediction,
  Home,
  // Engineering,
  // ReduceCapacity,
  // MonetizationOn,
  // ListAlt,
  Phone,
  // Person,
  // Contacts,
} from '@mui/icons-material'
import URLS from 'lib/constants/urls'

// eslint-disable-next-line import/prefer-default-export
export const navbarLinks = {
  HOME: {
    label: 'Home',
    url: URLS.home,
    icon: <Home />,
    userRights: ['*'],
    'data-testid': 'home-link',
  },
  // PROFILE: {
  //   label: 'Profile',
  //   url: URLS.profile,
  //   icon: <Person />,
  //   userRights: ['*'],
  //   'data-testid': 'profile-link',
  // },
  // CUSTOMERS: {
  //   label: 'Customers',
  //   url: URLS.customers,
  //   icon: <Contacts />,
  //   userRights: ['sales'],
  //   'data-testid': 'customers-and-opportunities-link',
  // },
  OPPORTUNITIES: {
    label: 'Opportunities',
    url: URLS.opportunities,
    icon: <BatchPrediction />,
    userRights: ['sales', 'support'],
    'data-testid': 'customers-and-opportunities-link',
  },
  // PRODUCT_AND_BILLING: {
  //   label: 'Products & Billing',
  //   url: URLS.productsAndBilling,
  //   icon: <MonetizationOn />,
  //   userRights: ['*'],
  //   'data-testid': 'products-and-billing-link',
  // },
  // COMPANY_DETAIL: {
  //   label: 'Company Details',
  //   url: URLS.companyDetails,
  //   icon: <ListAlt />,
  //   userRights: ['*'],
  //   'data-testid': 'company-details-link',
  // },
  // COMPANY_USERS: {
  //   label: 'Company Users',
  //   url: URLS.companyUsers,
  //   icon: <Engineering />,
  //   userRights: ['*'],
  //   'data-testid': 'company-users-link',
  // },
  // LICENSE_MANAGEMENT: {
  //   label: 'License Management',
  //   url: URLS.licenseManagement,
  //   icon: <ReduceCapacity />,
  //   userRights: ['*'],
  //   'data-testid': 'license-management-link',
  // },
  CONTACT_US: {
    label: 'Contact Us',
    url: URLS.leaveCompanyPortal.contactUs,
    icon: <Phone />,
    userRights: ['*'],
    'data-testid': 'contact-us-link',
  },
} as const
