import { Box } from '@mui/material'
import logo from 'assets/logo-chunker-orange-long.png'
import { IChunkerLogo } from './types'

export default function ChunkerLogo({ sx, ...props }: IChunkerLogo) {
  return (
    <Box
      component="img"
      src={logo}
      data-testid="chunker-logo"
      sx={{
        height: 35,
        ...sx,
        cursor: typeof props.onClick === 'function' ? 'pointer' : 'default',
      }}
      {...props}
    />
  )
}
