import { isSameDay } from 'date-fns'
import { TimeFrame } from '../../../../types/TimeFrame'
import getTimeFrameObject from '../getTimeFrames'

/**
 * @function getTimeFrameKey
 * - Provides a way to reverse engineer the Time Frame object or find if two dates are within a range specified.
 * - Similar to: (a + 3 = 5) where a === 2
 */
export default function getTimeFrameKey({
  dateFrom,
  dateTo,
}: {
  /**
   * @property
   * The given date from that is to be assessed.
   */
  dateFrom: Date
  /**
   * @property
   * The given date to that is to be assessed.
   */
  dateTo: Date
}): TimeFrame | null {
  const timeFrame = getTimeFrameObject(dateFrom)
  /** Verify that this is not within the time frame of one of our present value */
  for (const [key, currentTimeFrame] of Object.entries(timeFrame)) {
    if (
      isSameDay(dateFrom, currentTimeFrame.dateFrom) &&
      isSameDay(dateTo, currentTimeFrame.dateTo)
    )
      return key as TimeFrame
  }
  return null
}
