import { Box, Typography } from '@mui/material'
import { IFormHeaderDividerProps } from './types'

function FormHeaderDivider({ variant, ...props }: IFormHeaderDividerProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: (t) => t.palette[variant || 'primary'].main,
      }}
    >
      <Typography variant="h6" sx={{ letterSpacing: '0.2rem' }} {...props} />
      <Box
        sx={{
          flexGrow: 1,
          borderBottom: (t) => `2px solid ${t.palette[variant || 'primary'].main}`,
          ml: 2,
        }}
      />
    </Box>
  )
}

export default FormHeaderDivider
