import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import getAppendableTestId from 'lib/utils/testUtils/getAppendableTestId'
import { IApplicationDialogProps } from './types'

function ApplicationDialog({
  open = true,
  onClose,
  title,
  contentText,
  withCloseButton,
  children,
  actions,
  appendableTestIds,
  DialogBaseProps,
  DialogTitleProps,
  DialogContentProps,
  DialogContentTextProps,
  DialogActionsProps,
}: IApplicationDialogProps) {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      data-testid={getAppendableTestId({
        appendableTestIds,
        testId: 'application-dialog-dialog',
        key: 'dialog',
      })}
      {...DialogBaseProps}
    >
      <DialogTitle
        data-testid={getAppendableTestId({
          appendableTestIds,
          testId: 'application-dialog-title',
          key: 'dialogTitle',
        })}
        {...DialogTitleProps}
      >
        <Box component="span" sx={{ mr: 8 }}>
          {title}
        </Box>
        {onClose && withCloseButton ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (t) => t.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {contentText && (
        <DialogContent
          data-testid={getAppendableTestId({
            appendableTestIds,
            testId: 'application-dialog-content',
            key: 'dialogContent',
          })}
          {...DialogContentProps}
        >
          <DialogContentText {...DialogContentTextProps}>{contentText}</DialogContentText>
        </DialogContent>
      )}
      {children}
      {actions && (
        <DialogActions
          data-testid={getAppendableTestId({
            appendableTestIds,
            testId: 'application-dialog-actions',
            key: 'dialogActions',
          })}
          {...DialogActionsProps}
        >
          {actions}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default ApplicationDialog
