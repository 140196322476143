import getTimeFrameKey from 'lib/utils/dateUtils/getTimeFrameKey'
import { DateTimeFramesSelectOptionsValue } from '../../types'

export default function getTimeFrameFromDateRange({
  dateTo,
  dateFrom,
}: {
  dateTo: Date | null
  dateFrom: Date | null
}): DateTimeFramesSelectOptionsValue {
  if (dateTo === null || dateFrom === null) return 'custom'
  const timeFrame = getTimeFrameKey({ dateTo, dateFrom })
  if (timeFrame !== null) return timeFrame
  return 'custom'
}
