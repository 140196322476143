export const DATE_TIME_FORMATS = {
  /** The format we use to send dates to the database without time */
  DATABASE_NO_TIME: 'yyyy-MM-dd',
  /** A standard format for date with slashes without time */
  SLASH_NO_TIME: 'MM/dd/yy',
  /** A standard format for date with slashes with time */
  SLASH_DATE_TIME: 'MM/dd/yyyy HH:mm',
  /** A standard date which displays partial month wording without time. */
  SHORT_WORD_NO_TIME: 'dd-MMM-yyyy',
  /** A standard date which displays full date with partial month wording and [1-12]time. */
  LONG_DATE_12_HOURS: 'MMM do, yyyy hh:mm aaa',
  /** A standard date which display a full date with a timezone stamp at the end */
  LONG_DATE_12_HOURS_WITH_TIMEZONE: 'MMM do, yyyy hh:mm aaa z',
  /** A standard date which displays full date and time. */
  LONG_WORD_DATE_TIME: 'PPPpp',
  /** A standard display of time only that is User friendly */
  USER_FRIENDLY_DATE_TIME: 'MM/dd/yy - h:mm aaa',
} as const

/** The default table time frame "what should the tables load as" */
export const DEFAULT_TABLE_TIME_FRAME = 'custom'
