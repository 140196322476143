import { zendeskResource } from '@chunker/chunker-request'
import extractData from '../requestUtils/extractData'

function setupZenDeskChatAuthentication() {
  // Use type assertion to define zESettings on the window object
  window.zESettings = {
    webWidget: {
      authenticate: {
        chat: {
          /**
           * @function jwtFn is required to be called for within both sections.
           *
           *  - authenticate.chat uses the jwtFn to make chat requests on mount.
           *
           *  - authenticate uses jwtFn for re initializing when a user has logged in after the first session has failed.
           *
           * @param {function} callback
           */
          jwtFn: (callback: (jwt: string) => void) => {
            zendeskResource.requests
              .getZenDeskChatJWT()
              .then(extractData)
              .then((jwt) => callback(jwt))
              .catch((err) => console.error(err))
          },
        },
      },
    },
  }
}

export default setupZenDeskChatAuthentication
