import { Typography, TypographyProps } from '@mui/material'

export default function HeaderSmall({ sx, ...props }: TypographyProps) {
  return (
    <Typography
      color="secondary.dark"
      sx={{ mb: 3, mt: 3, fontWeight: 600, fontSize: (t) => t.typography.fontSize, ...sx }}
      {...props}
    />
  )
}
