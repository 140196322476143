import { Menu } from '@mui/icons-material'
import { Box, ButtonBase } from '@mui/material'
import { useState } from 'react'
import NavDrawer from '../NavDrawer'
import { IHamburgerMenu } from './types'

export default function HamburgerMenu({ BoxProps }: IHamburgerMenu) {
  const [navDrawerOpen, setNavDrawerOpen] = useState<boolean>(false)

  return (
    <Box data-testid="hamburger-menu" {...BoxProps}>
      <ButtonBase
        sx={{
          borderWidth: 1,
          borderStyle: 'solid',
          height: 35,
          width: 35,
          borderRadius: 2,
          color: 'white',
        }}
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={() => setNavDrawerOpen(true)}
        data-testid="navbar-user-hamburger"
      >
        <Menu />
      </ButtonBase>
      <NavDrawer open={navDrawerOpen} onClose={() => setNavDrawerOpen(false)} />
    </Box>
  )
}
