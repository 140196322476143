import { Box, Grid, useTheme } from '@mui/material'
import { ISplitPageContainerProps } from './types'
import concreteTexture from '../../assets/images/texture-concrete.jpeg'

function SplitPageContainer({
  leftContent,
  rightContent,
  sxLeft,
  sxRight,
}: ISplitPageContainerProps) {
  const theme = useTheme()
  return (
    <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            backgroundImage: `linear-gradient(rgb(255,255,255,0), rgb(255,255,255,0.8)), url(${concreteTexture})`,
            backgroundBlendMode: 'multiply',
            backgroundSize: 'cover',
            order: { xs: 2, sm: 2, md: 1 },
            ...sxLeft,
          }}
        >
          {leftContent}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            backgroundImage: `linear-gradient(${theme.palette.primary.light} 60%, ${theme.palette.primary.dark} 90%), url(${concreteTexture})`,
            backgroundBlendMode: 'multiply',
            backgroundSize: 'cover',
            order: { xs: 1, sm: 1, md: 2 },
            ...sxRight,
          }}
        >
          {rightContent}
        </Grid>
      </Grid>
    </Box>
  )
}

export default SplitPageContainer
