import { Slider } from '@mui/material'
import { FormikValues, getIn, useFormikContext } from 'formik'
import { IFormikSliderTravelDistanceProps } from './types'

const travelDistanceMarks = [
  {
    label: '5 mi',
    value: 5,
  },
  {
    label: '50 mi',
    value: 50,
  },
  {
    label: '100 mi',
    value: 100,
  },
  {
    label: '150 mi',
    value: 150,
  },
  {
    label: '200 mi',
    value: 200,
  },
  {
    label: '250 mi',
    value: 250,
  },
]

function FormikSliderTravelDistance({ name, min, max }: IFormikSliderTravelDistanceProps) {
  const { values, setFieldValue } = useFormikContext<FormikValues>()

  const getInValue = getIn(values, name) as number
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setFieldValue(name, newValue, true).catch(console.error)
  }

  return (
    <Slider
      marks={travelDistanceMarks}
      min={min ?? 0}
      max={max ?? 300}
      valueLabelDisplay="auto"
      valueLabelFormat={(value) => `${value} miles`}
      onChange={handleSliderChange}
      value={getInValue ?? 25}
      getAriaLabel={(index) => (index === 0 ? 'min-distance' : 'max-distance')}
    />
  )
}

export default FormikSliderTravelDistance
