import { Checkbox, FormControlLabel } from '@mui/material'
import { getIn, useFormikContext } from 'formik'
import getAppendableTestId from 'lib/utils/testUtils/getAppendableTestId'
import { IFormikCheckboxProps } from './types'

/**
 * @description
 * - Provides a MUI Checkbox that is hooked up to Formik Context API.
 */

export default function FormikCheckBox({
  name,
  label,
  isChecked,
  onChange,
  FormControlLabelProps: FCLP,
  CheckboxProps: CBP,
  disabled,
  appendableTestIds,
}: IFormikCheckboxProps) {
  const { setFieldValue, values } = useFormikContext()

  // Provide a default value for getIn(values, name) and ensure it's not undefined
  const checkedValue = isChecked || Boolean(getIn(values, name))

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, Boolean(event.target.checked), true).catch((error) => console.error(error))
  }

  return (
    <FormControlLabel
      {...FCLP}
      label={label}
      data-testid={
        FCLP?.['data-testid']
          ? FCLP['data-testid']
          : getAppendableTestId({
              appendableTestIds,
              testId: 'formik-checkbox-label',
              key: 'label',
            })
      }
      control={
        <Checkbox
          {...CBP}
          disabled={disabled}
          data-testid={
            CBP?.['data-testid']
              ? CBP['data-testid']
              : getAppendableTestId({
                  appendableTestIds,
                  testId: 'formik-checkbox',
                  key: 'checkbox',
                })
          }
          checked={checkedValue}
          onChange={onChange || handleChange}
        />
      }
    />
  )
}
