import {
  UserInfos,
  accountResources,
  termsAndConditionsResource2,
  userInfoResource,
  userInfoResource2,
  userJwtController,
} from '@chunker/chunker-request'
import { UserCTXProvider as CTXProvider } from '@chunker/user-ctx'
import extractData from 'lib/utils/requestUtils/extractData'
import zenDeskCommands from 'lib/utils/zendeskUtils/zenDeskCommands'

function UserCTXProvider({ children }: { children: JSX.Element }) {
  const setupAppData = () => {
    zenDeskCommands.reauthenticateChat()
  }

  const onUserSessionDestroy = () => {
    zenDeskCommands.logout()
  }

  return (
    <CTXProvider
      onUserSessionCreate={setupAppData}
      onUserSessionDestroy={onUserSessionDestroy}
      getCurrentUser={() => userInfoResource2.requests.getCurrentUser().then(extractData)}
      getTermsOfService={() =>
        termsAndConditionsResource2.requests.getTermsAndConditionsResource2().then(extractData)
      }
      logUserOut={() => userJwtController.requests.postLogout().then(extractData)}
      putUserInfos={(ui) =>
        userInfoResource.requests.putUserInfos(ui as UserInfos).then(extractData)
      }
      getAccount2={() => accountResources.requests.getAccount2().then(extractData)}
    >
      {children}
    </CTXProvider>
  )
}

export default UserCTXProvider
