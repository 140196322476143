import { isFunction } from 'lodash'
import { ZendeskWidget } from './types'

const commandWebWidget: ZendeskWidget = (...command) => {
  try {
    // ensure window.zE exists.
    if (isFunction(window.zE)) {
      window.zE(...command)
    }
    // if window.zE is not a function at this point return null. All return values should always be checked before being used.
    return null
    // ensure zendesk does not create a breaking change, and if they do, console.error it.
  } catch (err) {
    console.error(err)
    return null
  }
}

/**
 * @object chatCommands
 *  - Returns methods to make actions for the zendesk chat.
 */
const zenDeskCommands = {
  /**
   * @function reauthenticateChat
   * - Call after login or a new session is called.
   */
  reauthenticateChat: () => {
    commandWebWidget('webWidget', 'chat:reauthenticate')
  },
  /**
   * @function logout
   * - Call wheen the user logs out to end the session currently active between zendesk
   */
  logout: () => {
    commandWebWidget('webWidget', 'logout')
  },
  /**
   * @function updateLocation
   * - Updates settings to send the current location to zendesk so Chunker Service Team can see what page the user is currently looking at.
   */
  updateLocation: () => {
    commandWebWidget('webWidget', 'updatePath', {
      url: window.location.href,
      title: window.location.href,
    })
  },
}

export default zenDeskCommands
