import { useState } from 'react'
import { Customer } from '@chunker/chunker-request'
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import { AddBox } from '@mui/icons-material'
import useCustomerSearch from 'hooks/useCustomerSearch'
import { IFormSearchCustomerByNameProps } from './types'

export default function FormSearchCustomerByName({
  onSelect,
  selectedCustomer,
  TextFieldProps,
}: IFormSearchCustomerByNameProps) {
  const [selectedValue, setSelectedValue] = useState<Customer | null>(selectedCustomer ?? null)
  const [showOptions, setShowOptions] = useState(false)
  const { queryState, handleUpdateQuery, results, loading } = useCustomerSearch()

  return (
    <Autocomplete
      loading={loading}
      options={results ?? []}
      filterOptions={(options) => options}
      value={selectedValue ?? null}
      getOptionLabel={(option) => {
        const optionName = option?.name ?? ''
        const website = option?.website ?? ''

        return optionName && website ? `${optionName} (${website})` : optionName || website
      }}
      open={showOptions}
      onChange={(_, e) => {
        setSelectedValue(e)
        setShowOptions(false)
        onSelect(e)
      }}
      onInputChange={(_, e, r) => {
        if (r === 'reset') {
          setShowOptions(false)
        } else {
          setShowOptions(true)
        }
      }}
      noOptionsText={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          flexDirection="column"
        >
          <Typography variant="body1">
            If no customer is found, you can create a new one.
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddBox />}
            onClick={() => {
              onSelect(null)
              setSelectedValue(null)
              setShowOptions(false)
            }}
          >
            Create New Customer
          </Button>
        </Box>
      }
      renderOption={(props, option) => {
        const optionName = option?.name ?? ''
        const website = option?.website ?? ''

        return (
          <Box component="li" {...props} key={option.id}>
            <Typography variant="body1">
              {optionName && website ? `${optionName} (${website})` : optionName || website}
            </Typography>
          </Box>
        )
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            {...TextFieldProps}
            label="Search for a customer"
            value={queryState.query}
            onChange={(e) => {
              const { value } = e.target
              const arrVal = value.trim().split(' ')

              // The *  are added to search for a loose match mainly when multiple words are entered
              const adjustedValue = arrVal.length === 1 ? `*${arrVal[0]}*` : `*${arrVal.join('*')}*`
              handleUpdateQuery({ query: adjustedValue })
            }}
          />
        )
      }}
      isOptionEqualToValue={(option, value) => {
        return option?.id === value?.id && option?.name === value?.name
      }}
    />
  )
}
