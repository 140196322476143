import { StandardChunkerAPIType, opportunityStatusResource } from '@chunker/chunker-request'
import useNotify from 'hooks/useNotify'
import { useCallback, useEffect, useState } from 'react'

function useOpportunityStatuses() {
  const { notifyError } = useNotify()
  const [opportunityStatuses, setOpportunityStatuses] = useState<StandardChunkerAPIType[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const fetchOpportunityStatuses = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await opportunityStatusResource.requests.getOpportunityStatuses()
      setOpportunityStatuses(data)
    } catch (error) {
      console.error(error)
      notifyError()
    } finally {
      setLoading(false)
    }
  }, [notifyError])

  useEffect(() => {
    fetchOpportunityStatuses().catch(console.error)
  }, [fetchOpportunityStatuses])

  return { opportunityStatuses, fetch: fetchOpportunityStatuses, loading }
}

export default useOpportunityStatuses
