import { ListItemIcon, ListItemIconProps } from '@mui/material'

/**
 * @description ListItemIcon has a minWidth that is set to some number of pixels.
 * To match the standard appearance of the ListItemIcon in places that did not require
 * the min-width this component was created to make things more dry.
 */
export default function ListItemIconColorInherit({ children, sx, ...props }: ListItemIconProps) {
  return (
    <ListItemIcon sx={{ margin: 0, padding: 0, minWidth: 'fit-content', ...sx }} {...props}>
      {children}
    </ListItemIcon>
  )
}
