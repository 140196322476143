import { Contact } from '@chunker/chunker-request'
import * as yup from 'yup'

export const expectAtLeastOneLocationGrouping = (fields: string[]) => {
  return yup.string().when(fields, {
    is: (...values: (string | number)[]) => values.every((value) => !value),
    then: () => yup.string().required('At least one location grouping is required'),
  })
}

export const expectRequiredIfNewCustomer = () =>
  // eslint-disable-next-line func-names
  yup.string().test('new-customer', 'Required', function (value, ctx) {
    if (!(ctx.parent as Contact).id && !value) return ctx.createError({ message: 'Required' })

    return true
  })
