export default function extractDataAndTotalCount<
  T extends {
    headers: Record<string, string> | undefined
    data: unknown
  },
>(response: T | undefined | null): { data: T['data']; totalCount: string | undefined } {
  return {
    data: response?.data,
    totalCount: response?.headers?.['x-total-count'],
  }
}
