import { useCallback, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { BatchPrediction, Close } from '@mui/icons-material'
import { Opportunity } from '@chunker/chunker-request'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material'
import FormHeaderDivider from 'components/FormHeaderDivider'
import FormHeaderStep from 'components/FormHeaderStep'
import FormikTextField from 'components/FormikTextField'
import FormikTextFieldEditable from 'components/FormikTextFieldEditable'
import useOpportunityCTX from 'hooks/useOpportunityCTX'
import { DeepPartial } from 'types/DeepPartial'
import WarehouseForm from './WarehouseForm'
import StorageForm from './StorageForm'
import TransportationForm from './TransportationForm'
import OpportunityBackNextButtons from '../OpportunityActionsButtons'

function OpportunityInformation({ onClose }: { onClose: () => void }) {
  const { values } = useFormikContext<DeepPartial<Opportunity> & { currentStep: number }>()

  const {
    opportunityStatuses,
    opportunityStatusReasons,
    optionsByHeader,
    opportunitySpaceUnits,
    goodsInOutFrequencyUnits,
  } = useOpportunityCTX()

  const wonId = opportunityStatuses.find(({ label }) => label.includes('Won'))?.id
  const lostId = opportunityStatuses.find(({ label }) => label.includes('Lost'))?.id

  const isClosedWon = values.opportunityStatus?.id === wonId
  const isClosedLost = values.opportunityStatus?.id === lostId

  const isOpportunityStatusClosed = isClosedWon || isClosedLost

  const getClosedStatusReasons = useCallback(() => {
    if (isClosedWon) {
      return opportunityStatusReasons.filter(
        ({ opportunityStatus }) => opportunityStatus.id === wonId,
      )
    }
    if (isClosedLost) {
      return opportunityStatusReasons.filter(
        ({ opportunityStatus }) => opportunityStatus.id === lostId,
      )
    }
    return []
  }, [isClosedLost, isClosedWon, lostId, opportunityStatusReasons, wonId])

  return useMemo(() => {
    return (
      <>
        <DialogTitle>
          <FormHeaderStep
            step="2"
            title="Opportunity Information"
            description="What services or needs are inquired about?"
            Icon={BatchPrediction}
          />
          <Divider sx={{ my: 3 }} />
          <FormikTextFieldEditable id="name" name="name" label="Opportunity Name" required />
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (t) => t.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormHeaderDivider sx={{ my: 3 }}>OPPORTUNITY STATUS</FormHeaderDivider>
          <FormikTextField
            name="opportunityStatus.id"
            label="Opportunity Status"
            size="small"
            fullWidth
            select
            data={opportunityStatuses}
            withDescription
          />
          {isOpportunityStatusClosed && (
            <FormikTextField
              name="opportunityStatusReason.id"
              label="Reason For Closed Status"
              size="small"
              fullWidth
              select
              data={getClosedStatusReasons()}
              withDescription
              sx={{ mt: 3 }}
            />
          )}
          <Typography variant="body2" sx={{ mt: 3 }}>
            Please provide any additional information or context related to the current status of
            the sales opportunity. This may include specific details about the negotiation, decision
            pending, or any relevant notes regarding the status.
          </Typography>
          <FormikTextField
            name="notes"
            label="Notes"
            multiline
            fullWidth
            size="small"
            sx={{ mt: 3 }}
          />
          <FormHeaderDivider sx={{ my: 3 }}>OPPORTUNITY TYPE</FormHeaderDivider>
          <Typography variant="body2">
            Please fill in all applicable fields for the selected opportunity type.
          </Typography>
          <WarehouseForm
            optionsByHeader={optionsByHeader}
            opportunitySpaceUnits={opportunitySpaceUnits}
            goodsInOutFrequencyUnits={goodsInOutFrequencyUnits}
          />
          <StorageForm
            optionsByHeader={optionsByHeader}
            opportunitySpaceUnits={opportunitySpaceUnits}
            goodsInOutFrequencyUnits={goodsInOutFrequencyUnits}
          />
          <TransportationForm
            optionsByHeader={optionsByHeader}
            opportunitySpaceUnits={opportunitySpaceUnits}
            goodsInOutFrequencyUnits={goodsInOutFrequencyUnits}
          />
        </DialogContent>
        <DialogActions>
          <OpportunityBackNextButtons action="back" />
          <OpportunityBackNextButtons action="next" />
        </DialogActions>
      </>
    )
  }, [
    getClosedStatusReasons,
    goodsInOutFrequencyUnits,
    isOpportunityStatusClosed,
    onClose,
    opportunitySpaceUnits,
    opportunityStatuses,
    optionsByHeader,
  ])
}

export default OpportunityInformation
