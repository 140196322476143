import { Button } from '@mui/material'
import { ICSVData, IExportCSVProps } from './types'

function convertToCSV(data: ICSVData) {
  const { headers, rows } = data

  const headerData = headers.join(',')
  const rowData = rows.map((row) =>
    headers.map((fieldName) => JSON.stringify(row[fieldName] ?? '')).join(','),
  )
  return [headerData, ...rowData].join('\n')
}

function downloadCSV({ data, fileName }: { data: ICSVData; fileName: string }) {
  const csv = convertToCSV(data)
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')

  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

function ExportCSV({ data, fileName }: IExportCSVProps) {
  const handleDownload = () => {
    if (data) {
      downloadCSV({ data, fileName })
    } else {
      console.error('No data available to download.')
    }
  }

  return (
    <Button onClick={handleDownload} variant="contained">
      Generate CSV
    </Button>
  )
}

export default ExportCSV
