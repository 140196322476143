import { useUserCTX } from '@chunker/user-ctx'
import { IUserHasRightsProps, IUserHasRightsReturn } from './types'

function useUserHasRights(
  { sales = false, support = false, user = false }: IUserHasRightsProps = {
    sales: false,
    support: false,
    user: false,
  },
): IUserHasRightsReturn {
  const {
    roles: { ROLE_ADMIN, ROLE_SALES, ROLE_SUPPORT, ROLE_USER },
    status: { isInitializing, isUserLoggedIn },
  } = useUserCTX()

  if (isInitializing)
    return { hasRights: false, isInitializing, isUserLoggedIn, userHasAdminOrSalesRole: false }

  // ENSURE BOTH VALUES ARE TRUE
  const hasRoleRights = (routesAcceptedRole: boolean, userRole: boolean) =>
    routesAcceptedRole && userRole

  // ENSURE THAT ANY VALUES IN THE ARRAY ARE TRUE
  const anyRoleHasRights = (...isViewableByRole: boolean[]) => isViewableByRole.includes(true)

  // VERIFY EACH ROLE INDIVIDUALLY
  const isSalesAndCanView = hasRoleRights(sales, ROLE_SALES)
  const isSupportAndCanView = hasRoleRights(support, ROLE_SUPPORT)
  const isUserAndCanView = hasRoleRights(user, ROLE_USER)

  // IF ANY ROLES HAVE ACCESS THEN RETURN TRUE
  return {
    hasRights:
      ROLE_ADMIN || anyRoleHasRights(isSalesAndCanView, isSupportAndCanView, isUserAndCanView),
    userHasAdminOrSalesRole: [ROLE_ADMIN, ROLE_SALES].includes(true),
    isInitializing,
    isUserLoggedIn,
  }
}

export default useUserHasRights
