import { Button, ButtonGroup } from '@mui/material'
import { FormikValues, getIn, useFormikContext } from 'formik'
import { IFormikSwitchProps } from './types'

function FormikSwitch({ switcherName, switcherOptions, textTransform }: IFormikSwitchProps) {
  const { values, setFieldValue } = useFormikContext<FormikValues>()

  const getInValue = getIn(values, switcherName) as number
  const handleSwitcherButtonClicked = (selectedSwitcherOptionValue: number) => {
    setFieldValue(switcherName, selectedSwitcherOptionValue, true).catch(console.error)
  }

  return (
    <ButtonGroup
      variant="outlined"
      sx={{ height: '100%' }}
      disableElevation
      fullWidth
      data-testid="switcher-group"
    >
      {switcherOptions?.map((switcherOption, index) => {
        return (
          <Button
            key={switcherOption.id}
            variant={getInValue === switcherOption.id ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => handleSwitcherButtonClicked(switcherOption.id)}
            style={{
              textTransform: textTransform || 'none',
            }}
            data-testid={`switcher-button-${index}`}
          >
            {switcherOption.label}
          </Button>
        )
      })}
    </ButtonGroup>
  )
}

export default FormikSwitch
