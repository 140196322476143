/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IMaskInput } from 'react-imask'
import { forwardRef } from 'react'
import { IPhoneInputProps } from './types'

const PhoneInput = forwardRef<HTMLElement, IPhoneInputProps>(function TextMaskCustom(
  { onChange, ...rest },
  ref,
) {
  return (
    <IMaskInput
      {...rest}
      mask="+1 (000) 000-0000"
      inputRef={ref as unknown as React.RefObject<HTMLInputElement>}
      onAccept={(value, _, event) => {
        if (event) {
          onChange({ target: { name: rest.name, value } })
        }
      }}
      data-testid="phone-input"
    />
  )
})

export default PhoneInput
