/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { forwardRef } from 'react'
import { IMaskInput } from 'react-imask'
import { IFloatInputProps } from './types'

const FloatInput = forwardRef<HTMLElement, IFloatInputProps>(function TextMaskCustom(
  { onChange, ...rest },
  ref,
) {
  return (
    <IMaskInput
      {...rest}
      mask={Number}
      lazy={false}
      unmask="typed" // It allows sending value as a number instead of a string
      scale={2}
      thousandsSeparator=","
      radix="."
      min={0.0}
      max={999999.99}
      padFractionalZeros
      normalizeZeros
      overwrite
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      inputRef={ref as any}
      onAccept={(value) => onChange({ target: { name: rest.name, value: Number(value) } })}
      data-testid="float-input"
    />
  )
})

export default FloatInput
