import { Button, ButtonProps } from '@mui/material'

export default function ChunkerButton(props: ButtonProps) {
  return (
    <Button
      variant="contained"
      {...props}
      sx={{ boxShadow: 0, '&:hover': { boxShadow: 0 }, ...props?.sx }}
    />
  )
}
