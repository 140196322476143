import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useUserHasRights from 'hooks/useUserHasRights'
import LoadingPage from 'components/LoadingPage'
import URLS from 'lib/constants/urls'
import { ProtectRouteProps } from './types'

export default function ProtectedRoutes({
  sales,
  support,
  user,
  ...outletProps
}: ProtectRouteProps) {
  const location = useLocation()

  const { hasRights, isInitializing, isUserLoggedIn } = useUserHasRights({
    sales,
    support,
    user,
  })

  /**
   * If the user is initializing provide them with a loading animation to let them know they are in the loading state.
   */
  if (isInitializing) return <LoadingPage data-testid="protected-routes-is-initializing" />

  /**
   * If user is not logged in at this point. Send them to login and provide the state to redirect the user back to this page.
   * If for any reason the user returns to this page and they are not of the authenticated route then they will instead be redirect home.
   */
  if (!isUserLoggedIn) return <Navigate to={URLS.login} state={{ redirect: location.pathname }} />

  /**
   * At this point the user is initialized, logged in, and we can now asses if they have the rights to view the page.
   */
  return hasRights ? <Outlet {...outletProps} /> : <Navigate to={URLS.home} />
}
