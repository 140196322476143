import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormikContext } from 'formik'
import { FormValues } from 'types/FormValidation'
import FormikCheckBox from 'components/FormikCheckbox'
import HeaderSmall from 'components/HeaderSmall'
import { Grid, Typography } from '@mui/material'
import { IData } from 'types/IData'
import { ICheckboxRequestClusterProps } from './types'

function CheckboxRequestCluster({
  clusterName,
  header,
  subheader,
  request,
  data,
}: ICheckboxRequestClusterProps) {
  const { values, setFieldValue } = useFormikContext<FormValues>()

  const [checkboxes, setCheckboxes] = useState<
    Array<{ id: number; label: string; helperText?: string }> | undefined
  >([])

  useEffect(() => {
    const onLoad = async () => {
      if (!request) {
        setCheckboxes(data)
        return
      }
      try {
        const checkboxData = await request()
        if (!checkboxData.length) return

        setCheckboxes(checkboxData)
      } catch (error) {
        console.error(error)
      }
    }

    onLoad().catch(console.error)
  }, [data, request])

  const clusterValues = values[clusterName] as IData[]

  const handleCheckboxChange = useCallback(
    (checkbox: IData, checked: boolean) => {
      const currentValues = Array.isArray(clusterValues) ? clusterValues : []

      if (checked) {
        setFieldValue(clusterName, [...currentValues, checkbox]).catch(console.error)
      } else {
        const filtered = currentValues.filter((value: IData) => value.id !== checkbox.id)
        setFieldValue(clusterName, filtered).catch(console.error)
      }
    },
    [clusterName, clusterValues, setFieldValue],
  )

  const mappedElements = useMemo(
    () =>
      checkboxes?.map((checkbox) => {
        const { id, label } = checkbox
        return (
          <Grid item key={id} xs={12} sm={6} md={4} lg={3}>
            <FormikCheckBox
              key={id}
              name={label}
              label={label}
              isChecked={clusterValues?.some((value: IData) => value.id === id)}
              onChange={(event) => handleCheckboxChange(checkbox, event.target.checked)}
            />
          </Grid>
        )
      }),
    [checkboxes, handleCheckboxChange, clusterValues],
  )

  return (
    <Grid container>
      {header && (
        <Grid item xs={12}>
          <HeaderSmall>{header}</HeaderSmall>
        </Grid>
      )}
      {subheader && (
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ mb: 3 }}>
            {subheader}
          </Typography>
        </Grid>
      )}
      <Grid container spacing={3}>
        {mappedElements}
      </Grid>
    </Grid>
  )
}

export default CheckboxRequestCluster
