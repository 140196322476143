import { ISearchFilter } from './types'

export default function getSearchFiltersQuery(searchFilters: ISearchFilter[] = []) {
  return searchFilters
    ?.map((searchFilter) => {
      if (searchFilter.value)
        if (searchFilter.operator)
          return `${searchFilter.filterBy}:${searchFilter.value} ${searchFilter?.operator} `
        else return `${searchFilter.filterBy}:${searchFilter.value}`
      return ''
    })
    .join('')
}
