/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep, get, set } from 'lodash'

// Define a function that merges two objects of different types
function mergeObjects<T extends Record<string, any>, U extends Record<string, any>>(
  obj1: T,
  obj2: U,
): T & U {
  // Clone the first object to avoid modifying it directly
  const result = cloneDeep(obj1)

  // Define a recursive function to merge the objects
  const mergeRecursive = <V extends Record<string, any>, W extends Record<string, any>>(
    target: V,
    source: W,
  ): V & W => {
    for (const key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        // Get the values at the current key from both objects
        const sourceValue = source[key]
        const targetValue = get(target, key)

        if (typeof sourceValue === 'object' && sourceValue !== null) {
          // If the value is an object, recursively merge it
          set(target, key, mergeRecursive(targetValue || {}, sourceValue))
        } else {
          // If the value is not an object, update it or fill in missing values
          set(target, key, sourceValue === undefined ? targetValue : sourceValue)
        }
      }
    }
    // Return the merged object
    return target as V & W
  }

  // Start the merging process with the cloned object and the second object
  return mergeRecursive(result, obj2)
}

export default mergeObjects
