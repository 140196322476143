import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  Divider,
  useTheme,
  Grid,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {
  ChunkerMarketPlace,
  CustomerLocation,
} from '@chunker/chunker-request/dist/types/OpportunitySolution'
import SalesUtilityIcons from 'components/SalesUtilityIcons'
import ContactOrOwnerInfo from './ContactOrOwnerInfo'
import { isChunkerMarketPlaceLocation, renderLocationAddressCell } from '../utils'

function WarehouseDetailsDialog({
  open,
  handleClose,
  location,
}: {
  open: boolean
  handleClose: () => void
  location: CustomerLocation | ChunkerMarketPlace
}) {
  const theme = useTheme()
  const isMarketPlaceLocation = isChunkerMarketPlaceLocation(location)

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Box
        sx={{
          bgcolor: theme.palette.secondary.dark,
          color: theme.palette.common.white,
          px: 6,
          py: 3,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DialogTitle sx={{ p: 0, color: 'common.white' }}>
            {renderLocationAddressCell(location)}
          </DialogTitle>
          <IconButton onClick={handleClose} sx={{ color: 'common.white' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {!isMarketPlaceLocation && location?.sourceURL && (
            <>
              <Typography
                sx={{ fontSize: '0.8rem', color: theme.palette.primary.main, fontWeight: 700 }}
              >
                {location.sourceURL}
              </Typography>
              <SalesUtilityIcons linkValue={location.sourceURL} linkTooltip="Open in new tab" />
            </>
          )}
        </Box>
      </Box>
      <DialogContent sx={{ p: 6 }}>
        <Typography variant="subtitle1">Contacts</Typography>
        <Grid container spacing={2}>
          {!isMarketPlaceLocation &&
            location.customer?.contacts &&
            location.customer?.contacts?.length > 0 &&
            location.customer.contacts.map((contact) => (
              <Grid item xs={12} key={contact.id}>
                <ContactOrOwnerInfo
                  title={contact.title}
                  name={`${contact.firstName ?? ''} ${contact.lastName ?? ''}`}
                  email={contact.contactEmail}
                  phone={contact.contactPhone}
                />
              </Grid>
            ))}
          {!isMarketPlaceLocation &&
            (!location.customer?.contacts || location.customer.contacts.length === 0) && (
              <Grid item xs={12}>
                <Typography>
                  No contact information available. Please verify if it is a duplicate entry in
                  HubSpot by searching for the company name or domain.
                </Typography>
              </Grid>
            )}

          {isMarketPlaceLocation && location?.property?.createdByUser && (
            <Grid item xs={12}>
              <ContactOrOwnerInfo
                title="Property Creator"
                name={`${location?.property?.createdByUser?.firstName ?? ''} ${
                  location?.property?.createdByUser?.lastName ?? ''
                }`}
                email={location?.property?.createdByUser?.email}
              />
            </Grid>
          )}

          {isMarketPlaceLocation && !location?.property?.createdByUser && (
            <Grid item xs={12}>
              <Typography>No property creator information.</Typography>
            </Grid>
          )}
        </Grid>
        <Divider sx={{ m: 2 }} />
        <Typography variant="subtitle1">Owner</Typography>
        {!isMarketPlaceLocation && location.customer && (
          <Grid container spacing={2}>
            <Grid item xs={12} key={location.customer.id}>
              <ContactOrOwnerInfo name={location.customer.name} link={location.customer.website} />
            </Grid>
          </Grid>
        )}

        {!isMarketPlaceLocation && !location.customer && (
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ color: theme.palette.grey[500] }}>
              No owner information available.
            </Typography>
          </Grid>
        )}

        {isMarketPlaceLocation && location?.property?.ownerCompany && (
          <Grid container spacing={2}>
            <Grid item xs={12} key={location.property?.ownerCompany?.id}>
              <ContactOrOwnerInfo name={location.property?.ownerCompany?.legalName} />
              <ContactOrOwnerInfo
                title={location.property?.ownerCompany?.contactTitle}
                name={location.property?.ownerCompany?.contactName}
                email={location.property?.ownerCompany?.contactEmail}
                phone={location.property?.ownerCompany?.contactPhone}
              />
            </Grid>
          </Grid>
        )}

        {isMarketPlaceLocation && !location?.property?.ownerCompany && (
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ color: theme.palette.grey[500] }}>
              No owner company information available.
            </Typography>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default WarehouseDetailsDialog
