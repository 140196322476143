import { useMemo, useState } from 'react'
import { FormikValues, useFormikContext } from 'formik'
import { format, parseISO } from 'date-fns'
import { Button, ButtonGroup, Grid, InputAdornment, Typography } from '@mui/material'
import AddressAutoFill from 'components/AddressAutoFill'
import CheckboxRequestCluster from 'components/CheckboxRequestCluster'
import DateTimeFrames from 'components/DateTimeFrames'
import { DateTimeFramesSelectOptionsValue } from 'components/DateTimeFrames/types'
import getTimeFrameFromDateRange from 'components/DateTimeFrames/utils/getTimeFrameFromDateRange'
import FormAccordionOption from 'components/FormAccordionOption'
import FormAccordionType from 'components/FormAccordionType'
import FormHeaderDivider from 'components/FormHeaderDivider'
import FormikCheckBox from 'components/FormikCheckbox'
import FormikSliderTravelDistance from 'components/FormikSliderTravelDistance'
import FormikTextField from 'components/FormikTextField'
import FormikTextFieldWithSwitch from 'components/FormikTextFieldWithSwitch'
import HeaderSmall from 'components/HeaderSmall'
import { DATE_TIME_FORMATS } from 'lib/constants/time'
import { OPTION_HEADERS_ID } from '../constants/constants'
import { IStorageFormProps } from './types'

function StorageForm({
  optionsByHeader,
  opportunitySpaceUnits,
  goodsInOutFrequencyUnits,
}: IStorageFormProps) {
  const { setFieldValue, values } = useFormikContext<FormikValues>()

  const containerStorageDateStart =
    typeof values.containerStorageDateStart === 'string'
      ? parseISO(values.containerStorageDateStart)
      : null

  const containerStorageDateEnd =
    typeof values.containerStorageDateEnd === 'string'
      ? parseISO(values.containerStorageDateEnd)
      : null

  const [selectedTimeFrameValue, setSelectedTimeFrameValue] =
    useState<DateTimeFramesSelectOptionsValue>(
      getTimeFrameFromDateRange({
        dateTo: containerStorageDateEnd,
        dateFrom: containerStorageDateStart,
      }),
    )

  const spaceUnits = useMemo(() => ['parking spots', 'containers'], [])

  return useMemo(() => {
    return (
      <FormAccordionType type="storage" AccordionBaseProps={{ sx: { mt: 3 } }}>
        <FormHeaderDivider variant="secondary" sx={{ my: 3 }}>
          LOCATION
        </FormHeaderDivider>
        <Typography variant="body2" sx={{ mt: 3 }}>
          Provide the location space is needed. At least one location grouping is required.
        </Typography>
        <AddressAutoFill
          fields={[
            { key: 'containerStorageStreetAddress', label: 'Street', columns: { xs: 12 } },
            { key: 'containerStorageCity', label: 'City' },
            { key: 'containerStorageState', label: 'State' },
            { key: 'containerStorageCountryCode', label: 'Country' },
            { key: 'containerStoragePostalCode', label: 'Zip Code' },
          ]}
          fieldMapping={{
            street_number: 'containerStorageStreetAddress',
            route: 'containerStorageStreetAddress',
            locality: 'containerStorageCity',
            administrative_area_level_1: 'containerStorageState',
            postal_code: 'containerStoragePostalCode',
            country: 'containerStorageCountryCode',
            location: 'containerStorageLocation',
          }}
          BoxProps={{ sx: { mt: 3 } }}
          textFieldProps={{ size: 'small' }}
        />
        <HeaderSmall>Travel Distance</HeaderSmall>
        <Typography variant="body2" sx={{ mb: 3 }}>
          Provide how far the tenant is willing to travel to store their goods.
        </Typography>
        <FormikSliderTravelDistance name="containerStorageTravelDistance" />
        <FormHeaderDivider variant="secondary" sx={{ my: 3 }}>
          SPACE NEEDED
        </FormHeaderDivider>
        <FormikTextFieldWithSwitch
          name="containerStorageSpaceNeeded"
          label="Space Needed"
          switcherName="containerStorageOpportunitySpaceUnit.id"
          switcherOptions={opportunitySpaceUnits.filter((unit) => spaceUnits.includes(unit.label))}
          size="small"
          integer
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <HeaderSmall>How many total loads?</HeaderSmall>
            <FormikTextField
              name="containerStorageUnitsTotal"
              label="Total Units"
              size="small"
              fullWidth
              integer
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <HeaderSmall>Units Per Load</HeaderSmall>
            <FormikTextField
              name="containerStorageUnitsPerLoad"
              label="How many units per load?"
              size="small"
              fullWidth
              integer
            />
          </Grid>
        </Grid>
        <HeaderSmall>Goods In / Out Frequency</HeaderSmall>
        <Typography variant="body2" sx={{ mb: 3 }}>
          How often will the customer need to store and retrieve goods (e.g., daily, weekly,
          monthly)?
        </Typography>
        <FormikTextFieldWithSwitch
          name="containerStorageGoodsInOutFrequency"
          label="Goods In / Out Frequency"
          switcherName="containerStorageGoodsInOutFrequencyUnit.id"
          switcherOptions={goodsInOutFrequencyUnits}
          size="small"
          integer
        />
        <FormHeaderDivider variant="secondary" sx={{ my: 3 }}>
          PROJECT LENGTH
        </FormHeaderDivider>
        <DateTimeFrames
          dateFrom={containerStorageDateStart}
          dateTo={containerStorageDateEnd}
          selectedTimeFrameValue={selectedTimeFrameValue}
          onChange={({ dateTo, dateFrom }) => {
            setSelectedTimeFrameValue(getTimeFrameFromDateRange({ dateTo, dateFrom }))

            setFieldValue(
              'containerStorageDateStart',
              dateFrom ? format(dateFrom, DATE_TIME_FORMATS.DATABASE_NO_TIME) : null,
            ).catch(console.error)
            setFieldValue(
              'containerStorageDateEnd',
              dateTo ? format(dateTo, DATE_TIME_FORMATS.DATABASE_NO_TIME) : null,
            ).catch(console.error)
          }}
          handleUpdateSelectedTimeFrameValue={setSelectedTimeFrameValue}
        />
        <HeaderSmall>Total Estimated Budget</HeaderSmall>
        <FormikTextField
          name="containerStorageEstimatedBudgetTotal"
          label="Total Estimated Budget"
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          fullWidth
          integer
        />
        <HeaderSmall>Estimated Budget Per Unit </HeaderSmall>
        <FormikTextFieldWithSwitch
          name="containerStorageEstimatedBudgetPerUnit"
          label="Estimated Budget Per Unit"
          switcherName="containerStorageOpportunitySpaceUnit.id"
          switcherOptions={opportunitySpaceUnits.filter((unit) => spaceUnits.includes(unit.label))}
          startAdornment="$"
          size="small"
          float
        />
        <FormHeaderDivider variant="secondary" sx={{ my: 3 }}>
          SPECIAL REQUIREMENTS
        </FormHeaderDivider>
        <Grid container>
          <Grid item xs={12}>
            <HeaderSmall>Yard Essentials</HeaderSmall>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ mb: 3 }}>
              Select building requirements that match the needs of the customer.
            </Typography>
          </Grid>
        </Grid>
        <FormAccordionOption title="Parking Spots">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <HeaderSmall>Trailer Parking Spots</HeaderSmall>
              <FormikTextField
                name="containerStorageParkingSpotTrailerSpots"
                label="Trailer Parking Spots"
                size="small"
                fullWidth
                integer
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <HeaderSmall>Car Parking Spots</HeaderSmall>
              <FormikTextField
                name="containerStorageParkingSpotCarSpots"
                label="Car Parking Spots"
                size="small"
                fullWidth
                integer
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <HeaderSmall>Truck Parking Spots</HeaderSmall>
              <FormikTextField
                name="containerStorageParkingSpotTruckSpots"
                label="Truck Parking Spots"
                size="small"
                fullWidth
                integer
              />
            </Grid>
          </Grid>
        </FormAccordionOption>
        <FormAccordionOption title="Shipping Containers">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <HeaderSmall>Container Height</HeaderSmall>
              <FormikTextField
                name="containerStorageContainerHeight"
                label="Container Height"
                size="small"
                InputProps={{
                  endAdornment: 'inches',
                }}
                fullWidth
                float
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HeaderSmall>Container Width</HeaderSmall>
              <FormikTextField
                name="containerStorageContainerWidth"
                label="Container Width"
                size="small"
                InputProps={{
                  endAdornment: 'inches',
                }}
                fullWidth
                float
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HeaderSmall>Container Length</HeaderSmall>
              <FormikTextField
                name="containerStorageContainerLength"
                label="Container Length"
                size="small"
                InputProps={{
                  endAdornment: 'inches',
                }}
                fullWidth
                float
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HeaderSmall>Container Weight</HeaderSmall>
              <FormikTextField
                name="containerStorageContainerWeight"
                label="Container Weight"
                size="small"
                InputProps={{
                  endAdornment: 'pounds',
                }}
                fullWidth
                float
              />
            </Grid>
          </Grid>
          <HeaderSmall>Can they be stacked?</HeaderSmall>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2">
                Specify if any of the tenants containers can be stacked
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ButtonGroup variant="outlined" sx={{ height: '100%' }} disableElevation fullWidth>
                <Button
                  name="containerStorageContainerStackable"
                  onClick={() => {
                    setFieldValue('containerStorageContainerStackable', true).catch(console.error)
                  }}
                  variant={values.containerStorageContainerStackable ? 'contained' : 'outlined'}
                  fullWidth
                  style={{
                    textTransform: 'none',
                  }}
                >
                  Yes
                </Button>
                <Button
                  name="containerStorageContainerStackable"
                  onClick={() => {
                    setFieldValue('containerStorageContainerStackable', false).catch(console.error)
                  }}
                  variant={!values.containerStorageContainerStackable ? 'contained' : 'outlined'}
                  fullWidth
                  style={{
                    textTransform: 'none',
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </Grid>
            {values.containerStorageContainerStackable && (
              <>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2">How many containers can they be stacked?</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    name="containerStorageContainerStackableLevels"
                    label="Stackable Levels"
                    size="small"
                    InputProps={{
                      endAdornment: 'levels',
                    }}
                    fullWidth
                    integer
                  />
                </Grid>
              </>
            )}
          </Grid>
        </FormAccordionOption>
        <CheckboxRequestCluster
          clusterName="containerStorageOptions"
          header="Services Needed"
          subheader="Select all the services needed that match the needs of the customer."
          data={optionsByHeader[OPTION_HEADERS_ID.SERVICES_NEEDED]}
        />
        <CheckboxRequestCluster
          clusterName="containerStorageOptions"
          header="Equipment Needed"
          data={optionsByHeader[OPTION_HEADERS_ID.EQUIPMENT_NEEDED]}
        />
        <FormAccordionOption title="Pallets">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <HeaderSmall>Pallets Height</HeaderSmall>
              <FormikTextField
                name="containerStoragePalletHeight"
                label="Pallets Height"
                size="small"
                InputProps={{
                  endAdornment: 'inches',
                }}
                fullWidth
                float
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HeaderSmall>Pallets Width</HeaderSmall>
              <FormikTextField
                name="containerStoragePalletWidth"
                label="Pallets Width"
                size="small"
                InputProps={{
                  endAdornment: 'inches',
                }}
                fullWidth
                float
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HeaderSmall>Pallets Length</HeaderSmall>
              <FormikTextField
                name="containerStoragePalletLength"
                label="Pallets Length"
                size="small"
                InputProps={{
                  endAdornment: 'inches',
                }}
                fullWidth
                float
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HeaderSmall>Pallets Weight</HeaderSmall>
              <FormikTextField
                name="containerStoragePalletWeight"
                label="Pallets Weight"
                size="small"
                InputProps={{
                  endAdornment: 'pounds',
                }}
                fullWidth
                float
              />
            </Grid>
          </Grid>
          <HeaderSmall>Can they be stacked?</HeaderSmall>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2">
                Specify if any of the tenants pallets can be stacked
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ButtonGroup variant="outlined" sx={{ height: '100%' }} disableElevation fullWidth>
                <Button
                  name="containerStoragePalletStackable"
                  onClick={() => {
                    setFieldValue('containerStoragePalletStackable', true).catch(console.error)
                  }}
                  variant={values.containerStoragePalletStackable ? 'contained' : 'outlined'}
                  fullWidth
                  style={{
                    textTransform: 'none',
                  }}
                >
                  Yes
                </Button>
                <Button
                  name="containerStoragePalletStackable"
                  onClick={() => {
                    setFieldValue('containerStoragePalletStackable', false).catch(console.error)
                  }}
                  variant={!values.containerStoragePalletStackable ? 'contained' : 'outlined'}
                  fullWidth
                  style={{
                    textTransform: 'none',
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </Grid>
            {values.containerStoragePalletStackable && (
              <>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2">How many pallets can they be stacked?</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    name="containerStoragePalletStackableLevels"
                    label="Stackable Levels"
                    size="small"
                    InputProps={{
                      endAdornment: 'levels',
                    }}
                    fullWidth
                    integer
                  />
                </Grid>
              </>
            )}
          </Grid>
        </FormAccordionOption>
        <CheckboxRequestCluster
          clusterName="containerStorageOptions"
          header="Handled Materials"
          data={optionsByHeader[OPTION_HEADERS_ID.HANDLED_GOODS]}
        />
        <FormAccordionOption title="Chemical/Hazmat">
          <FormikCheckBox name="containerStorageHazardousMaterial" label="Chemical/Hazmat" />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <HeaderSmall>What kind of hazardous materials? </HeaderSmall>
              <FormikTextField
                name="containerStorageHazardousMaterialTypeOfMaterials"
                label="Hazmat Type"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <HeaderSmall>Are there any specific requirements or conditions?</HeaderSmall>
              <FormikTextField
                name="containerStorageHazardousMaterialRequiredConditions"
                label="Special Conditions"
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
        </FormAccordionOption>
      </FormAccordionType>
    )
  }, [
    containerStorageDateEnd,
    containerStorageDateStart,
    goodsInOutFrequencyUnits,
    opportunitySpaceUnits,
    optionsByHeader,
    selectedTimeFrameValue,
    setFieldValue,
    spaceUnits,
    values.containerStorageContainerStackable,
    values.containerStoragePalletStackable,
  ])
}

export default StorageForm
