import { Typography } from '@mui/material'
import ChunkerButton from 'components/ChunkerButton'
import { ChunkerButtonWithImageProps } from './types'

function ChunkerButtonWithImage({
  onClick,
  image,
  text,
  sxButtonProps,
  imageStyleProps,
  ...buttonProps
}: ChunkerButtonWithImageProps) {
  return (
    <ChunkerButton
      onClick={onClick}
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...sxButtonProps }}
      variant="outlined"
      color="secondary"
      {...buttonProps}
      data-testid="chunker-button-with-image"
    >
      <img src={image} alt={image} style={{ width: 'auto', height: '10rem', ...imageStyleProps }} />
      <Typography variant="subtitle1">{text}</Typography>
    </ChunkerButton>
  )
}

export default ChunkerButtonWithImage
