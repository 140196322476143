import Router from 'Router'
import { CssBaseline } from '@mui/material'
import Navbar from 'components/Navbar'
import TermsAndConditions from 'components/TermsAndConditions'
import { useEffect } from 'react'
import setupZenDeskChatAuthentication from 'lib/utils/zendeskUtils/setupZenDeskChatAuthentication'
import ZendeskLocationWatcher from 'components/ZendeskLocationWatcher'
import ApplicationFooter from 'components/ApplicationFooter'

function App() {
  useEffect(() => {
    setupZenDeskChatAuthentication()
  }, [])

  return (
    <>
      <TermsAndConditions />
      <Navbar />
      <CssBaseline />
      <ZendeskLocationWatcher />
      <Router />
      <div data-testid="App" />
      <ApplicationFooter />
    </>
  )
}

export default App
