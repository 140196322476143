import { useState } from 'react'
import { isValid } from 'date-fns'
import { Box } from '@mui/material'
import { TimeValidationError, TimePicker } from '@mui/x-date-pickers'
import { ITimeFramesProps } from './types'

/**
 *
 * @description TimeFrames
 * - Provides a time picker cluster.
 * Sends the startTime and endTime for each onChange.
 */

function TimeFrames({ startTime, endTime, onChange }: ITimeFramesProps) {
  const [errorStartTime, setErrorStartTime] = useState<TimeValidationError | null>(null)
  const [errorEndTime, setErrorEndTime] = useState<TimeValidationError | null>(null)

  const getErrorMessage = (error: TimeValidationError) => {
    if (!error) return null

    return 'Invalid time, format must be HH:MM AM/PM'
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: ['column', 'row'] }} data-testid="time-frames">
      <Box sx={{ flex: 1, pr: [0, 3], pt: [3, 0] }}>
        <TimePicker
          label="Time Start"
          value={startTime}
          onChange={(newValue) => {
            if (isValid(newValue)) {
              onChange({ startTime: newValue, endTime })
            }
          }}
          onError={(error) => setErrorStartTime(error)}
          slotProps={{
            textField: {
              fullWidth: true,
              size: 'small',
              inputProps: { 'data-testid': 'time-frames-start-input' },
              helperText: getErrorMessage(errorStartTime),
            },
          }}
        />
      </Box>
      <Box sx={{ flex: 1, pt: [3, 0] }}>
        <TimePicker
          label="Time End"
          value={endTime}
          onChange={(newValue) => {
            if (isValid(newValue)) {
              onChange({ startTime, endTime: newValue })
            }
          }}
          onError={(error) => setErrorEndTime(error)}
          slotProps={{
            textField: {
              fullWidth: true,
              size: 'small',
              inputProps: { 'data-testid': 'time-frames-end-input' },
              helperText: getErrorMessage(errorEndTime),
            },
          }}
        />
      </Box>
    </Box>
  )
}

export default TimeFrames
