import { Box } from '@mui/material'
import greyLogo from 'assets/logo-chunker-gray-mini.png'
import { APPLICATION_MAX_CONTAINER_WIDTH } from 'lib/constants/sizes'
import windowOpenBlank from '../../../lib/utils/windowUtls/windowOpenBlank'
import URLS from '../../../lib/constants/urls'

export default function SeparationLine() {
  return (
    <Box>
      <Box
        data-testid="application-footer-separation-line"
        sx={{
          borderTopStyle: 'solid',
          borderTopWidth: 1,
          borderTopColor: (theme) => theme.palette.secondary.light,
          maxWidth: APPLICATION_MAX_CONTAINER_WIDTH,
          margin: '0 auto',
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6, mb: 3 }}>
        <Box
          component="img"
          src={greyLogo}
          data-testid="application-footer-separation-line-chunker-logo-gray"
          sx={{ cursor: 'pointer' }}
          onClick={() => windowOpenBlank(URLS.leaveCompanyPortal.marketPlace)}
        />
      </Box>
    </Box>
  )
}
