import { Opportunity } from '@chunker/chunker-request'
import opportunitiesResource from '@chunker/chunker-request/dist/opportunitiesResource'
import useDebounce from 'hooks/useDebounce'
import useNotify from 'hooks/useNotify'
import builtQuery from 'lib/utils/hooksUtils/builtQuery'
import extractDataAndTotalCount from 'lib/utils/requestUtils/extractDataAndTotalCount'
import { useCallback, useEffect, useState } from 'react'
import { IQueryState } from 'types/IQueryState'

function useOpportunitiesSearch() {
  const [results, setResults] = useState<Opportunity[]>([])
  const [totalResults, setTotalResults] = useState<number>(0)
  const [loading, setLoading] = useState(true)
  const [reload, setReload] = useState(false)
  const [queryState, setQueryState] = useState<IQueryState>({
    query: '',
    filters: [],
    page: 0,
    size: 10,
    sort: ['createdDate,desc'],
  })

  const handleUpdateQuery = (updateQuery: Partial<IQueryState>) =>
    setQueryState((prev) => ({ ...prev, ...updateQuery }))

  const { notifyError } = useNotify()

  const query = useDebounce(builtQuery(queryState), 500)

  const fetchData = useCallback(async () => {
    try {
      setLoading(true)

      const { data, totalCount } = await opportunitiesResource.requests
        .searchOpportunities(query)
        .then(extractDataAndTotalCount)

      setResults(data)
      setTotalResults(Number(totalCount))
    } catch (err) {
      console.error(err)
      notifyError('An error occurred while searching for an opportunity.')
    } finally {
      setLoading(false)
    }
  }, [query, notifyError])

  useEffect(() => {
    fetchData().catch(console.error)

    if (reload) {
      setReload(false)
    }
  }, [fetchData, reload])

  const handleReload = () => {
    setReload(true)
  }

  return {
    loading,
    results,
    totalResults,
    queryState,
    handleUpdateQuery,
    handleReload,
  }
}

export default useOpportunitiesSearch
