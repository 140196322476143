import { Button, ButtonProps, Theme, alpha, useTheme } from '@mui/material'

export const buttonDarkContrastStyle = (theme: Theme) => {
  return {
    backgroundColor: `${theme.palette.common.black}`,
    color: `${theme.palette.common.white}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.8),
    },
    '&:disabled': {
      backgroundColor: alpha(theme.palette.common.black, 0.6),
      color: alpha(theme.palette.common.white, 0.2),
    },
  }
}

function ButtonWithDarkContrast({ children, sx, ...props }: ButtonProps) {
  const theme = useTheme()
  return (
    <Button
      variant="contained"
      sx={{
        ...buttonDarkContrastStyle(theme),
        ...sx,
      }}
      {...props}
      data-testid="button-with-dark-contrast"
    >
      {children}
    </Button>
  )
}

export default ButtonWithDarkContrast
