import { Typography } from '@mui/material'
import {
  ChunkerMarketPlace,
  CustomerLocation,
} from '@chunker/chunker-request/dist/types/OpportunitySolution'

export const isChunkerMarketPlaceLocation = (
  location: CustomerLocation | ChunkerMarketPlace,
): location is ChunkerMarketPlace => {
  return (location as ChunkerMarketPlace).property !== undefined
}

export const renderLocationTypeCell = (location: CustomerLocation | ChunkerMarketPlace) => {
  if (!isChunkerMarketPlaceLocation(location)) return location.locationType

  if (location.property?.rawProperty) return 'land'

  if (!location.property?.rawProperty) return 'building'

  return 'unknown'
}

const getLocationDetails = (location: CustomerLocation | ChunkerMarketPlace) => {
  if (!isChunkerMarketPlaceLocation(location)) {
    return {
      city: location.city,
      state: location.state,
      streetAddress1: location.streetAddress1,
    }
  }
  return {
    city: location?.property?.city,
    state: location?.property?.state,
    streetAddress1: location?.property?.streetAddress1,
  }
}

export const renderLocationAddressCell = (location: CustomerLocation | ChunkerMarketPlace) => {
  const { city, state, streetAddress1 } = getLocationDetails(location)

  const formattedLocation = city && state ? `${city}, ${state}` : city || state

  return (
    <>
      <Typography variant="body1" sx={{ fontWeight: '600' }}>
        {formattedLocation}
      </Typography>
      <Typography variant="body2">{streetAddress1}</Typography>
    </>
  )
}

export function renderLocationDistanceCell(location: CustomerLocation | ChunkerMarketPlace) {
  const distance = isChunkerMarketPlaceLocation(location)
    ? location?.property?.distance
    : location?.distance

  if (distance === undefined) return 'N/A'
  if (distance === null) return 'N/A'
  if (distance < 1) return '<1 Miles'
  const stringDistance = distance.toString()
  const decimalIndex = stringDistance.indexOf('.')
  if (decimalIndex === -1) return `${stringDistance}.0 Miles`

  return `${Math.round(distance * 10) / 10} Miles`
}

export const validateUrl = (url: string): string => {
  if (url.startsWith('https://') || url.startsWith('http://')) {
    return url // Return the URL unchanged
  }
  return `https://${url}`
}
