/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { forwardRef } from 'react'
import { IMaskInput } from 'react-imask'
import { IIntegerInputProps } from './types'

const IntegerInput = forwardRef<HTMLElement, IIntegerInputProps>(function TextMaskCustom(
  { onChange, ...rest },
  ref,
) {
  return (
    <IMaskInput
      {...rest}
      mask={Number}
      lazy={false}
      unmask="typed" // It allows sending value as a number instead of a string
      thousandsSeparator=","
      min={0}
      max={999999}
      overwrite
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      inputRef={ref as any}
      onAccept={(value) => onChange({ target: { name: rest.name, value: Number(value) } })}
      data-testid="integer-input"
    />
  )
})

export default IntegerInput
