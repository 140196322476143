/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react'
import { useUserCTX } from '@chunker/user-ctx'
import ApplicationDialog from 'components/ApplicationDialog'
import ChunkerButton from 'components/ChunkerButton'
import URLS from 'lib/constants/urls'
import { Navigate } from 'react-router-dom'
import ButtonLinkClickable from 'components/ButtonLinkClickable'
import { Box, Button, Collapse, DialogContent, Typography, useTheme } from '@mui/material'
import windowOpenBlank from 'lib/utils/windowUtls/windowOpenBlank'
import { termsAndConditionsResource2 } from '@chunker/chunker-request'
import extractData from 'lib/utils/requestUtils/extractData'
import DOMPurify from 'dompurify'
import { sortBy } from 'lodash'
import useNotify from 'hooks/useNotify'

function TermsAndConditions() {
  const theme = useTheme()

  const {
    status,
    shouldShowTermsAndConditions,
    clearUserSession,
    handleAcceptTermsAndConditions,
    handleDeclineTermsAndConditions,
  } = useUserCTX()

  const [open, setOpen] = useState(true)
  const [termsAndConditions, setTermsAndConditions] = useState('')
  const [showTerms, setShowTerms] = useState(false)

  const { notifyError, notifySuccess } = useNotify()

  useEffect(() => {
    termsAndConditionsResource2.requests
      .getTermsAndConditionsResource2()
      .then(extractData)
      .then((tcsArray) => {
        const currentTermsOfServiceObj = sortBy(tcsArray, ['id'])[0]
        setTermsAndConditions(DOMPurify.sanitize(currentTermsOfServiceObj.content ?? ''))
      })
      .catch((error) => {
        notifyError('Error getting terms and conditions')
        console.error(error)
      })
  }, [notifyError])

  if (status.isUserLoggedOut) return <Navigate to={URLS.login} />

  return shouldShowTermsAndConditions ? (
    <ApplicationDialog
      open={open}
      title="Chunker Terms of Service and Privacy Policy Agreement"
      onClose={(_, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') setOpen(false)
      }}
      DialogActionsProps={{
        sx: {
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'stretch', // Take up full width in a row
            '& > *': {
              margin: theme.spacing(1, 0),
            },
            '& > *:not(:first-of-type)': {
              margin: theme.spacing(1, 0),
            },
          },
        },
      }}
      actions={
        <>
          <Button
            onClick={() => setShowTerms((prev) => !prev)}
            variant="outlined"
            data-testid="terms-and-conditions-button-show-hide"
          >
            {showTerms ? 'Hide Terms and Conditions' : 'Show Terms and Conditions'}
          </Button>
          <ChunkerButton
            onClick={() => {
              handleDeclineTermsAndConditions()
              notifyError('Access Denied. You must accept our terms of use and privacy policy.')
            }}
            variant="contained"
            color="secondary"
            data-testid="terms-and-conditions-button-decline"
          >
            I Decline
          </ChunkerButton>
          <ChunkerButton
            onClick={() => {
              handleAcceptTermsAndConditions()
              notifySuccess('You have successfully accepted our terms of use and privacy policy.')
            }}
            variant="contained"
            color="primary"
            data-testid="terms-and-conditions-button-accept"
          >
            I Accept
          </ChunkerButton>
        </>
      }
    >
      <DialogContent data-testid="terms-and-conditions">
        <Box
          sx={{
            display: 'flex',
            whiteSpace: 'break-spaces',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          By clicking &#39;I Accept&#39; you agree to the{' '}
          <ButtonLinkClickable
            onClick={() => {
              windowOpenBlank(URLS.leaveCompanyPortal.terms)
              clearUserSession().catch(console.error)
            }}
            data-testid="terms-and-conditions-button-terms"
          >
            Terms of Service{' '}
          </ButtonLinkClickable>
          and{' '}
          <ButtonLinkClickable
            onClick={() => {
              clearUserSession().catch(console.error)
              windowOpenBlank(URLS.leaveCompanyPortal.privacyPolicy)
            }}
            data-testid="terms-and-conditions-button-privacy"
          >
            Privacy Policy
          </ButtonLinkClickable>
          .
        </Box>
        <Collapse in={showTerms} sx={{ my: 3 }} unmountOnExit>
          <>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Terms and Conditions:
            </Typography>
            {/* eslint-disable-next-line react/no-danger */}
            <div
              dangerouslySetInnerHTML={{ __html: termsAndConditions }}
              data-testid="terms-and-conditions-details"
            />
          </>
        </Collapse>
      </DialogContent>
    </ApplicationDialog>
  ) : null
}

export default TermsAndConditions
