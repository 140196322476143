import { Grid } from '@mui/material'
import AddressAutoFill from 'components/AddressAutoFill'
import FormikTextField from 'components/FormikTextField'
import OutlinedContainer from 'components/OutlinedContainer'
import { IFormikCompanyClusterProps } from './types'

export default function FormikCompanyCluster({
  readOnly = false,
  fieldNameCompanyName = `companyName`,
  fieldNameCompanyWebsite = 'companyWebsite',
  fieldNameAddress = 'address',
  fieldNameCity = 'city',
  fieldNameCountry = 'country',
  fieldNamePostalCode = 'zipCode',
  fieldNameState = 'state',
  OutlinedContainerProps,
}: IFormikCompanyClusterProps) {
  return (
    <OutlinedContainer {...OutlinedContainerProps}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormikTextField
            label="Company Name"
            name={fieldNameCompanyName}
            disabled={readOnly}
            data-testid={`formik-company-cluster-${fieldNameCompanyName}`}
            size="small"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikTextField
            label="Company Website"
            name={fieldNameCompanyWebsite}
            disabled={readOnly}
            data-testid={`formik-company-cluster-${fieldNameCompanyWebsite}`}
            size="small"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <AddressAutoFill
            fields={[
              { key: fieldNameAddress, label: 'Street', columns: { xs: 12, sm: 12 } },
              { key: fieldNameCity, label: 'City', columns: { xs: 12, sm: 6 } },
              { key: fieldNameState, label: 'State', columns: { xs: 12, sm: 6 } },
              { key: fieldNameCountry, label: 'Country', columns: { xs: 12, sm: 6 } },
              { key: fieldNamePostalCode, label: 'Zip Code', columns: { xs: 12, sm: 6 } },
            ]}
            fieldMapping={{
              street_number: fieldNameAddress,
              route: fieldNameAddress,
              locality: fieldNameCity,
              administrative_area_level_1: fieldNameState,
              postal_code: fieldNamePostalCode,
              country: fieldNameCountry,
            }}
            searchBarDisabled={readOnly}
            textFieldProps={{
              size: 'small',
            }}
          />
        </Grid>
      </Grid>
    </OutlinedContainer>
  )
}
