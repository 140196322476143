import React from 'react'
import { Container, useTheme } from '@mui/material'
import LoadingPage from 'components/LoadingPage'

export default function PageContainer({
  children,
  loading,
}: {
  children: React.ReactNode | React.ReactNodeArray
  loading?: boolean
}) {
  const theme = useTheme()
  const { minHeight } = theme.mixins.toolbar as { minHeight: number }

  if (loading) return <LoadingPage />

  return (
    <Container
      data-testid="page-container"
      maxWidth="lg"
      sx={{
        pt: 6,
        pb: 6,
        minHeight: `calc(100vh - ${minHeight}px)`,
      }}
    >
      {children}
    </Container>
  )
}
