import { useState, useCallback, useEffect } from 'react'
import { Opportunity, Option } from '@chunker/chunker-request'
import { Id } from '@chunker/chunker-request/dist/types/Id'
import {
  ErrorOutline,
  RadioButtonChecked,
  RemoveRedEyeRounded,
  Search,
  HolidayVillage,
  LocalShipping,
  BorderAll,
} from '@mui/icons-material'
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { format } from 'date-fns'
import ChunkerButton from 'components/ChunkerButton'
import HeaderMedium from 'components/HeaderMedium'
import HeaderPage from 'components/HeaderPage'
import useOpportunitiesSearch from 'hooks/useOpportunitiesSearch'
import PageContainer from 'AppLayout/PageContainer'
import { DATE_TIME_FORMATS } from 'lib/constants/time'
import useOpportunityCTX from 'hooks/useOpportunityCTX'
import { ISearchFilter } from 'lib/utils/requestUtils/getSearchFiltersQuery/types'
import OpportunitiesForm from './OpportunitiesForm'
import { OpportunityLocation } from './types'

const locationFields = [
  'warehouseCity',
  'warehouseState',
  'containerStorageCity',
  'containerStorageState',
  'transportationServicesOriginCity',
  'transportationServicesOriginState',
  'transportationServicesDestinationCity',
  'transportationServicesDestinationState',
  'transportationServicesAdditionalDestinationCity',
  'transportationServicesAdditionalDestinationState',
]

function OpportunitiesList() {
  const [selectedOpportunityId, setSelectedOpportunityId] = useState<Id>()
  const [selectedOpportunitiesStatusesIds, setSelectedOpportunitiesStatusesIds] = useState<
    string[]
  >([])
  const [open, setOpen] = useState(false)
  const [isNewSearch, setIsNewSearch] = useState(false)
  const [filters, setFilters] = useState({
    name: '',
    'customer.name': '',
    location: '',
  })

  const { opportunityStatuses } = useOpportunityCTX()

  const { loading, results, totalResults, queryState, handleUpdateQuery, handleReload } =
    useOpportunitiesSearch()

  const buildQueryString = useCallback(() => {
    const queryParts = []
    for (const [key, value] of Object.entries(filters)) {
      if (value) {
        if (key === 'location') {
          const locationQuery = locationFields.map((field) => `${field}:*${value}*`).join(' OR ')
          queryParts.push(`(${locationQuery})`)
        } else {
          queryParts.push(`${key}:*${value}*`)
        }
      }
    }
    return queryParts.join(' AND ')
  }, [filters])

  useEffect(() => {
    const queryString = buildQueryString()
    handleUpdateQuery({
      query: queryString,
      page: isNewSearch ? 0 : queryState.page,
    })
    setIsNewSearch(false)
  }, [filters, buildQueryString, handleUpdateQuery, isNewSearch, queryState.page])

  const handleFilterChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilters = { ...filters, [field]: event.target.value }
    setFilters(newFilters)
    setIsNewSearch(true)
  }

  const handleStatusChange = (
    event: SelectChangeEvent<typeof selectedOpportunitiesStatusesIds>,
  ) => {
    const { value } = event.target

    const field = 'opportunityStatus.id'
    const operator = 'OR'

    const selectedValues = typeof value === 'string' ? value.split(',') : value

    const searchFilters: ISearchFilter[] = selectedValues.map((selectedValue, index) => ({
      filterBy: field,
      value: selectedValue,
      operator: index === selectedValues.length - 1 ? '' : operator,
    }))

    handleUpdateQuery({ filters: searchFilters, page: 0 })

    setSelectedOpportunitiesStatusesIds(selectedValues)
    setIsNewSearch(true)
  }

  const handlePageChange = (event: unknown, newPage: number) => {
    handleUpdateQuery({ page: newPage })
  }

  const onClose = () => {
    setOpen(false)
    setSelectedOpportunityId(undefined)
    handleReload()
  }

  const renderOptionsList = (options: Option[] | undefined, maxToShow = 3) => {
    if (!options) {
      return <Chip label="None" />
    }

    return (
      <>
        {options.slice(0, maxToShow).map((option) => (
          <ListItem key={option.id}>
            <Chip
              key={option.id}
              label={option.label}
              style={{ maxWidth: '200px', height: 'auto' }}
            />
          </ListItem>
        ))}
        {options.length > maxToShow && (
          <ListItem>
            <Chip
              label={`+${options.length - maxToShow} more`}
              style={{ maxWidth: '200px', height: 'auto' }}
            />
          </ListItem>
        )}
      </>
    )
  }

  const renderLocationsList = (locs: OpportunityLocation[]) => {
    return locs.map((loc, i) => {
      const { state, city } = loc
      const statePlusCommaSpace = state ? `${state}, ` : ''
      const entireLabel = `${statePlusCommaSpace}${city ?? ''}`
      const k = `${i} ${entireLabel}`

      return (
        <Chip
          sx={{
            '&:not(:last-child)': { mb: 2 },
            width: '100%',
            height: 'auto',
            justifyContent: 'flex-start',
          }}
          label={
            <Box sx={{ display: 'flex', flexDirection: 'column', py: 1 }}>
              <Typography sx={{ fontWeight: 500, fontSize: 14 }}>{entireLabel}</Typography>
              <Typography
                sx={{
                  borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                  fontSize: 10,
                  textTransform: 'uppercase',
                }}
              >
                {loc.subLabel}
              </Typography>
            </Box>
          }
          key={k}
          icon={<loc.Icon />}
        />
      )
    })
  }

  const getOpportunityLocations: (opportunity: Opportunity) => OpportunityLocation[] = (
    opportunity,
  ) => {
    const warehouseLocation: OpportunityLocation = {
      state: opportunity.warehouseState,
      city: opportunity.warehouseCity,
      Icon: HolidayVillage,
      subLabel: 'Warehouse',
    }

    const containerStorageLocation: OpportunityLocation = {
      state: opportunity.containerStorageState,
      city: opportunity.containerStorageCity,
      Icon: BorderAll,
      subLabel: 'Storage',
    }

    const transportationLocation1: OpportunityLocation = {
      state: opportunity.transportationServicesOriginState,
      city: opportunity.transportationServicesOriginCity,
      Icon: LocalShipping,
      subLabel: 'Origin',
    }

    const transportationLocation2: OpportunityLocation = {
      state: opportunity.transportationServicesDestinationState,
      city: opportunity.transportationServicesDestinationCity,
      Icon: LocalShipping,
      subLabel: 'Destination',
    }

    const transportationLocation3: OpportunityLocation = {
      state: opportunity.transportationServicesAdditionalDestinationState,
      city: opportunity.transportationServicesAdditionalDestinationCity,
      Icon: LocalShipping,
      subLabel: 'Add-Destination',
    }

    const categoryLocations = [
      warehouseLocation,
      containerStorageLocation,
      transportationLocation1,
      transportationLocation2,
      transportationLocation3,
    ]

    const validLocations = []

    for (const loc of categoryLocations) if (loc.city || loc.state) validLocations.push(loc)

    return validLocations
  }

  return (
    <PageContainer>
      <OpportunitiesForm open={open} onClose={onClose} opportunityId={selectedOpportunityId} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderPage>List of Opportunities</HeaderPage>
          <HeaderMedium mb={6}>
            Create new entries, view detailed information, and perform detailed searches.
          </HeaderMedium>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            label="Search by Name"
            value={filters.name}
            onChange={handleFilterChange('name')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            placeholder="Enter name"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            label="Search by Company"
            value={filters['customer.name']}
            onChange={handleFilterChange('customer.name')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            placeholder="Enter company"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            label="Search by Location"
            value={filters.location}
            onChange={handleFilterChange('location')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            placeholder="Enter city or state"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={3}>
          <ChunkerButton
            data-testid="create-opportunity-button"
            color="secondary"
            startIcon={<RadioButtonChecked />}
            onClick={() => {
              setSelectedOpportunityId('new')
              setOpen(true)
            }}
            fullWidth
          >
            Create Opportunity
          </ChunkerButton>
        </Grid>
        <Grid item xs={12}>
          <FormControl size="small" fullWidth>
            <InputLabel
              id="multiple-checkbox-label"
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
              }}
            >
              Filter by Opportunity Status
            </InputLabel>
            <Select
              labelId="multiple-checkbox-label"
              id="multiple-checkbox"
              multiple
              value={selectedOpportunitiesStatusesIds}
              onChange={handleStatusChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => {
                    const opportunityStatus = opportunityStatuses.find(
                      (status) => status.id.toString() === value.toString(),
                    )
                    return (
                      <Chip
                        key={value}
                        label={opportunityStatus?.label}
                        style={{ maxWidth: '200px', height: 'auto' }}
                      />
                    )
                  })}
                </Box>
              )}
            >
              {opportunityStatuses.map((opportunityStatus) => {
                return (
                  <MenuItem
                    key={opportunityStatus.id}
                    value={opportunityStatus.id}
                    sx={{ px: 1, py: 0.5 }}
                  >
                    <Checkbox
                      checked={selectedOpportunitiesStatusesIds.some(
                        (id) => id.toString() === opportunityStatus.id.toString(),
                      )}
                    />
                    <ListItemText primary={opportunityStatus.label} />
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        {loading && <LinearProgress />}
        {!loading && results.length === 0 && (
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
              }}
            >
              <ErrorOutline fontSize="large" color="secondary" />
              <Typography variant="h6" color="text.secondary" align="center" sx={{ py: 2 }}>
                No opportunities found.
              </Typography>
              <Typography variant="body2" color="text.secondary" align="center">
                Please try a different search or create a new opportunity.
              </Typography>
            </Box>
          </Grid>
        )}
        {results.length > 0 && (
          <Grid item xs={12}>
            <TableContainer sx={{ overflow: 'auto' }}>
              <Table size="small">
                <TableHead
                  style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  <TableRow
                    sx={{
                      backgroundColor: (theme) => theme.palette.secondary.dark,
                    }}
                  >
                    <TableCell />
                    <TableCell
                      align="left"
                      sx={{ color: (theme) => theme.palette.common.white, fontWeight: 'bold' }}
                    >
                      NAME
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: (theme) => theme.palette.common.white, fontWeight: 'bold' }}
                    >
                      COMPANY
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: (theme) => theme.palette.common.white, fontWeight: 'bold' }}
                    >
                      LOCATION
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: (theme) => theme.palette.common.white, fontWeight: 'bold' }}
                    >
                      CREATED ON
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: (theme) => theme.palette.common.white, fontWeight: 'bold' }}
                    >
                      CREATED BY
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: (theme) => theme.palette.common.white, fontWeight: 'bold' }}
                    >
                      STATUS
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={3}
                      sx={{ color: (theme) => theme.palette.common.white, fontWeight: 'bold' }}
                    >
                      OPTIONS (SELECTED)
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      backgroundColor: (theme) => theme.palette.secondary.main,
                    }}
                  >
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell
                      align="center"
                      sx={{ color: (theme) => theme.palette.common.white, fontWeight: 'bold' }}
                    >
                      WAREHOUSE
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: (theme) => theme.palette.common.white, fontWeight: 'bold' }}
                    >
                      STORAGE
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: (theme) => theme.palette.common.white, fontWeight: 'bold' }}
                    >
                      TRANSPORTATION
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.map((opportunity) => (
                    <TableRow key={opportunity.id} hover sx={{ cursor: 'pointer' }}>
                      <TableCell align="right">
                        <ChunkerButton
                          variant="outlined"
                          color="secondary"
                          startIcon={<RemoveRedEyeRounded />}
                          onClick={() => {
                            setSelectedOpportunityId(opportunity.id)
                            setOpen(true)
                          }}
                        >
                          View
                        </ChunkerButton>
                      </TableCell>
                      <TableCell style={{ minWidth: '200px', fontWeight: 'bold' }}>
                        {opportunity?.name}
                      </TableCell>
                      <TableCell style={{ minWidth: '200px' }}>
                        {opportunity.customer?.name}
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: '100px',
                        }}
                      >
                        {renderLocationsList(getOpportunityLocations(opportunity))}
                      </TableCell>
                      <TableCell style={{ minWidth: '140px' }}>
                        {opportunity.createdDate
                          ? format(
                              new Date(opportunity?.createdDate),
                              DATE_TIME_FORMATS.USER_FRIENDLY_DATE_TIME,
                            )
                          : ''}
                      </TableCell>
                      <TableCell>{opportunity?.createdBy}</TableCell>
                      <TableCell style={{ minWidth: '100px' }}>
                        {opportunity?.opportunityStatus?.label}
                      </TableCell>
                      <TableCell style={{ minWidth: '200px' }}>
                        <List disablePadding>
                          {renderOptionsList(opportunity?.warehouseOptions)}
                        </List>
                      </TableCell>
                      <TableCell style={{ minWidth: '200px' }}>
                        <List>{renderOptionsList(opportunity?.containerStorageOptions)}</List>
                      </TableCell>
                      <TableCell style={{ minWidth: '200px' }}>
                        <List>{renderOptionsList(opportunity?.transportationServicesOptions)}</List>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalResults}
              rowsPerPageOptions={[queryState.size]}
              rowsPerPage={queryState.size}
              page={queryState.page}
              onPageChange={handlePageChange}
            />
          </Grid>
        )}
      </Grid>
    </PageContainer>
  )
}

export default OpportunitiesList
