import { Box, Card, Grid, useTheme } from '@mui/material'
import { IInfoCardWithImageProps } from './types'

function InfoCardWithImage({ image, text }: IInfoCardWithImageProps) {
  const theme = useTheme()
  return (
    <Card raised>
      <Grid container>
        <Grid item xs={4} sx={{ order: { xs: 2, sm: 1, md: 1 } }}>
          {/* Image */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '100%',
              height: '100%',
              paddingBottom: '100%', // 1:1 aspect ratio
              backgroundImage: `url(${image})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundColor: theme.palette.primary.main,
            }}
            data-testid="info-card-with-image-image"
          />
        </Grid>
        <Grid item xs={8} p={4} sx={{ order: { xs: 1, sm: 1, md: 2 } }}>
          {/* Text */}
          <Box>{text}</Box>
        </Grid>
      </Grid>
    </Card>
  )
}

export default InfoCardWithImage
