import { Box, BoxProps } from '@mui/material'

export default function OutlinedContainer({ sx, ...props }: BoxProps) {
  return (
    <Box
      data-testid="outlined-container"
      {...props}
      sx={{
        ...sx,
        p: 3,
        border: (t) => `1px solid ${t.palette.grey[400]}`,
        borderRadius: 2,
      }}
    />
  )
}
