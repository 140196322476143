import { useState } from 'react'
import { OpportunitiesContact } from '@chunker/chunker-request'
import { AddBox } from '@mui/icons-material'
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import useContactSearch from 'hooks/useContactSearch'
import { ISearchFilter } from 'lib/utils/requestUtils/getSearchFiltersQuery/types'
import getSearchFiltersQuery from 'lib/utils/requestUtils/getSearchFiltersQuery'
import { IFormSearchContactsProps } from './types'

function FormSearchContacts({
  onSelect,
  selectedContacts,
  TextFieldProps,
}: IFormSearchContactsProps) {
  const [selectedValues, setSelectedValues] = useState<OpportunitiesContact[]>(
    selectedContacts ?? [],
  )
  const { handleUpdateQuery, results, loading } = useContactSearch()

  return (
    <>
      <Autocomplete
        multiple
        loading={loading}
        options={results ?? []}
        filterOptions={(options) => options}
        value={selectedValues}
        getOptionLabel={(option) => {
          const { firstName = '', lastName = '', contactEmail = '' } = option?.contact ?? {}
          return `${firstName} ${lastName} ${contactEmail ? `<${contactEmail}>` : ''}`.trim()
        }}
        onChange={(_, e) => {
          setSelectedValues(e)
          onSelect(e)
        }}
        noOptionsText={
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            flexDirection="column"
          >
            <Typography variant="body1">
              If no contact is found, you can create a new one.
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddBox />}
              onClick={() => {
                onSelect([...selectedValues], true)
                setSelectedValues([...selectedValues])
              }}
            >
              Create new contact
            </Button>
          </Box>
        }
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label="Search for a contact"
              variant="outlined"
              onChange={(e) => {
                if (e.target.value === '') {
                  onSelect(null)
                }
                const fields = ['firstName', 'lastName', 'contactEmail']
                const operator = 'OR'

                const values = e.target.value.trim().split(' ')

                const searchFilters: ISearchFilter[] = []

                values.forEach((word, wordIndex) => {
                  const wordFilters: ISearchFilter[] = fields.map((field, fieldIndex) => ({
                    filterBy: field,
                    operator:
                      wordIndex === values.length - 1 && fieldIndex === fields.length - 1
                        ? ''
                        : operator,
                    value: `${word}*`,
                  }))

                  searchFilters.push(...wordFilters)
                })

                // Filters are converted to query string for a more accurate search
                const filtersAsQuery = getSearchFiltersQuery(searchFilters)

                handleUpdateQuery({ query: filtersAsQuery })
              }}
              {...TextFieldProps}
            />
          )
        }}
        isOptionEqualToValue={(option, value) => {
          return (
            option?.contact?.id === value?.contact?.id &&
            option?.contact?.firstName === value?.contact?.firstName
          )
        }}
      />
      <Button
        variant="contained"
        startIcon={<AddBox />}
        onClick={() => {
          onSelect([...selectedValues], true)
        }}
        sx={{ mt: 2 }}
      >
        Create new contact
      </Button>
    </>
  )
}

export default FormSearchContacts
