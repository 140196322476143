import { Option, optionResource } from '@chunker/chunker-request'
import useNotify from 'hooks/useNotify'
import { useCallback, useEffect, useState } from 'react'

function useOptionsByOptionHeader() {
  const { notifyError } = useNotify()
  const [optionsByHeader, setOptionsByHeader] = useState<{ [headerId: number]: Option[] }>({})
  const [loading, setLoading] = useState<boolean>(false)

  const fetchOptions = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await optionResource.requests.getOptions()

      setOptionsByHeader(
        data.reduce((acc: { [headerId: number]: Option[] }, option: Option) => {
          const headerId = option.optionHeader.id

          acc[headerId] = [...(acc[headerId] || []), option]

          return acc
        }, {}),
      )
    } catch (error) {
      console.error(error)
      notifyError()
    } finally {
      setLoading(false)
    }
  }, [notifyError])

  useEffect(() => {
    fetchOptions().catch(console.error)
  }, [fetchOptions])

  return { optionsByHeader, fetch: fetchOptions, loading }
}

export default useOptionsByOptionHeader
