import { add } from 'date-fns'
import { GetTimeFramesReturn } from './types'

export default function getTimeFrames(startDate: Date): GetTimeFramesReturn {
  const getReturnValue = (date: Date) => ({ dateFrom: startDate, dateTo: date })
  return {
    get next3Months() {
      return getReturnValue(add(startDate, { months: 3 }))
    },
    get next6Months() {
      return getReturnValue(add(startDate, { months: 6 }))
    },
    get next9Months() {
      return getReturnValue(add(startDate, { months: 9 }))
    },
    get nextYear() {
      return getReturnValue(add(startDate, { years: 1 }))
    },
  }
}
