import { useState } from 'react'
import { Theme } from '@mui/material/styles'
import AccordionSummary from '@mui/material/AccordionSummary'
import { BorderAll, ExpandMore, HolidayVillage, LocalShipping } from '@mui/icons-material'
import { Accordion, AccordionDetails, Typography } from '@mui/material'
import getAppendableTestId from 'lib/utils/testUtils/getAppendableTestId'
import { IFormAccordionTypeProps } from './types'

const typeOptions = {
  warehouse: {
    icon: <HolidayVillage sx={{ mx: 2 }} />,
    title: 'Warehouse',
  },
  storage: {
    icon: <BorderAll sx={{ mx: 2 }} />,
    title: 'Shipping Containers & Transportation Storage',
  },
  transportation: {
    icon: <LocalShipping sx={{ mx: 2 }} />,
    title: 'Transportation',
  },
}

function FormAccordionType({
  children,
  type,
  appendableTestIds,
  AccordionBaseProps,
  AccordionSummaryProps,
  AccordionDetailsProps,
}: IFormAccordionTypeProps) {
  const [expanded, setExpanded] = useState(false)

  const handleChange = () => {
    setExpanded(!expanded)
  }

  const getType = () => {
    const option = typeOptions[type]

    return (
      <>
        {option.icon}
        <Typography variant="subtitle1">{option.title}</Typography>
      </>
    )
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      elevation={0}
      square
      sx={{
        border: (t: Theme) => `1px solid ${t.palette.divider}`,
        '&:before': {
          display: 'none',
        },
      }}
      data-testid={getAppendableTestId({
        appendableTestIds,
        testId: 'form-accordion-type',
        key: 'accordionType',
      })}
      {...AccordionBaseProps}
    >
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: (t) => t.palette.common.white }} />}
        sx={{
          backgroundColor: (t) => t.palette.secondary.dark,
          color: (t) => t.palette.common.white,
          alignItems: 'center',
        }}
        data-testid={getAppendableTestId({
          appendableTestIds,
          testId: 'form-accordion-type-title',
          key: 'accordionTypeTitle',
        })}
        {...AccordionSummaryProps}
      >
        {getType()}
      </AccordionSummary>
      <AccordionDetails
        sx={{ padding: (t) => t.spacing(2), borderTop: '1px solid rgba(0, 0, 0, .125)' }}
        data-testid={getAppendableTestId({
          appendableTestIds,
          testId: 'form-accordion-type-content',
          key: 'accordionTypeContent',
        })}
        {...AccordionDetailsProps}
      >
        {expanded ? children : null}
      </AccordionDetails>
    </Accordion>
  )
}

export default FormAccordionType
