import { useEffect, useState } from 'react'
import { OpportunitiesContact, contactsResource } from '@chunker/chunker-request'
import useDebounce from 'hooks/useDebounce'
import useNotify from 'hooks/useNotify'
import { IQueryState } from 'types/IQueryState'
import builtQuery from 'lib/utils/hooksUtils/builtQuery'

function useContactSearch() {
  const [results, setResults] = useState<OpportunitiesContact[]>([])
  const [loading, setLoading] = useState(true)
  const [queryState, setQueryState] = useState<IQueryState>({
    query: '',
    filters: [],
    page: 0,
    size: 50,
    sort: [],
  })

  const handleUpdateQuery = (updateQuery: Partial<IQueryState>) =>
    setQueryState((prev) => ({ ...prev, ...updateQuery }))

  const { notifyError } = useNotify()

  const requiredParamExist = Boolean(queryState.query) || Boolean(queryState.filters?.length)
  const hasResults = Boolean(results?.length)

  const query = useDebounce(builtQuery(queryState), 500)

  useEffect(() => {
    if (!requiredParamExist) {
      if (hasResults) setResults([])
      setLoading(false)
      return
    }

    setLoading(true)

    contactsResource.requests
      .searchContacts(query)
      .then(({ data }) => {
        const cleanedData = data.filter((contact) => contact.contactEmail)

        setResults(
          cleanedData.map((contact) => {
            return {
              primaryContact: false,
              contact,
            }
          }) as OpportunitiesContact[],
        )
      })
      .catch((err) => {
        console.error(err)
        notifyError('An error occurred while searching for a contact.')
      })
      .finally(() => setLoading(false))
  }, [query, hasResults, requiredParamExist, notifyError])

  return {
    loading,
    results,
    queryState,
    handleUpdateQuery,
  }
}

export default useContactSearch
