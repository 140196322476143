import { Box, Typography } from '@mui/material'
import { IFormHeaderStepProps } from './types'

export default function FormHeaderStep({
  step,
  Icon,
  title,
  description,
  iconPadding,
  noUpperCase,
}: IFormHeaderStepProps) {
  return (
    <Box data-testid="form-step-header">
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            backgroundColor: (t) => t.palette.primary.main,
            width: 51,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 0.5,
          }}
        >
          <Icon
            data-testid="form-step-header-icon"
            sx={{
              color: (t) => t.palette.primary.contrastText,
              height: (t) => `calc(100% - ${t.spacing(iconPadding ?? 3)})`,
              width: (t) => `calc(100% - ${t.spacing(iconPadding ?? 3)})`,
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            pl: 1.5,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography fontWeight="bold" color="secondary.dark">
            STEP {step}
          </Typography>
          <Typography fontWeight="bold" color="secondary.dark" sx={{ fontSize: 18 }}>
            {!noUpperCase ? title.toUpperCase() : title}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant="body1" component="p" sx={{ color: (t) => t.palette.text.secondary }}>
          {description}
        </Typography>
      </Box>
    </Box>
  )
}
