import { Alert, Box, Grid, useTheme } from '@mui/material'
import AddressSearchBar from 'components/AddressSearchBar'
import FormikTextField from 'components/FormikTextField'
import getAppendableTestId from 'lib/utils/testUtils/getAppendableTestId'
import { FormikErrors, FormikValues, useFormikContext } from 'formik'
import { IAddressAutoFillProps } from './types'

/**
 *
 * @description This component is a wrapper for the AddressSearchBar component and the FormikTextField component.
 * @param fields - An array of objects that contain the key, label, and columns properties.
 * @param fieldMapping - An object that maps the address components to the formik fields.
 * @param searchBarDisabled - A boolean that determines if the AddressSearchBar component is disabled.
 * @param textFieldProps - An object that contains the props for the FormikTextField component.
 *
 * @example
 * <AddressAutoFill
 *  fields={[
 *   { key: 'address', label: 'Street', columns: { xs: 12 } },
 *   { key: 'city', label: 'City' },
 *   { key: 'state', label: 'State' },
 *   { key: 'country', label: 'Country' },
 *   { key: 'zipCode', label: 'Zip Code' },
 *  ]}
 *  fieldMapping={{
 *   street_number: 'address',
 *   route: 'address',
 *   locality: 'state',
 *   administrative_area_level_1: 'city',
 *   postal_code: 'zipCode',
 *   country: 'country,
 *  }}
 *  searchBarDisabled={!editing}
 * />
 */
function AddressAutoFill({
  fields,
  fieldMapping,
  searchBarDisabled,
  textFieldProps,
  BoxProps,
}: IAddressAutoFillProps) {
  const theme = useTheme()
  const { errors, touched } = useFormikContext<FormikErrors<FormikValues>>()

  return (
    <Box {...BoxProps}>
      <AddressSearchBar fieldMapping={fieldMapping} disabled={searchBarDisabled} />
      <Box
        sx={{
          border: `1px solid lightgray`,
          borderRadius: '8px',
          boxShadow: '0px 0px 10px 0px rgba(255,255,255,0.25)',
          padding: theme.spacing(4),
          marginY: theme.spacing(4),
          backdropFilter: 'blur(1px)',
        }}
      >
        {touched?.address && errors?.address && (
          <Alert severity="error" variant="filled" sx={{ mb: 4 }}>
            {errors?.address || 'Please enter a valid address.'}
          </Alert>
        )}
        <Grid container spacing={4}>
          {fields.map(({ key, label, columns }) => {
            return (
              <Grid item xs={columns?.xs ?? 6} sm={columns?.sm} key={key}>
                <FormikTextField
                  type="text"
                  id={key}
                  name={key}
                  label={label}
                  FormHelperTextProps={{
                    sx: { fontSize: theme.typography.caption.fontSize, fontWeight: 'bold' },
                  }}
                  {...textFieldProps}
                  fullWidth
                  disabled
                  data-testid={getAppendableTestId({
                    testId: `address-autofill-text-field-${key}`,
                  })}
                />
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

export default AddressAutoFill
