import { Typography } from '@mui/material'
import { IClickableTypography } from './types'

export default function ClickableTypography({ onClick, text, ...props }: IClickableTypography) {
  return (
    <Typography
      {...props}
      onClick={onClick}
      variant="body2"
      data-testid={props['data-testid']}
      sx={{
        color: (theme) => theme.palette.secondary.dark,
        ...(onClick
          ? {
              '&:hover': {
                cursor: 'pointer',
                color: 'black',
              },
            }
          : {}),
        ...props?.sx,
      }}
    >
      {text}
    </Typography>
  )
}
