/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState } from 'react'
import { InputAdornment, MenuItem, TextField } from '@mui/material'
import omit from 'lodash/omit'
import { FormikValues, getIn, useField, useFormikContext } from 'formik'
import formatZip from 'lib/utils/normalizers/formatZip'
import { IFormikTextFieldProps } from './types'
import PhoneInput from './PhoneInput'
import CentsInput from './CentsInput'
import FloatInput from './FloatInput'
import IntegerInput from './IntegerInput'

/** @description
 * - This is a wrapper around MUI's TextField component that uses Formik's `useField` hook to
 *  connect the field to Formik's context.
 */
export default function FormikTextField({
  name,
  label,
  phoneNumber,
  zipCode,
  data,
  cents,
  integer,
  float,
  touchOnInput,
  select,
  withDescription,
  ...props
}: IFormikTextFieldProps) {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<FormikValues>()
  const [field, meta] = useField(name)
  const omittedProps = omit(props, ['data-testid'])
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const getInValue = getIn(values, name)
  const [touchedOnInput, setTouchedOnInput] = useState(false)

  const handleChange = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    if (touchOnInput && !touchedOnInput) setTouchedOnInput(true)

    const { value } = e.target
    if (!['string', 'number'].includes(typeof value)) return
    if (zipCode) setFieldValue(name, formatZip(value), true).catch(console.error)
    else setFieldValue(name, value, true).catch(console.error)
  }

  const getInputProps = () => {
    if (cents)
      return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: CentsInput as any,
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        ...omittedProps.InputProps,
      }

    if (phoneNumber)
      return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: PhoneInput as any,
        ...omittedProps.InputProps,
      }

    if (float)
      return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: FloatInput as any,
        ...omittedProps.InputProps,
      }

    if (integer)
      return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: IntegerInput as any,
        ...omittedProps.InputProps,
      }

    return omittedProps.InputProps
  }

  return (
    <TextField
      {...omittedProps}
      {...field}
      {...omit(field, ['onChange', 'onBlur', 'value'])}
      onChange={handleChange}
      onBlur={() => {
        setFieldTouched(name, true, true)
      }}
      select={select}
      value={getInValue ?? ''}
      InputProps={getInputProps()}
      inputProps={{ 'data-testid': props['data-testid'] }}
      label={label}
      error={Boolean(meta.error) && (meta.touched || touchedOnInput)}
      helperText={(meta.touched || touchedOnInput) && meta.error}
    >
      {data?.map(({ label: dataLabel, id, helpText: description }, i) => {
        if (withDescription)
          return (
            <MenuItem key={id} data-testid={`menu-item-${i}`} value={id}>
              <strong>{dataLabel}:</strong> &nbsp;{description}
            </MenuItem>
          )
        return (
          <MenuItem key={id} data-testid={`menu-item-${i}`} value={id}>
            {dataLabel}
          </MenuItem>
        )
      })}
    </TextField>
  )
}
