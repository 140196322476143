import { useUserCTX } from '@chunker/user-ctx'
import { Box } from '@mui/material'
import { APPLICATION_MAX_CONTAINER_WIDTH } from 'lib/constants/sizes'
import CompanyPoliciesAndSocials from './FooterContent'
import SeparationLine from './SeparationLine'

export default function ApplicationFooter() {
  const { status } = useUserCTX()

  if (!status.isUserLoggedIn) return null

  return (
    <Box
      data-testid="application-footer"
      sx={{
        backgroundColor: (theme) => theme.palette.grey?.[100],
        width: '100%',
        p: 3,
      }}
    >
      <Box
        sx={{
          maxWidth: APPLICATION_MAX_CONTAINER_WIDTH,
          margin: '0 auto',
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <CompanyPoliciesAndSocials />
      </Box>
      <SeparationLine />
    </Box>
  )
}
