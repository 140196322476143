import { useUserCTX } from '@chunker/user-ctx'
import {
  Box,
  ButtonBase,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { Logout as LogoutIcon } from '@mui/icons-material'
import greyLogo from 'assets/logo-chunker-gray-mini.png'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useLocation, useNavigate } from 'react-router-dom'
import windowOpenBlank from 'lib/utils/windowUtls/windowOpenBlank'
import URLS from 'lib/constants/urls'
import ListItemIconColorInherit from '../ListItemIconColorInherit'
import { navbarLinks } from '../constants'
import { INavDrawerProps } from './types'
import MenuListItem from '../MenuListItem'

export default function NavDrawer({ open, onClose }: INavDrawerProps) {
  const { clearUserSession } = useUserCTX()
  const navigate = useNavigate()
  const location = useLocation()

  const handleCloseNavMenu =
    () =>
    ({ key, type }: React.KeyboardEvent) => {
      if (type === 'keydown' && (key === 'Tab' || key === 'Shift')) return

      onClose()
    }

  const handleNavigate = (url: string) => {
    if (url === location.pathname) navigate(0)
    if (url === URLS.leaveCompanyPortal.contactUs) windowOpenBlank(url)
    else navigate(url)
    onClose()
  }

  return (
    <Drawer open={open} onClose={onClose} anchor="right">
      <Box
        data-testid="nav-drawer"
        role="presentation"
        onClick={handleCloseNavMenu}
        onKeyDown={handleCloseNavMenu}
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', width: 'auto' }}
      >
        <List component="div" disablePadding>
          <ListItem sx={{ display: 'flex' }}>
            <ListItemIconColorInherit
              sx={{ '&:hover': { color: 'black' }, cursor: 'pointer' }}
              onClick={onClose}
            >
              <ButtonBase sx={{ borderRadius: 50 }}>
                <KeyboardArrowRightIcon />
              </ButtonBase>
            </ListItemIconColorInherit>
          </ListItem>
          <Divider />
          {Object.values(navbarLinks).map((value) => {
            const { url, icon, userRights, label, 'data-testid': dataTestId } = value
            const isSelected = location.pathname === url

            return (
              <MenuListItem
                key={url}
                isSelected={isSelected}
                onClick={() => handleNavigate(url)}
                open={open}
                icon={icon}
                userRights={userRights}
                title={label}
                data-testid={dataTestId}
              />
            )
          })}
          <ListItem disablePadding>
            <ListItemButton
              data-testid="nav-drawer-logout-button"
              onClick={() => {
                clearUserSession()
                  .then(() => navigate(URLS.login))
                  .catch((err) => console.error(err))
              }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
          <Divider />
        </List>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <Box sx={{ padding: 3, display: 'flex', justifyContent: 'center' }}>
            <Box component="img" src={greyLogo} sx={{ width: 100 }} />
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}
