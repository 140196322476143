import { Customer, customerResource } from '@chunker/chunker-request'
import useDebounce from 'hooks/useDebounce'
import useNotify from 'hooks/useNotify'
import { useEffect, useState } from 'react'
import { IQueryState } from 'types/IQueryState'
import builtQuery from 'lib/utils/hooksUtils/builtQuery'

export default function useCustomerSearch() {
  const [results, setResults] = useState<Customer[]>([])
  const [loading, setLoading] = useState(true)
  const [queryState, setQueryState] = useState<IQueryState>({
    query: '',
    page: 0,
    size: 50,
    sort: ['name.keyword,asc'],
  })

  const handleUpdateQuery = (updateQuery: Partial<IQueryState>) =>
    setQueryState((prev) => ({ ...prev, ...updateQuery }))

  const { notifyError } = useNotify()

  const requiredParamExist = Boolean(queryState.query)
  const hasResults = Boolean(results?.length)

  const query = useDebounce(builtQuery(queryState), 500)

  useEffect(() => {
    if (!requiredParamExist) {
      if (hasResults) setResults([])
      setLoading(false)
      return
    }

    setLoading(true)

    customerResource.requests
      .searchCustomer(query)
      .then(({ data }) =>
        data.filter((customer) => [customer.name, customer.website].some(Boolean)),
      )
      .then((data) => setResults(data))
      .catch((err) => {
        console.error(err)
        notifyError('An error occurred while searching for a customer.')
      })
      .finally(() => setLoading(false))
  }, [query, hasResults, requiredParamExist, notifyError])

  return {
    loading,
    results,
    queryState,
    handleUpdateQuery,
  }
}
