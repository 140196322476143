import { useState } from 'react'
import { IconButton, InputAdornment } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { IFormikTextFieldProps } from 'components/FormikTextField/types'
import FormikTextField from 'components/FormikTextField'

function FormikTextFieldEditable(props: IFormikTextFieldProps) {
  const [editing, setEditing] = useState(false)

  return (
    <FormikTextField
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={() => setEditing(!editing)}>
              <Edit />
            </IconButton>
          </InputAdornment>
        ),
        sx: {
          '&.Mui-disabled:before': {
            borderBottom: 'none',
          },
          fontWeight: 'bold',
        },
      }}
      variant="standard"
      fullWidth
      multiline
      disabled={!editing}
      {...props}
    />
  )
}
export default FormikTextFieldEditable
